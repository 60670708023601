import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetProductDataSortType', {
  MOST_CLICKED: 10,
  MOST_PURCHASED: 20,
  HIGHEST_REVENUE: 30,
  MOST_CARTED: 40,
  HIGHEST_PURCHASE_RATE: 50,
  MOST_REVIEWED: 60,
  HIGHEST_REVIEW_SCORE: 70
});
