export default [
  {
    id: 'channel_settings_basic',
    menuItemId: 'channel_settings_basic',
    sections: [
      {
        groups: [
          { id: 'excluding_category_ids' },
          { id: 'in_categories' },
          { id: 'excluding_product_keywords' },
          {
            id: 'min_products_count',
            isAccessible: ({ _state, getters }) =>
              !getters['session/isChannelMultiFeedEnabled']
          },
          {
            id: 'proper_products_count',
            isAccessible: ({ _state, getters }) =>
              !getters['session/isChannelMultiFeedEnabled']
          },
          {
            id: 'purchase_rate_filter_criteria',
            isAccessible: ({ _state, getters }) =>
              !getters['session/isChannelMultiFeedEnabled']
          },
          {
            id: 'cohort_csv_download',
            isAccessible: ({ _state, getters }) =>
              !getters['session/isChannelMultiFeedEnabled']
          },
          { id: 'product_categories_csv' }
        ]
      }
    ]
  },
  {
    id: 'channel_settings_admin',
    menuItemId: 'channel_settings_admin',
    sections: [
      {
        groups: [
          {
            id: 'ad_feed_url',
            isAccessible: ({ _state, getters }) =>
              !getters['session/isChannelMultiFeedEnabled']
          },
          { id: 'launch_ad_menu' },
          { id: 'skip_product_image_download' }
        ]
      }
    ]
  }
];
