var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppImageInput"},[_c('AppFileInput',_vm._b({on:{"change":_vm.changeFile,"blur":function($event){return _vm.$emit('blur')}}},'AppFileInput',{
      id: _vm.id,
      name: _vm.name,
      disabled: _vm.disabled,
      invalid: _vm.invalid,
      selectOnMount: _vm.selectOnMount,
      placeholder: _vm.placeholder,
      accept: _vm.accept
    },false)),(_vm.caption)?_c('div',{staticClass:"AppImageInput__caption",class:[
      'AppImageInput__caption',
      { 'AppImageInput__caption--disabled': _vm.disabled }
    ]},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e(),(!_vm.noPreview && _vm.currentImageUrl)?_c('div',{staticClass:"AppImageInput__preview-wrapper"},[_c('AppImage',{staticClass:"AppImageInput__preview",style:({ height: _vm.previewHeight }),attrs:{"src":_vm.currentImageUrl}})],1):_vm._e(),(!_vm.notRemovable && _vm.currentImageUrl && _vm.name)?_c('div',{staticClass:"AppImageInput__remove-image-wrapper"},[_c('AppCheckbox',{attrs:{"name":_vm.removeName,"disabled":_vm.disabled,"label":_vm.removeImageLabel || _vm.$t('remove_image')},model:{value:(_vm.removeImage),callback:function ($$v) {_vm.removeImage=$$v},expression:"removeImage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }