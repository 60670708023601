import Vue from 'vue';
import Vuex from 'vuex';

import abTest from './modules/abTest';
import alert from './modules/alert';
import asyncJob from './modules/asyncJob';
import bizMessage from './modules/bizMessage';
import bizMessageTemplate from './modules/bizMessageTemplate';
import multiAsyncJob from './modules/multiAsyncJob';
import batchJob from './modules/batchJob';
import session from './modules/session';
import dialog from './modules/dialog';
import drawer from './modules/drawer';
import easyJoin from './modules/easyJoin';
import editor from './modules/editor';
import form from './modules/form';
import menu from './modules/menu';
import miniNotice from './modules/miniNotice';
import notification from './modules/notification';
import pageBreadcrumb from './modules/pageBreadcrumb';
import payment from './modules/payment';
import poll from './modules/poll';
import jobRequest from './modules/jobRequest';
import settings from './modules/settings';
import toast from './modules/toast';
import translation from './modules/translation';
import request from './modules/request';
import search from './modules/search';
import sync from './modules/sync';
import competingGroup from './modules/insight/competingGroup';
import brandCategory from './modules/brandCategory';
import kakaoStyle from './modules/kakaoStyle';
import productCategory from './modules/productCategory';
import productIcons from './modules/productIcons';
import review from './modules/review';
import reviewThirdPartyReviewCsv from './modules/review/third_party_review_csv';
import reviewPinnedReviews from './modules/review/pinnedReviews';
import reviewWidgets from './modules/reviewWidgets';
import reviewInstallSimulation from './modules/reviewInstallSimulation';
import requestInstallations from './modules/requestInstallations';
import widgetInstallRequest from './modules/widgetInstallRequest';
import manualSyncJobs from './modules/manualSyncJobs';
import smartstore from './modules/smartstore';
import thirdParties from './modules/thirdParties';
import userGrade from './modules/userGrade';
import fitSettings from './modules/fitSettings';
import fitCsv from './modules/fit/csv';
import fitPresetProduct from './modules/fit/presetProduct';
import fitSizeFeedbackProduct from './modules/fit/sizeFeedbackProduct';
import fitTemplate from './modules/fit/template';
import fitProduct from './modules/fit/product';
import targetCampaign from './modules/target/campaign';
import targetCampaigns from './modules/target/campaigns';
import displayPreviewSettings from './modules/display/previewSettings';
import displayProductDisplays from './modules/display/productDisplays';
import displayProductDisplay from './modules/display/productDisplay';
import displayProducts from './modules/display/products';
import displayUnits from './modules/display/units';
import displayProductManagement from './modules/display/productManagement';
import displayProductManagementCohortJobs from './modules/display/productManagementCohortJobs';
import targetProductDataCalculationJobs from './modules/target/productDataCalculationJobs';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    abTest,
    alert,
    asyncJob,
    bizMessage,
    bizMessageTemplate,
    multiAsyncJob,
    batchJob,
    session,
    dialog,
    drawer,
    easyJoin,
    editor,
    form,
    menu,
    miniNotice,
    notification,
    pageBreadcrumb,
    payment,
    poll,
    jobRequest,
    settings,
    toast,
    translation,
    request,
    search,
    competingGroup,
    brandCategory,
    kakaoStyle,
    productCategory,
    productIcons,
    review,
    reviewThirdPartyReviewCsv,
    reviewPinnedReviews,
    reviewWidgets,
    reviewInstallSimulation,
    requestInstallations,
    widgetInstallRequest,
    sync,
    manualSyncJobs,
    smartstore,
    thirdParties,
    userGrade,
    fitSettings,
    fitCsv,
    fitPresetProduct,
    fitSizeFeedbackProduct,
    fitTemplate,
    fitProduct,
    targetCampaign,
    targetCampaigns,
    displayPreviewSettings,
    displayProductDisplay,
    displayProductDisplays,
    displayProducts,
    displayUnits,
    displayProductManagement,
    displayProductManagementCohortJobs,
    targetProductDataCalculationJobs
  }
});
