import { render, staticRenderFns } from "./ChannelFeedProductsUnitsDialog.vue?vue&type=template&id=b7952e7e&scoped=true&"
import script from "./ChannelFeedProductsUnitsDialog.vue?vue&type=script&lang=js&"
export * from "./ChannelFeedProductsUnitsDialog.vue?vue&type=script&lang=js&"
import style0 from "./ChannelFeedProductsUnitsDialog.vue?vue&type=style&index=0&id=b7952e7e&prod&lang=scss&scoped=true&"
import style1 from "./ChannelFeedProductsUnitsDialog.vue?vue&type=style&index=1&id=b7952e7e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7952e7e",
  null
  
)

/* custom blocks */
import block0 from "./ChannelFeedProductsUnitsDialog.vue?vue&type=custom&index=0&blockType=i18n&locale=ko"
if (typeof block0 === 'function') block0(component)

export default component.exports