<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
    >
      <template #group="{ id, errors }">
        <template v-if="id === 'ad_feed_url'">
          <AppCopyableTextarea
            class="AppForm__group-field"
            :value="adFeedUrl"
          />
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'ChannelSettingsAdmin',
  mixins: [FormView, SettingsView, TargetContentView],
  data: () => ({ isLoading: true, adFeedUrl: null, feedUpdatedAt: null }),
  computed: {
    ...mapGetters('session', ['isChannelMultiFeedEnabled']),
    SETTINGS_ID() {
      return 'channel_settings_admin';
    },
    objectId() {
      return 'channel_settings';
    },
    groupOptions() {
      return {
        ...(this.isChannelMultiFeedEnabled
          ? {}
          : {
              ad_feed_url: {
                groupDescription: this.$t('form.ad_feed_url.description', {
                  updatedAt: this.feedUpdatedAt
                }),
                hint: this.$t('form.ad_feed_url.hint')
              }
            }),
        launch_ad_menu: { type: 'checkbox' },
        skip_product_image_download: {
          type: 'checkbox',
          description: this.$t('form.skip_product_image_download.description')
        }
      };
    }
  },
  mounted() {
    api
      .get('/channel/settings/admin')
      .then(({ data }) => {
        const { settings, ad_feed_url } = data;
        this.orgFormObject = settings;
        this.adFeedUrl = ad_feed_url;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/channel/settings/update_admin', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
  {
    "form": {
      "ad_feed_url": {
        "description": "크리마 애드의 데이터를 픽셀과 연동하기 위하여 다음 광고 피드 URL을 사용합니다.",
        "hint": "광고 피드 URL을 복사하여 [페이스북 픽셀 - 상거래 관리자 - 카탈로그 - 데이터 소스]의 파일 업로드 방법 선택의 [파일이 호스팅되는 URL]로 설정해주세요."
      },
      "skip_product_image_download": {
        "description": "애드에서는 상품 이미지 사이즈를 파악하기위해 상품 동기화 시 상품이미지를 다운로드합니다.<br />상품 동기화 시 상품 이미지 다운로드로 인해 부하가 일어날 시 해당 기능을 사용하여 상품 이미지 다운로드를 제한합니다.<br />상품 이미지 다운로드 스킵 시 이미지 주소를 이용해 상품 이미지를 파악하나 시간이 오래걸립니다.<br />[리뷰 > 최고관리자 설정 > 관리 기능 > 상품 이미지 다운로드 스킵]과 연결되어있습니다."
      }
    }
  }
</i18n>
