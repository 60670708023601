<template>
  <AppModalForm
    :title="
      $t(widgetId ? 'title_edit' : 'title_new', {
        widget_type: WidgetType.t(widgetType)
      })
    "
    :sub-title="
      widgetId
        ? $t('sub_title', {
            widget_name: widget.name,
            widget_id: widgetId
          })
        : ''
    "
    :form-props="{ ...formProps, objectId: 'widget' }"
    :is-loading="isLoading"
    :can-maximize="useWidgetPreview"
    :default-left-min-width="766"
    :default-main-min-width="680"
    :show-bubble-top="useWidgetPreview"
    :show-bubble-mid="useWidgetPreview && !isDeviceMobile"
    v-on="formEvents"
    @change-group="formGroupChanged"
    @error-summary="updatePreviewIfValid"
  >
    <template v-if="useWidgetPreview" #left>
      <ReviewWidgetPreview
        :use-vuejs="widget.use_vuejs"
        :show-vue-widget-setting="widget.show_vue_widget_setting"
        :preview-widget-url="previewUrl"
        :preview-not-supported="!isPreviewSupported"
        :widget-style-not-selected="!showWidgetTypeNotSelected"
        :is-pc="isDevicePc"
        :current-version="currentVersion"
        :display-version="displayVersion"
        :event-bus="eventBus"
      />
    </template>
    <input type="hidden" name="widget[widget_type]" :value="widgetType" />
    <input
      type="hidden"
      name="widget[widget_style]"
      :value="widget.widget_style"
    />
    <input
      type="hidden"
      name="widget[widget_sub_style]"
      :value="widget.widget_sub_style"
    />
    <AppSummaryBox
      v-if="widgetIdExist && widget.show_vue_widget_setting"
      class="ReviewSettingsWidgetsNormalFormDialog__summary-box"
    >
      <AppCheckbox
        v-model="widget.use_vuejs"
        name="widget[use_vuejs]"
        class="ReviewSettingsWidgetsNormalFormDialog__group-title"
        :label="$t('form.use_vuejs')"
        @change="validateField('use_vuejs')"
      />
      <AppMarked :text="$t('use_vuejs_group_description')" />
    </AppSummaryBox>
    <template #group="group">
      <div
        v-if="group.id === 'widget_device_type' && widgetId"
        class="AppForm__group-field"
      >
        {{ WidgetDeviceType.t(widget.widget_device_type) }}
      </div>
      <div v-else-if="group.id === 'widget_style'" class="AppForm__group-field">
        <div v-if="widgetId">{{ WidgetStyle.t(widgetStyle) }}</div>
        <AppSelect
          v-else
          v-model="widgetStyle"
          :options="widgetStyleOptions"
          :placeholder="
            isWidgetTypeProducts ? null : $t('widget_style_placeholder')
          "
          :disabled="isWidgetTypeManagingReviews"
          @change="widgetStyleChanged"
        />
      </div>
      <template v-else-if="group.id === 'no_item_action'">
        <input
          v-if="!widget.no_item_action"
          type="hidden"
          name="widget[no_item_action_type]"
          value=""
        />
        <div class="AppForm__group-field">
          <i18n :path="noItemActionLabel">
            <AppNumberInput
              v-model="widget.min_items_count"
              name="widget[min_items_count]"
              :invalid="!!group.errors.min_items_count"
              :digits="2"
              :disabled="!widget.no_item_action"
              @blur="validateField('min_items_count')"
              @change="validateField('min_items_count')"
            />
          </i18n>
          <AppFormError :error="group.errors.min_items_count" />
        </div>
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="widget.no_item_action_type"
            name="widget[no_item_action_type]"
            :disabled="!widget.no_item_action"
            :options="[
              { label: $t('no_item_action_hide'), value: 'hide' },
              { label: $t('no_item_action_image'), value: 'image' }
            ]"
          >
            <template #sub-item="{ value, checked }">
              <template v-if="value === 'image'">
                <AppTextInput
                  v-model="widget.no_item_image_url"
                  name="widget[no_item_image_url]"
                  placeholder="https://"
                  type="url"
                  :invalid="!!group.errors.no_item_image_url"
                  :disabled="!widget.no_item_action || !checked"
                  @blur="validateField('no_item_image_url')"
                  @change="validateField('no_item_image_url')"
                />
                <AppFormError :error="group.errors.no_item_image_url" />
              </template>
            </template>
          </AppSelectRadio>
        </div>
      </template>
      <template v-else-if="group.id === 'show_header_section'">
        <ReviewSettingsShowHeaderSection
          v-if="widget.version === 3"
          v-bind="{ widget, group }"
          class="AppForm__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
          @change="validateField($event)"
          @blur="validateField($event)"
        />
        <template v-else>
          <div
            v-for="(subSettingName, index) in showHeaderSectionSubSettings"
            :key="index"
            class="AppForm__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
          >
            <div
              v-if="subSettingName === 'show_title'"
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.show_title"
                name="widget[show_title]"
                :disabled="!widget.show_header_section"
                @change="validateField('show_title')"
              >
                <template #label>
                  {{
                    isHeaderRenewed
                      ? $t('show_title_list')
                      : $t('form.show_title')
                  }}
                </template>
              </AppCheckbox>
              <div
                class="ReviewSettingsWidgetsNormalFormDialog__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
              >
                <label
                  v-if="isHeaderRenewed"
                  :class="[
                    'AppForm__sub-group-title',
                    {
                      'AppForm__sub-group-title--disabled':
                        !widget.show_header_section || !widget.show_title
                    }
                  ]"
                  for="widget[show_title]"
                >
                  {{ $t('show_title_list_label') }}
                  <span
                    class="ReviewSettingsWidgetsNormalFormDialog__group-title-required"
                    >*</span
                  >
                </label>
                <AppTextInput
                  v-model="widget.title"
                  :placeholder="$t('title_placeholder')"
                  :disabled="!widget.show_header_section || !widget.show_title"
                  :invalid="!!group.errors.show_title"
                  name="widget[title]"
                  @blur="validateField('show_title')"
                />
                <AppFormError :error="group.errors.show_title" />
              </div>
            </div>
            <div
              v-else-if="subSettingName === 'title'"
              class="AppForm__sub-group-item"
            >
              <label
                :class="[
                  'AppForm__sub-group-title',
                  {
                    'AppForm__sub-group-title--disabled': !widget.show_header_section
                  }
                ]"
                for="widget[title]"
              >
                {{ $t('form.title') }}
              </label>
              <AppTextInput
                v-model="widget.title"
                :placeholder="$t('title_placeholder')"
                :disabled="!widget.show_header_section"
                name="widget[title]"
                @blur="validateField('title')"
              />
            </div>
            <div
              v-else-if="subSettingName === 'description_text'"
              :class="[
                'AppForm__sub-group-item',
                {
                  'ReviewSettingsWidgetsNormalFormDialog__sub-item':
                    isHeaderRenewed || widget.use_vuejs
                }
              ]"
            >
              <label
                :class="[
                  'AppForm__sub-group-title',
                  {
                    'AppForm__sub-group-title--disabled': disabledShowTitleSubSetting
                  }
                ]"
                for="widget[description_text]"
              >
                {{ $t('form.description_text') }}
              </label>
              <AppTextInput
                v-model="widget.description_text"
                :placeholder="$t('description_text_placeholder')"
                :disabled="disabledShowTitleSubSetting"
                :invalid="!!group.errors.description_text"
                name="widget[description_text]"
                @blur="validateField('description_text')"
              />
              <AppFormHint
                :message="$t('description_text_description')"
                :disabled="disabledShowTitleSubSetting"
              />
              <AppFormError :error="group.errors.description_text" />
            </div>
            <div
              v-else-if="subSettingName === 'reviews_button_title'"
              :class="[
                'AppForm__sub-group-item',
                {
                  'ReviewSettingsWidgetsNormalFormDialog__sub-item': isHeaderRenewed,
                  'super-admin__item': isMobileProductReviewsRenewed
                }
              ]"
            >
              <AppCheckbox
                v-model="widget.use_reviews_button"
                name="widget[use_reviews_button]"
                :disabled="disabledShowTitleSubSetting"
                @change="validateField('use_reviews_button')"
              >
                <template #label>
                  {{ $t('form.use_reviews_button') }}
                </template>
              </AppCheckbox>
              <div
                class="ReviewSettingsWidgetsNormalFormDialog__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
              >
                <ReviewSettingsWidgetsNormalFormReviewsButtonTitle
                  :widget="widget"
                  :disabled="disabledReviewsButtonTitle"
                  :error="group.errors.reviews_button_title"
                  @title-blur="validateField('reviews_button_title')"
                >
                  <AppFormHint
                    v-if="isMobileProductReviewsRenewed"
                    :message="
                      $t('form.reviews_button_title_super_admin_description')
                    "
                    :disabled="disabledReviewsButtonTitle"
                  />
                </ReviewSettingsWidgetsNormalFormReviewsButtonTitle>
              </div>
            </div>
            <div
              v-else-if="subSettingName === 'show_product_score'"
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.show_product_score"
                name="widget[show_product_score]"
                :label="$t('form.show_product_score')"
                :disabled="!widget.show_header_section"
                @change="validateField('show_product_score')"
              />
            </div>
            <div
              v-else-if="subSettingName === 'show_media_summary'"
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.show_media_summary"
                name="widget[show_media_summary]"
                :label="$t('form.show_media_summary')"
                :disabled="!widget.show_header_section"
                @change="validateField('show_media_summary')"
              />
              <div
                class="ReviewSettingsWidgetsNormalFormDialog__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
              >
                <label
                  :class="[
                    'AppForm__sub-group-title',
                    {
                      'AppForm__sub-group-title--disabled': !widget.show_header_section
                    }
                  ]"
                  for="widget[media_summary_sort]"
                >
                  {{ $t('media_summary_sort') }}
                </label>
                <AppSelectRadio
                  id="widget_media_summary_sort"
                  v-model="widget.media_summary_sort"
                  name="widget[media_summary_sort]"
                  :disabled="
                    !widget.show_header_section || !widget.show_media_summary
                  "
                  :options="
                    reviewSortOptions([
                      ReviewSort.RECENTLY_CREATED,
                      ReviewSort.MOST_SCORES,
                      ReviewSort.MOST_LIKES
                    ])
                  "
                  @change="validateField('media_summary_sort')"
                />
              </div>
            </div>
            <div
              v-else-if="subSettingName === 'use_review_keyword_filter'"
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.use_review_keyword_filter"
                name="widget[use_review_keyword_filter]"
                :label="$t('form.use_review_keyword_filter')"
                :disabled="!widget.show_header_section"
                @change="validateField('use_review_keyword_filter')"
              />
              <div
                class="ReviewSettingsWidgetsNormalFormDialog__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
              >
                <label
                  :class="[
                    'AppForm__group-description',
                    {
                      'AppForm__group-description--disabled':
                        !widget.show_header_section ||
                        !widget.use_review_keyword_filter
                    }
                  ]"
                >
                  {{ $t('use_review_keyword_filter_description') }}
                </label>
              </div>
            </div>
            <div
              v-else-if="subSettingName === 'show_notices'"
              :class="[
                'AppForm__sub-group-item',
                {
                  'ReviewSettingsWidgetsNormalFormDialog__sub-item':
                    isHeaderRenewed || widget.use_vuejs
                }
              ]"
            >
              <AppCheckbox
                v-model="widget.show_notices"
                name="widget[show_notices]"
                :label="$t('form.show_notices')"
                :disabled="disabledShowTitleSubSetting"
                @change="validateField('show_notices')"
              />
            </div>
            <div
              v-else-if="
                subSettingName === 'show_top_filter_menu' && !isShopifyBrand
              "
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.show_top_filter_menu"
                name="widget[show_top_filter_menu]"
                :label="
                  $t(
                    `form.show_top_filter_menu.${widget.renewed ? 'v2' : 'v1'}`
                  )
                "
                :disabled="
                  !widget.show_header_section ||
                    (widget.use_vuejs && !!widget.category_id)
                "
                @change="validateField('show_top_filter_menu')"
              />
              <AppFormHint
                v-if="widget.use_vuejs && !!widget.category_id"
                :message="$t('top_filter_disabled_reason')"
                priority="danger"
              />
            </div>
            <div
              v-else-if="subSettingName === 'show_score_filter'"
              class="AppForm__sub-group-item"
            >
              <ReviewSettingsShowScoreFilter
                :widget="widget"
                :disabled="!widget.show_header_section"
                @change="validateField('show_score_filter')"
              />
            </div>
            <div
              v-else-if="subSettingName === 'show_search_section'"
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.show_search_section"
                name="widget[show_search_section]"
                :label="$t('form.show_search_section')"
                :disabled="!widget.show_header_section"
                @change="validateField('show_search_section')"
              />
            </div>
            <div
              v-else-if="subSettingName === 'show_review_options_search'"
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.show_review_options_search"
                name="widget[show_review_options_search]"
                :label="$t('form.show_review_options_search')"
                :disabled="!widget.show_header_section"
                @change="validateField('show_review_options_search')"
              />
              <div
                class="ReviewSettingsWidgetsNormalFormDialog__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
              >
                <AppTextInput
                  v-model="widget.review_options_title_html"
                  :placeholder="$t('review_options_title_html_placeholder')"
                  :disabled="!widget.show_review_options_search"
                  name="widget[review_options_title_html]"
                  @blur="validateField('review_options_title_html')"
                />
                <div
                  :class="[
                    'AppForm__group-description',
                    {
                      'AppForm__group-description--disabled':
                        !widget.show_header_section ||
                        !widget.show_review_options_search
                    }
                  ]"
                >
                  {{ $t('show_review_options_search_description') }}
                </div>
              </div>
            </div>
            <div
              v-else-if="subSettingName === 'thumbnail_summary'"
              class="AppForm__sub-group-item"
            >
              <AppCheckbox
                v-model="widget.thumbnail_summary"
                name="widget[thumbnail_summary]"
                :label="$t('form.thumbnail_summary')"
                :disabled="!widget.show_header_section || widget.renewed"
                @change="validateField('thumbnail_summary')"
              />
              <input
                v-if="widget.renewed"
                type="hidden"
                name="widget[thumbnail_summary]"
                :value="false"
              />
              <div
                class="AppForm__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-group"
              >
                <label
                  :class="[
                    'AppForm__sub-group-title',
                    {
                      'AppForm__sub-group-title--disabled':
                        !widget.show_header_section ||
                        widget.renewed ||
                        !widget.thumbnail_summary
                    }
                  ]"
                  for="widget[thumbnail_sort]"
                >
                  {{ $t('thumbnail_sort') }}
                </label>
                <AppSelectRadio
                  id="widget_thumbnail_sort"
                  v-model="widget.thumbnail_sort"
                  name="widget[thumbnail_sort]"
                  :disabled="
                    !widget.show_header_section || !widget.thumbnail_summary
                  "
                  :options="
                    reviewSortOptions([
                      ReviewSort.RECENTLY_CREATED,
                      ReviewSort.MOST_SCORES,
                      ReviewSort.MOST_LIKES
                    ])
                  "
                  @change="validateField('thumbnail_sort')"
                />
              </div>
              <div class="AppForm__sub-group">
                <label
                  :class="[
                    'AppForm__sub-group-title',
                    {
                      'AppForm__sub-group-title--disabled':
                        !useThumbnailSummarySubSetting || widget.renewed
                    }
                  ]"
                  for="widget[no_item_message]"
                >
                  {{ $t('no_item_message') }}
                </label>
                <AppTextInput
                  id="widget_no_item_message"
                  v-model="widget.no_item_message"
                  name="widget[no_item_message]"
                  :disabled="!useThumbnailSummarySubSetting"
                  :placeholder="$t('no_item_message_placeholder')"
                  @change="validateField('no_item_message')"
                />
                <div class="AppForm__sub-group-hint">
                  {{ $t('no_item_message_hint') }}
                </div>
              </div>
              <div class="AppForm__sub-group">
                <label
                  :class="[
                    'AppForm__sub-group-title',
                    {
                      'AppForm__sub-group-title--disabled':
                        !useThumbnailSummarySubSetting || widget.renewed
                    }
                  ]"
                  for="widget[photo_thumbnail_color]"
                >
                  {{ $t('form.photo_thumbnail_color') }}
                </label>
                <AppSelectRadio
                  id="widget_photo_thumbnail_color"
                  v-model="widget.photo_thumbnail_color"
                  name="widget[photo_thumbnail_color]"
                  :disabled="!useThumbnailSummarySubSetting"
                  :options="[
                    { label: $t('photo_thumbnail_color_true'), value: true },
                    { label: $t('photo_thumbnail_color_false'), value: false }
                  ]"
                  @change="validateField('photo_thumbnail_color')"
                />
              </div>
            </div>
            <div
              v-else-if="subSettingName === 'photo_thumbnail_color'"
              class="AppForm__sub-group-item"
            >
              <div
                class="AppForm__sub-group ReviewSettingsWidgetsNormalFormDialog__sub-item"
              >
                <label
                  :class="[
                    'AppForm__sub-group-title',
                    {
                      'AppForm__sub-group-title--disabled': !widget.show_header_section
                    }
                  ]"
                  for="widget[photo_thumbnail_color]"
                >
                  {{ $t('form.photo_thumbnail_color') }}
                </label>
                <AppSelectRadio
                  id="widget_photo_thumbnail_color"
                  v-model="widget.photo_thumbnail_color"
                  :disabled="
                    !widget.show_header_section || !widget.show_media_summary
                  "
                  name="widget[photo_thumbnail_color]"
                  :options="[
                    { label: $t('photo_thumbnail_color_true'), value: true },
                    { label: $t('photo_thumbnail_color_false'), value: false }
                  ]"
                  @change="validateField('photo_thumbnail_color')"
                />
              </div>
            </div>
            <div
              v-else-if="subSettingName === 'review_options_title_html'"
              class="AppForm__sub-group-item"
            >
              <label
                :class="[
                  'AppForm__sub-group-title',
                  {
                    'AppForm__sub-group-title--disabled': !widget.show_header_section
                  }
                ]"
                for="widget[review_options_title_html]"
              >
                {{ $t('form.review_options_title_html') }}
              </label>
              <AppTextInput
                v-model="widget.review_options_title_html"
                :placeholder="$t('review_options_title_html_placeholder')"
                :disabled="!widget.show_header_section"
                name="widget[review_options_title_html]"
                @blur="validateField('review_options_title_html')"
              />
              <div
                :class="[
                  'AppForm__group-description',
                  {
                    'AppForm__group-description--disabled': !widget.show_header_section
                  }
                ]"
              >
                {{ $t('show_review_options_search_description') }}
              </div>
            </div>
          </div>
        </template>
      </template>
      <div v-else-if="group.id === 'show_title'" class="AppForm__group-field">
        <AppTextInput
          v-model="widget.title"
          :placeholder="$t('title_placeholder')"
          :disabled="!widget.show_title"
          :invalid="!!group.error"
          name="widget[title]"
          @blur="validateField('show_title')"
        />
      </div>
      <div
        v-else-if="group.id === 'thumbnail_summary'"
        :class="[
          'AppForm__group-field',
          { 'AppForm__group-field--disabled': !widget.thumbnail_summary }
        ]"
      >
        <input
          v-if="widget.renewed"
          type="hidden"
          name="widget[thumbnail_summary]"
          :value="false"
        />
        <div class="AppForm__sub-group">
          <label
            v-if="!widget.renewed"
            class="AppForm__sub-group-title"
            for="thumbnail_sort"
          >
            {{ $t('thumbnail_sort') }}
          </label>
          <span v-else class="AppForm__sub-group-title">
            {{ $t('thumbnail_sort') }}
          </span>
          <AppSelectRadio
            id="widget_thumbnail_sort"
            v-model="widget.thumbnail_sort"
            name="widget[thumbnail_sort]"
            :disabled="!widget.thumbnail_summary"
            :options="
              reviewSortOptions([
                ReviewSort.RECENTLY_CREATED,
                ReviewSort.MOST_SCORES,
                ReviewSort.MOST_LIKES
              ])
            "
          />
        </div>
        <div class="AppForm__sub-group">
          <label
            v-if="!widget.renewed"
            class="AppForm__sub-group-title"
            for="no_item_message"
          >
            {{ $t('no_item_message') }}
          </label>
          <span v-else class="AppForm__sub-group-title">
            {{ $t('no_item_message') }}
          </span>
          <AppTextInput
            id="widget_no_item_message"
            v-model="widget.no_item_message"
            name="widget[no_item_message]"
            :disabled="!widget.thumbnail_summary"
            :placeholder="$t('no_item_message_placeholder')"
          />
          <div class="AppForm__sub-group-hint">
            {{ $t('no_item_message_hint') }}
          </div>
        </div>
        <div class="AppForm__sub-group">
          <label
            v-if="!widget.renewed"
            class="AppForm__sub-group-title"
            for="photo_thumbnail_color"
          >
            {{ $t('form.photo_thumbnail_color') }}
          </label>
          <span v-else class="AppForm__sub-group-title">
            {{ $t('form.photo_thumbnail_color') }}
          </span>
          <AppSelectRadio
            id="widget_photo_thumbnail_color"
            v-model="widget.photo_thumbnail_color"
            name="widget[photo_thumbnail_color]"
            :disabled="!widget.thumbnail_summary"
            :options="[
              { label: $t('photo_thumbnail_color_true'), value: true },
              { label: $t('photo_thumbnail_color_false'), value: false }
            ]"
          />
        </div>
      </div>
      <div v-else-if="group.id === 'max_pages'" class="AppForm__group-field">
        <AppSelectRadio
          v-model="widget.limit_pages"
          :options="[{ value: false }, { value: true }]"
          @change="validateField('max_pages')"
        >
          <template #label="{ value, checked }">
            <template v-if="value">
              <i18n path="limit_pages.true">
                <AppNumberInput
                  v-model="widget.max_pages"
                  name="widget[max_pages]"
                  :digits="2"
                  :disabled="!checked"
                  inline
                  @change="validateField('max_pages')"
                />
              </i18n>
            </template>
            <template v-else>
              <input
                :disabled="!checked"
                type="hidden"
                name="widget[max_pages]"
                value="0"
              />
              {{ $t('limit_pages.false') }}
            </template>
          </template>
        </AppSelectRadio>
      </div>
      <template v-else-if="group.id === 'display_orders_in_use'">
        <div class="AppForm__group-field">
          <ReviewSettingsWidgetsNormalFormOrderTypes
            v-model="widget.display_orders_in_use"
            :name="`${group.inputName}[]`"
            :org-default-type="widget.display_order"
            :options="group.options"
            @change-default-type="
              changeDefaultType(
                'display_orders_in_use',
                'display_order',
                $event
              )
            "
            @change="validateField('display_orders_in_use')"
          />
          <input
            type="hidden"
            name="widget[display_order]"
            :value="widget.display_order"
          />
        </div>
      </template>
      <template v-else-if="group.id === 'recommendation_review_sort'">
        <div class="AppForm__group-field">
          <div class="AppForm__sub-group">
            <AppMultipleSelectReview
              v-model="recommendationReviews"
              name="widget[recommendation_review_ids][]"
              :disabled="
                !widget.display_orders_in_use.includes(ReviewSort.MOST_LIKES)
              "
              :tags="tags"
              :title="$t('recommendation_review_ids_dialog_title')"
              :select-button-label="
                $t('recommendation_review_ids_select_button_label')
              "
              :social-media-only="isWidgetStyleExternal"
              @change="recommendationReviewsChanged()"
            />
          </div>
          <div class="AppForm__sub-group">
            <div class="AppForm__sub-group-title">
              {{ $t('recommendation_review_sort_type') }}
            </div>
            <AppSelectRadio
              v-model="widget.recommendation_review_sort_type"
              name="widget[recommendation_review_sort_type]"
              :disabled="
                !widget.display_orders_in_use.includes(ReviewSort.MOST_LIKES)
              "
              :options="
                reviewSortOptions([
                  ReviewSort.RECENTLY_CREATED,
                  ReviewSort.MOST_SCORES,
                  ReviewSort.MOST_HELPS
                ])
              "
              @change="validateField('recommendation_review_sort_type')"
            />
          </div>
        </div>
      </template>
      <template v-else-if="group.id === 'sort_types'">
        <div class="AppForm__group-field">
          <ReviewSettingsWidgetsNormalFormOrderTypes
            v-model="widget.sort_types"
            :name="group.inputName"
            :org-default-type="widget.default_sort_type"
            :options="group.options"
            :packing-method="group.packingMethod"
            @change-default-type="
              changeDefaultType('sort_types', 'default_sort_type', $event)
            "
            @change="validateField('sort_types')"
          >
            <template #label="{ value, checked }">
              <template v-if="value === ProductSortType.RECENTLY_MOST_REVIEWED">
                <i18n path="product_sort_type.recently_most_reviewed">
                  <AppNumberInput
                    v-model="widget.most_reviewed_by_created_at_from_day"
                    name="widget[most_reviewed_by_created_at_from_day]"
                    :default="1"
                    :digits="2"
                    :disabled="!checked"
                    inline
                  />
                </i18n>
              </template>
              <template v-else-if="value === ProductSortType.MOST_PURCHASED">
                <i18n path="product_sort_type.most_purchased">
                  <AppNumberInput
                    v-model="widget.most_purchased_since_n_days_ago"
                    name="widget[most_purchased_since_n_days_ago]"
                    :default="1"
                    :digits="2"
                    :disabled="!checked"
                    inline
                  />
                </i18n>
              </template>
            </template>
          </ReviewSettingsWidgetsNormalFormOrderTypes>
          <input
            type="hidden"
            name="widget[default_sort_type]"
            :value="widget.default_sort_type"
          />
        </div>
      </template>
      <template v-else-if="group.id === 'recommendation_product_sort'">
        <div class="AppForm__group-field">
          <AppMultipleSelectProduct
            v-model="widget.recommendation_products"
            name="widget[recommendation_product_ids][]"
            :title="$t('recommendation_product_ids_dialog_title')"
            :disabled="
              (widget.sort_types & ProductSortType.RECOMMENDATION) === 0
            "
            :select-button-label="
              $t('recommendation_product_ids_select_button_label')
            "
            @change="
              widget.default_sort_type == ProductSortType.RECOMMENDATION &&
                validateField('recommendation_product_ids')
            "
          />
          <div class="AppForm__sub-group">
            <div class="AppForm__sub-group-title">
              {{ $t('recommendation_product_sort_type') }}
            </div>
            <AppSelectRadio
              v-model="widget.recommendation_product_sort_type"
              name="widget[recommendation_product_sort_type]"
              :disabled="
                (widget.sort_types & ProductSortType.RECOMMENDATION) === 0
              "
              :options="
                ProductSortType.options([
                  ProductSortType.RECENTLY_CREATED,
                  ProductSortType.RECENTLY_REVIEWED,
                  ProductSortType.ALPHABETICAL,
                  ProductSortType.LOW_PRICE,
                  ProductSortType.HIGH_PRICE,
                  ProductSortType.MOST_REVIEWED,
                  ProductSortType.MOST_SCORES,
                  ProductSortType.RECENTLY_MOST_REVIEWED,
                  ProductSortType.MOST_PURCHASED
                ]).map(sortType => ({
                  ...sortType,
                  disabled:
                    !isShowScoreSummary &&
                    sortType.value === ProductSortType.MOST_SCORES
                }))
              "
              @change="
                widget.default_sort_type == ProductSortType.RECOMMENDATION &&
                  validateField('recommendation_product_sort_type')
              "
            />
          </div>
        </div>
      </template>
      <template v-else-if="group.id === 'use_custom_layout'">
        <AppSelectRadio
          v-model="widget.use_custom_layout"
          name="widget[use_custom_layout]"
          class="AppForm__sub-group-title"
          :options="group.options"
          @change="validateField('use_custom_layout')"
        >
          <template #sub-item="{ value, checked }">
            <template v-if="!value">
              <i18n path="fixed_number_posts_per_page.count">
                <AppNumberInput
                  v-model="widget.posts_per_page"
                  name="widget[posts_per_page]"
                  :invalid="!!group.errors.fixed_number_posts_per_page"
                  :digits="3"
                  :disabled="!checked"
                  @change="validateField('fixed_number_posts_per_page')"
                />
              </i18n>
              <AppFormError :error="group.errors.fixed_number_posts_per_page" />
            </template>
            <template v-else>
              <div
                v-for="review_axis in ['column', 'row']"
                :key="`layout_${review_axis}`"
                class="AppForm__group-field"
              >
                <i18n :path="`review_custom_layout.${review_axis}`">
                  <AppNumberInput
                    v-model="widget[`layout_${review_axis}`]"
                    :name="`widget[layout_${review_axis}]`"
                    :invalid="!!group.errors[`layout_${review_axis}`]"
                    :digits="3"
                    :disabled="!checked"
                    @change="validateField(`layout_${review_axis}`)"
                  />
                </i18n>
                <AppFormError :error="group.errors[`layout_${review_axis}`]" />
              </div>
              <div class="AppForm__group-field--mt16">
                <div class="AppForm__sub-group-title">
                  {{ $t(`gap_between_reviews.label`) }}
                </div>
                <div
                  v-for="gap_axis in ['column', 'row']"
                  :key="`${gap_axis}_gap`"
                  class="AppForm__group-field"
                >
                  <i18n :path="`gap_between_reviews.${gap_axis}`">
                    <AppNumberInput
                      v-model="widget[`${gap_axis}_gap`]"
                      :name="`widget[${gap_axis}_gap]`"
                      :invalid="!!group.errors[`${gap_axis}_gap`]"
                      :digits="3"
                      :disabled="!checked"
                      @change="validateField(`${gap_axis}_gap`)"
                    />
                  </i18n>
                  <AppFormError :error="group.errors[`${gap_axis}_gap`]" />
                </div>
              </div>
            </template>
          </template>
        </AppSelectRadio>
      </template>
      <template v-else-if="group.id === 'reviews_custom_layout'">
        <div class="AppForm__group-field">
          <i18n path="review_custom_layout.column">
            <AppNumberInput
              v-model="widget.layout_column"
              name="widget[layout_column]"
              :invalid="!!group.errors.layout_column"
              :digits="3"
              @change="validateField('layout_column')"
            />
          </i18n>
          <AppFormError :error="group.errors.layout_column" />
        </div>
        <div class="AppForm__group-field">
          <i18n path="review_custom_layout.row">
            <AppNumberInput
              v-model="widget.layout_row"
              name="widget[layout_row]"
              :invalid="!!group.errors.layout_row"
              :digits="3"
              @change="validateField('layout_row')"
            />
          </i18n>
          <AppFormError :error="group.errors.layout_row" />
        </div>
        <div class="AppForm__group-field--mt16">
          <div class="AppForm__sub-group-title">
            {{ $t('gap_between_reviews.label') }}
          </div>
          <div class="AppForm__group-field">
            <i18n :path="'gap_between_reviews.column'">
              <AppNumberInput
                v-model="widget.column_gap"
                name="widget[column_gap]"
                :invalid="!!group.errors.column_gap"
                :digits="3"
                @change="validateField('column_gap')"
              />
            </i18n>
            <AppFormError :error="group.errors.column_gap" />
          </div>
          <div class="AppForm__group-field">
            <i18n :path="'gap_between_reviews.row'">
              <AppNumberInput
                v-model="widget.row_gap"
                name="widget[row_gap]"
                :invalid="!!group.errors.row_gap"
                :digits="3"
                @change="validateField('row_gap')"
              />
            </i18n>
            <AppFormError :error="group.errors.row_gap" />
          </div>
        </div>
      </template>
      <template v-else-if="group.id === 'reviews_count_display_format'">
        <div class="AppForm__group-field">
          <AppKeywordTextarea
            v-model="formObject.reviews_count_display_format"
            name="widget[reviews_count_display_format]"
            :keywords="keywords"
            :single-line="true"
            :invalid="!!group.errors.reviews_count_display_format"
            @blur="validateField('reviews_count_display_format')"
            @change="validateField('reviews_count_display_format')"
          />
        </div>
      </template>
      <template v-else-if="group.id === 'reviews_count_font'">
        <div
          :class="[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !widget.use_reviews_count_font_size
            }
          ]"
        >
          <AppCheckbox
            v-model="widget.use_reviews_count_font_size"
            name="widget[use_reviews_count_font_size]"
            :label="$t('font.size')"
          />
          <AppNumberInput
            v-model="widget.reviews_count_font_size"
            class="ReviewSettingsWidgetsNormalFormDialog__number-input"
            name="widget[reviews_count_font_size]"
            :digits="2"
            :min="1"
            :disabled="!widget.use_reviews_count_font_size"
          />
          px
        </div>
        <div
          :class="[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !widget.use_reviews_count_font_weight
            }
          ]"
        >
          <AppCheckbox
            v-model="widget.use_reviews_count_font_weight"
            name="widget[use_reviews_count_font_weight]"
            :label="$t('font.weight')"
          />
          <AppSelect
            v-model="widget.reviews_count_font_weight"
            name="widget[reviews_count_font_weight]"
            class="ReviewSettingsWidgetsNormalFormDialog__select"
            :options="WidgetFontWeight.options()"
            :disabled="!widget.use_reviews_count_font_weight"
          />
        </div>
        <div
          :class="[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !widget.use_reviews_count_font_color
            }
          ]"
        >
          <AppCheckbox
            v-model="widget.use_reviews_count_font_color"
            name="widget[use_reviews_count_font_color]"
            :label="$t('font.color')"
          />
          <AppColorPicker
            v-model="widget.reviews_count_font_color"
            name="widget[reviews_count_font_color]"
            class="ReviewSettingsWidgetsNormalFormDialog__color-input"
            :label="$t('font.color')"
            :disabled="!widget.use_reviews_count_font_color"
            @input="changeFontColor"
          />
        </div>
      </template>
      <template v-else-if="group.id === 'reviews_count_star'">
        <div class="ReviewSettingsWidgetsNormalFormDialog__group-fields">
          <label class="ReviewSettingsWidgetsNormalFormDialog__label">
            {{ $t('star.size') }}
          </label>
          <AppNumberInput
            v-model="widget.reviews_count_star_size"
            class="ReviewSettingsWidgetsNormalFormDialog__number-input"
            name="widget[reviews_count_star_size]"
            :digits="2"
            :min="1"
          />
        </div>
        <div class="ReviewSettingsWidgetsNormalFormDialog__group-fields">
          <label class="ReviewSettingsWidgetsNormalFormDialog__label">
            {{ $t('star.color') }}
          </label>
          <AppColorPicker
            v-model="widget.reviews_count_star_color"
            name="widget[reviews_count_star_color]"
            :label="$t('star.color')"
            @input="changeStarColor"
          />
        </div>
        <div class="AppForm__group-field">
          <AppCheckbox
            v-model="widget.reviews_count_star_format"
            name="widget[reviews_count_star_format]"
            :label="$t('form.reviews_count_star_format')"
          />
        </div>
      </template>
      <template v-else-if="group.id === 'use_custom_width'">
        <div
          class="AppForm__sub-group-item ReviewSettingsWidgetsNormalFormDialog__sub-item"
        >
          <AppSelectRadio
            v-model="widget.custom_width_type"
            name="widget[custom_width_type]"
            :options="WidgetWidthType.options()"
            :disabled="!widget.use_custom_width"
          >
            <template #label="{ value, checked }">
              <template v-if="value === WidgetWidthType.RESPONSIVE_WIDTH">
                <i18n path="custom_width.responsive_width">
                  <AppNumberInput
                    v-model="widget.responsive_width"
                    name="widget[responsive_width]"
                    :digits="4"
                    :disabled="!(checked && widget.use_custom_width)"
                    :invalid="!!group.errors.responsive_width"
                    inline
                    @change="validateField('responsive_width')"
                  />
                </i18n>
                <AppFormError
                  :error="checked ? group.errors.responsive_width : null"
                />
              </template>
              <template v-else-if="value === WidgetWidthType.FIXED_WIDTH">
                <i18n path="custom_width.fixed_width">
                  <AppNumberInput
                    v-model="widget.fixed_width"
                    name="widget[fixed_width]"
                    :digits="4"
                    :disabled="!(checked && widget.use_custom_width)"
                    :invalid="!!group.errors.fixed_width"
                    inline
                    @change="validateField('fixed_width')"
                  />
                </i18n>
                <AppFormError
                  :error="checked ? group.errors.fixed_width : null"
                />
              </template>
            </template>
          </AppSelectRadio>
          <AppFormHint
            v-if="isWidgetStyleList"
            :message="$t('custom_width.list_style_hint')"
            :disabled="!widget.use_custom_width"
          />
        </div>
      </template>
      <div v-else-if="group.id === 'use_reviews_button'">
        <div class="AppForm__group-field">
          <ReviewSettingsWidgetsNormalFormReviewsButtonTitle
            :widget="widget"
            :disabled="disabledReviewsButtonTitle"
            :error="group.errors.reviews_button_title"
            @title-blur="validateField('reviews_button_title')"
          />
        </div>
      </div>
      <template v-else-if="group.id === 'margin'">
        <div
          v-for="position in ['top', 'right', 'bottom', 'left']"
          :key="`margin_${position}`"
          class="AppForm__group-field"
        >
          <i18n :path="`margin.${position}`">
            <AppNumberInput
              v-model="widget[`margin_${position}`]"
              :name="`widget[margin_${position}]`"
              :digits="2"
              inline
              allow-negative
            />
          </i18n>
        </div>
      </template>
      <template v-else-if="group.id === 'enable_tab_scroll'">
        <ReviewSettingsWidgetEnablePaginationScrollCheckbox
          :is-device-pc="isDevicePc"
          :widget="widget"
          class="AppForm__group-field"
        >
          <template #label>
            <span class="ReviewSettingsWidgetsNormalFormDialog__group-title">
              {{ $t('enable_tab_scroll') }}</span
            >
          </template>
        </ReviewSettingsWidgetEnablePaginationScrollCheckbox>
      </template>
      <template
        v-else-if="
          (isGalleryRenewed || isHorizontalScrollRenewed) &&
            group.id === 'show_thumbnail_as_square'
        "
      >
        <div class="AppForm__group-description">
          {{ $t('show_thumbnail_as_square.description') }}
          <span
            class="ReviewSettingsWidgetsNormalFormDialog__show_thumbnail_as_square_detail"
            @click="openShowThumbnailAsSquareDescriptionDialog"
            ><u>{{ $t('app.detail') }}</u></span
          >
        </div>
        <AppSelectRadio
          v-model="widget.show_thumbnail_as_square"
          name="widget[show_thumbnail_as_square]"
          class="AppForm__group-field"
          :options="group.options"
          @blur="validateField('show_thumbnail_as_square')"
          @change="validateField('show_thumbnail_as_square')"
        />
      </template>
      <template v-else-if="group.id === 'show_widget_on_all_categories'">
        <div class="AppForm__group-field">
          <AppSelect
            v-model="widget.show_widget_on_all_categories"
            name="widget[show_widget_on_all_categories]"
            :options="group.options"
            @change="validateField('show_widget_on_all_categories')"
          />
        </div>
        <div class="AppForm__group-field">
          <template v-if="!group.value">
            <AppMultipleSelectProductCategory
              v-model="widget.widget_inclusion_category_ids"
              name="widget[widget_inclusion_category_ids][]"
              :disabled="widget.show_widget_on_all_categories"
              :exclude-category-values="
                widget.enable_widget_exclusion_category_ids
                  ? widget.widget_exclusion_category_ids
                  : []
              "
              :invalid="!!group.errors.widget_inclusion_category_ids"
              :placeholder="$t('widget_inclusion_category_ids_placeholder')"
              @blur="validateField('widget_inclusion_category_ids')"
              @change="validateField('widget_inclusion_category_ids')"
            />
            <AppFormError :error="group.errors.widget_inclusion_category_ids" />
          </template>
        </div>
        <div class="AppForm__group-field">
          <AppCheckbox
            v-model="widget.enable_widget_exclusion_category_ids"
            class="ReviewSettingsWidgetsNormalFormDialog__sub-group"
            name="widget[enable_widget_exclusion_category_ids]"
            :label="$t('form.enable_widget_exclusion_category_ids')"
          />
        </div>
        <div
          :class="[
            'ReviewSettingsWidgetsNormalFormDialog__sub-item',
            'ReviewSettingsWidgetsNormalFormDialog__sub-group'
          ]"
        >
          <AppMultipleSelectProductCategory
            v-model="widget.widget_exclusion_category_ids"
            name="widget[widget_exclusion_category_ids][]"
            :placeholder="$t('widget_exclusion_category_ids.placeholder')"
            :disabled="!widget.enable_widget_exclusion_category_ids"
            :exclude-category-values="
              widget.show_widget_on_all_categories
                ? []
                : widget.widget_inclusion_category_ids
            "
            :invalid="!!group.errors.widget_exclusion_category_ids"
            @blur="validateField('widget_exclusion_category_ids')"
            @change="validateField('widget_exclusion_category_ids')"
          />
          <AppFormHint
            :message="$t('widget_exclusion_category_ids.hint')"
            :disabled="!widget.enable_widget_exclusion_category_ids"
          />
          <AppFormError :error="group.errors.widget_exclusion_category_ids" />
        </div>
      </template>
    </template>
    <template #group-label="{ group, value, checked }">
      <template v-if="group.id === 'item_display_fields'">
        <template v-if="value === 8">
          <i18n path="item_display_field.reviews">
            <AppNumberInput
              v-model="widget.reviews_per_product"
              name="widget[reviews_per_product]"
              inline
              :digits="2"
              :disabled="!checked"
              @change="validateField('reviews_per_product')"
            />
          </i18n>
        </template>
      </template>
    </template>
    <template #group-sub-item="{ group, errors, value, checked }">
      <template v-if="group.id === 'product_display_filter'">
        <AppSelectProductCategory
          v-if="value === 10"
          v-model="widget.category_id"
          name="widget[category_id]"
          :disabled="!checked"
          @change="validateField('category_id')"
        />
        <AppSelectProduct
          v-else-if="value === 20"
          :products="widget.products"
          multiple
          name="widget[product_ids][]"
          :disabled="!checked"
          :placeholder="$t('product_ids_placeholder')"
          @change-items="widget.products = $event"
          @change="validateField('product_ids')"
        />
      </template>
      <template v-else-if="group.id === 'product_review_display_filter'">
        <AppMultipleSelect
          v-if="value === 30"
          v-model="widget.tag_ids"
          name="widget[tag_ids][]"
          :options="tagsOptions"
          :disabled="!checked"
          :placeholder="$t('product_review_display_filter_tag_ids_placeholder')"
        />
      </template>
      <template v-else-if="group.id === 'item_display_fields'">
        <AppSelectRadio
          v-if="value === 8"
          v-model="widget.display_order"
          :disabled="!checked"
          :options="
            reviewSortOptions([
              ReviewSort.RECENTLY_CREATED,
              ReviewSort.MOST_SCORES,
              ReviewSort.MOST_LIKES
            ])
          "
          name="widget[display_order]"
          @change="validateField('display_order')"
        />
      </template>
      <template v-else-if="group.id === 'message_initial_rows'">
        <template v-if="!isGalleryRenewed && value === 0">
          <div class="AppForm__sub-group-title">
            {{ $t('collapsed_title_description') }}
          </div>
          <AppTextInput
            v-model="widget.collapsed_title"
            class="AppForm__sub-group"
            :placeholder="$t('collapsed_title_placeholder')"
            :disabled="!checked"
            :invalid="!!errors.collapsed_title"
            name="widget[collapsed_title]"
            @blur="validateField('collapsed_title')"
            @change="validateField('collapsed_title')"
          />
          <AppFormError :error="errors.collapsed_title" />
        </template>
      </template>
      <template v-else-if="group.id === 'horizontal_scroll_enable_autoscroll'">
        <template v-if="value">
          <i18n path="horizontal_scroll_autoscroll_delay">
            <AppNumberInput
              v-model="widget.horizontal_scroll_autoscroll_delay"
              :disabled="!widget.horizontal_scroll_enable_autoscroll"
              name="widget[horizontal_scroll_autoscroll_delay]"
              :min="1"
              :max="99"
              :digits="2"
              @blur="validateField('horizontal_scroll_autoscroll_delay')"
              @change="validateField('horizontal_scroll_autoscroll_delay')"
            />
          </i18n>
          <AppFormError :error="errors.horizontal_scroll_autoscroll_delay" />
        </template>
        <template v-if="showHorizontalScrollSeeAll && !value">
          <div class="AppForm__sub-group-field">
            <AppCheckbox
              v-model="widget.horizontal_scroll_show_see_all_at_last_slide"
              name="widget[horizontal_scroll_show_see_all_at_last_slide]"
              :label="$t('form.horizontal_scroll_show_see_all_at_last_slide')"
              :disabled="widget.horizontal_scroll_enable_autoscroll"
            />
            <AppTextInput
              v-model="widget.horizontal_scroll_see_all_redirect_url"
              :placeholder="
                $t('horizontal_scroll_see_all_redirect_url_placeholder')
              "
              :disabled="
                !(
                  checked && widget.horizontal_scroll_show_see_all_at_last_slide
                )
              "
              :invalid="!!group.error"
              name="widget[horizontal_scroll_see_all_redirect_url]"
              @blur="
                validateField('horizontal_scroll_show_see_all_at_last_slide')
              "
            />
            <AppFormError
              :error="errors.horizontal_scroll_show_see_all_at_last_slide"
            />
          </div>
        </template>
      </template>
      <template v-else-if="group.id === 'show_thumbnail_as_square'">
        <template v-if="value === false">
          <div class="AppForm__sub-group-hint">
            {{ $t('show_thumbnail_as_square.hint') }}
          </div>
        </template>
      </template>
      <template
        v-else-if="
          ['pagination_method', 'pagination_method_for_mobile'].includes(
            group.id
          )
        "
      >
        <ReviewSettingsWidgetEnablePaginationScrollCheckbox
          :is-device-pc="group.id === 'pagination_method'"
          :showable="value === PaginationMethod.PAGINATE"
          :disabled="!checked"
          :widget="widget"
          :tooltip="$t('preview_not_supported_tooltip')"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import DialogSize from '@/enums/DialogSize';
import PaginationMethod from '@/enums/PaginationMethod';
import WidgetType from '@/enums/WidgetType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetWidthType from '@/enums/WidgetWidthType';
import ReviewSort from '@/enums/ReviewSort';
import ProductSortType from '@/enums/ProductSortType';
import ReviewWidgetFontWeight from '@/enums/ReviewWidgetFontWeight';
import ListDisplayField from '@/enums/ListDisplayField';
import ThumbnailShape from '@/enums/ThumbnailShape';
import ThumbnailRatio from '@/enums/ThumbnailRatio';
import IframePreviewHelper from '@/mixins/IframePreviewHelper';

import widgetTypeConfigManagingReviews from './ReviewSettingsWidgetsNormalFormDialog/widgetTypeConfigManagingReviews';
import widgetTypeConfigReviews from './ReviewSettingsWidgetsNormalFormDialog/widgetTypeConfigReviews';
import widgetTypeConfigProductReviews from './ReviewSettingsWidgetsNormalFormDialog/widgetTypeConfigProductReviews';
import widgetTypeConfigProductScore from './ReviewSettingsWidgetsNormalFormDialog/widgetTypeConfigProductScore';
import widgetTypeConfigProducts from './ReviewSettingsWidgetsNormalFormDialog/widgetTypeConfigProducts';
import widgetTypeConfigEtc from './ReviewSettingsWidgetsNormalFormDialog/widgetTypeConfigEtc';
import widgetTypeConfigCategoryReviews from './ReviewSettingsWidgetsNormalFormDialog/widgetTypeConfigCategoryReviews';

import ReviewWidgetPreview from '../reviews/components/ReviewWidgetPreview.vue';
import ReviewSettingsShowHeaderSection from './components/ReviewSettingsShowHeaderSection';
import ReviewSettingsShowScoreFilter from './components/ReviewSettingsShowScoreFilter';

export default {
  name: 'ReviewSettingsWidgetsNormalFormDialog',
  components: {
    ReviewWidgetPreview,
    ReviewSettingsShowHeaderSection,
    ReviewSettingsShowScoreFilter
  },
  mixins: [DialogFormView, IframePreviewHelper],
  props: {
    widgetId: {
      type: Number,
      default: null
    },
    widgetType: {
      type: Number,
      required: true
    }
  },
  data() {
    let widgetTypeConfig;
    switch (this.widgetType) {
      case WidgetType.REVIEWS:
        widgetTypeConfig = widgetTypeConfigReviews;
        break;
      case WidgetType.PRODUCT_REVIEWS:
        widgetTypeConfig = widgetTypeConfigProductReviews;
        break;
      case WidgetType.PRODUCT_SCORE:
        widgetTypeConfig = widgetTypeConfigProductScore;
        break;
      case WidgetType.MANAGING_REVIEWS:
        widgetTypeConfig = widgetTypeConfigManagingReviews;
        break;
      case WidgetType.ETC:
        widgetTypeConfig = widgetTypeConfigEtc;
        break;
      case WidgetType.CATEGORY_REVIEWS:
        widgetTypeConfig = widgetTypeConfigCategoryReviews;
        break;
      default:
        widgetTypeConfig = widgetTypeConfigProducts;
        break;
    }
    return {
      widgetTypeConfig,
      isLoading: true,
      recommendationReviews: [],
      subBrands: [],
      tags: [],
      keepBoardStyleForMobile: false,
      previewUrl: '',
      useWidgetPreview: false,
      isPreviewUsable: false
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings', 'currentBrand', 'vueWidget']),
    ...mapGetters('session', ['isShopifyBrand', 'isUsingNlp', 'isImwebBrand']),
    ...mapGetters(['isSuperAdminAccessible', 'pathWithBrandParams']),
    ...mapGetters('reviewWidgets', ['reviewSortOptions']),
    WidgetType() {
      return WidgetType;
    },
    WidgetStyle() {
      return WidgetStyle;
    },
    WidgetWidthType() {
      return WidgetWidthType;
    },
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    ReviewSort() {
      return ReviewSort;
    },
    ProductSortType() {
      return ProductSortType;
    },
    PaginationMethod() {
      return PaginationMethod;
    },
    WidgetFontWeight() {
      return ReviewWidgetFontWeight;
    },
    widget() {
      return this.formObject;
    },
    formSections() {
      if (this.isLoading) return [];

      const sections = {
        common: [
          this.isImwebBrand ? null : 'widget_displayable',
          this.isImwebBrand ? null : 'widget_device_type',
          this.isImwebBrand ||
          this.isWidgetTypeProductScore ||
          this.isWidgetTypeProducts
            ? null
            : 'widget_style',
          this.isImwebBrand || this.isWidgetTypeManagingReviews ? null : 'name'
        ]
      };

      if (this.isWidgetTypeProductScore || this.widgetStyleSelected) {
        const typeSections = this.widgetTypeConfig.formSections({
          widgetStyle: this.widgetStyle,
          isGodo: this.isGodo,
          isDeviceMobile: this.isDeviceMobile,
          isDevicePc: this.isDevicePc,
          isRenewed: this.widget.renewed,
          isGalleryRenewed: this.isGalleryRenewed,
          isShopifyBrand: this.isShopifyBrand,
          useSubBrandFilter: this.reviewSettings.use_sub_brand_filter_setting,
          useVuejs: this.widget.use_vuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
        });

        _.forEach(
          typeSections,
          (groups, section) =>
            (sections[section] =
              section in sections ? [...sections[section], ...groups] : groups)
        );
        sections.design = [
          ...(sections.design || []),
          'custom_top_image_url',
          'custom_bottom_image_url',
          'background_color',
          this.isDevicePc ? 'use_custom_width' : null,
          'margin',
          'css',
          'javascript'
        ];
      }

      this.filterSections(sections, 'keep_board_style_for_mobile');
      this.filterSections(sections, 'background_color');
      this.filterSections(sections, 'review_popup_display_type_for_mobile');
      this.filterSections(sections, 'reviews_count');

      return _.map(sections, (groupIds, sectionId) => ({
        id: sectionId,
        label: sectionId === 'common' ? null : this.$t(`form.${sectionId}`),
        groups: groupIds
          .filter(id => id)
          .map(id => {
            const group = { id, ...this.groupDefinitionMap[id] };
            group.label =
              id === 'enable_tab_scroll' || id === 'display_review_info'
                ? null
                : group.label || this.$t(`form.${id}`);
            return group;
          })
      })).filter(section => section.groups.length > 0);
    },
    widgetStyle: {
      get() {
        return this.widget.widget_sub_style || this.widget.widget_style;
      },
      set(newValue) {
        switch (newValue) {
          case WidgetStyle.BEST_GALLERY:
          case WidgetStyle.SQUARE_GALLERY:
          case WidgetStyle.ROUND_THUMBNAIL_GALLERY:
          case WidgetStyle.MAIN_GALLERY:
            this.widget.widget_style = WidgetStyle.GALLERY;
            this.widget.widget_sub_style = newValue;
            break;
          case WidgetStyle.DETACHABLE_BOARD:
            this.widget.widget_style = WidgetStyle.BOARD;
            this.widget.widget_sub_style = newValue;
            break;
          default:
            this.widget.widget_style = newValue;
            this.widget.widget_sub_style = null;
        }
      }
    },
    widgetStyleOptions() {
      let widget_styles = this.widgetTypeConfig.widgetStyles({
        currentWidgetStyle: this.orgFormObject.widget_style,
        isDevicePc: this.isDevicePc,
        isSuperAdminAccessible: this.isSuperAdminAccessible,
        showBoardStyle: this.showBoardStyle
      });
      if (this.isShopifyBrand) {
        widget_styles = widget_styles.filter(
          widget_style =>
            ![
              WidgetStyle.MAIN_GALLERY,
              WidgetStyle.GRID,
              WidgetStyle.OPEN_MARKET,
              WidgetStyle.RESPONSIVE,
              WidgetStyle.KEYWORD
            ].includes(widget_style)
        );
      }
      return WidgetStyle.options(widget_styles);
    },
    widgetStyleSelected() {
      const { widget_style } = this.widget;
      return widget_style !== null && widget_style !== undefined;
    },
    tagsOptions() {
      return this.tags.map(t => ({ label: t.name, value: t.id }));
    },
    subBrandsOptions() {
      return this.subBrands.map(t => ({ label: t.name, value: t.id }));
    },
    groupDefinitionMap() {
      return {
        ...this.groupDefinitionMapCommon,
        ...this.groupDefinitionMapDisplayWidgets,
        ...this.groupDefinitionMapDisplayReviews,
        ...this.groupDefinitionMapDisplayProducts,
        ...this.groupDefinitionMapSort,
        ...this.groupDefinitionMapFeature,
        ...this.groupDefinitionMapDesign
      };
    },
    groupDefinitionMapCommon() {
      return {
        widget_displayable: {
          type: 'checkbox'
        },
        widget_device_type: this.widgetIdExist
          ? null
          : {
              type: 'select_radio',
              options: WidgetDeviceType.options(
                this.orgFormObject.widget_device_type ===
                  WidgetDeviceType.PC_MOBILE
                  ? undefined
                  : [WidgetDeviceType.PC, WidgetDeviceType.MOBILE]
              ),
              validate: ['required'],
              disabled: this.isWidgetTypeManagingReviews,
              labelDisabled: this.isWidgetTypeManagingReviews
            },
        widget_style: this.widgetIdExist
          ? null
          : {
              validate: this.isWidgetTypeProducts ? null : ['required'],
              labelDisabled: this.isWidgetTypeManagingReviews,
              description: this.isSuperAdminAccessible
                ? this.$t('widget_style_description')
                : null
            },
        renewed: {
          type: 'checkbox',
          superAdmin: true,
          installationAgency: true
        },
        name: {
          groupDescription: this.$t('name_group_description'),
          type: 'text',
          placeholder: this.$t('name_placeholder'),
          validate: ['required']
        },
        posts_per_page: {
          type: 'number',
          digits: 3,
          label: this.isWidgetTypeProducts
            ? this.$t('form.products_per_page')
            : this.isWidgetTypeProductScore
            ? this.$t('form.max_media')
            : this.isMaxPostsAvailable
            ? this.$t('form.max_posts')
            : null,
          validate: [
            'required',
            this.widget.widget_style === WidgetStyle.PRODUCT_OPTIONS
              ? { rule: 'min_max_integer', min: 1, max: 20 }
              : this.widget.widget_style === WidgetStyle.HORIZONTAL_SCROLL
              ? { rule: 'min_max_integer', min: 1, max: 100 }
              : 'positive_integer'
          ]
        },
        use_custom_layout: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('fixed_number_posts_per_page.label'),
              value: false
            },
            { label: this.$t('review_custom_layout.label'), value: true }
          ],
          fields: !this.widget.use_custom_layout
            ? [
                {
                  id: 'fixed_number_posts_per_page',
                  value: () => this.widget.posts_per_page,
                  validate: ['required', 'positive_integer']
                }
              ]
            : [
                {
                  id: 'layout_column',
                  value: () => this.widget.layout_column,
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 1, max: 20 }
                  ]
                },
                {
                  id: 'layout_row',
                  value: () => this.widget.layout_row,
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 1, max: 20 }
                  ]
                },
                {
                  id: 'row_gap',
                  value: () => this.widget.row_gap,
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 0, max: 1000 }
                  ]
                },
                {
                  id: 'column_gap',
                  value: () => this.widget.column_gap,
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 0, max: 1000 }
                  ]
                }
              ],
          validate: ['required']
        },
        reviews_custom_layout: {
          required: true,
          fields: [
            {
              id: 'layout_column',
              value: () => this.widget.layout_column,
              validate: [
                'required',
                this.widgetStyle == WidgetStyle.MAIN_GALLERY ||
                this.widgetStyle == WidgetStyle.GALLERY
                  ? { rule: 'min_max_integer', min: 1, max: 8 }
                  : this.widgetStyle == WidgetStyle.BEST_GALLERY
                  ? { rule: 'min_max_integer', min: 1, max: 6 }
                  : { rule: 'min_max_integer', min: 1, max: 20 }
              ]
            },
            {
              id: 'layout_row',
              value: () => this.widget.layout_row,
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 20 }
              ]
            },
            {
              id: 'row_gap',
              value: () => this.widget.row_gap,
              validate: [
                'required',
                { rule: 'min_max_integer', min: 0, max: 1000 }
              ]
            },
            {
              id: 'column_gap',
              value: () => this.widget.column_gap,
              validate: [
                'required',
                this.widgetStyle == WidgetStyle.MAIN_GALLERY ||
                this.widgetStyle == WidgetStyle.GALLERY
                  ? { rule: 'min_max_integer', min: 0, max: 32 }
                  : this.widgetStyle == WidgetStyle.BEST_GALLERY
                  ? { rule: 'min_max_integer', min: 0, max: 80 }
                  : { rule: 'min_max_integer', min: 0, max: 1000 }
              ]
            }
          ]
        },
        no_item_action: {
          type: 'checkbox',
          name: null,
          fields: this.widget.no_item_action
            ? [
                {
                  id: 'min_items_count',
                  validate: [
                    'required',
                    ...(this.isMaxPostsAvailable
                      ? [
                          {
                            rule: v => v < this.widget.posts_per_page,
                            errorMessage: this.$t('validations.min_items_count')
                          }
                        ]
                      : [])
                  ]
                },
                ...(this.widget.no_item_action_type === 'image'
                  ? [
                      {
                        id: 'no_item_image_url',
                        validate: [
                          'required',
                          'url_format',
                          { rule: 'check_image', async: true }
                        ]
                      }
                    ]
                  : [])
              ]
            : []
        },
        reviews_count_no_item_action: {
          type: 'checkbox',
          name: 'widget[reviews_count_no_item_action]',
          groupDescription: this.$t('no_item_action_min_reviews_count_widget')
        }
      };
    },
    groupDefinitionMapDisplayWidgets() {
      return {
        show_widget_on_all_categories: {
          type: 'select_radio',
          groupDescription: this.$t(
            'show_widget_on_all_categories_group_description'
          ),
          options: [
            {
              label: this.$t('show_widget_on_all_categories_true'),
              value: true
            },
            {
              label: this.$t('show_widget_on_all_categories_false'),
              value: false
            }
          ],
          fields: [
            !this.widget.show_widget_on_all_categories
              ? {
                  id: 'widget_inclusion_category_ids',
                  validate: ['required']
                }
              : null,
            this.widget.enable_widget_exclusion_category_ids
              ? {
                  id: 'widget_exclusion_category_ids',
                  validate: ['required']
                }
              : null
          ].filter(v => v)
        }
      };
    },
    groupDefinitionMapDisplayReviews() {
      return {
        category_id: {
          type: 'select_product_category',
          placeholder: this.$t('category_id_placeholder'),
          groupDescription: this.$t('category_id_group_description')
        },
        sub_brand_ids: {
          type: 'multiple_select',
          placeholder: this.$t('sub_brand_ids_placeholder'),
          groupDescription: this.$t('sub_brand_ids_description'),
          options: this.subBrandsOptions
        },
        tag_id: {
          type: 'select_filterable',
          groupDescription: this.$t('tag_id_group_description'),
          placeholder: this.$t('tag_id_placeholder'),
          options: this.tagsOptions
        },
        exclude_tag_id: {
          type: 'select_filterable',
          groupDescription: this.$t('exclude_tag_id_group_description'),
          placeholder: this.$t('exclude_tag_id_placeholder'),
          options: this.tagsOptions
        },
        filter_photo_only: {
          type: 'select_radio',
          options: [
            { label: this.$t('filter_photo_only_false'), value: false },
            { label: this.$t('filter_photo_only_true'), value: true }
          ]
        },
        display_review_type: {
          type: 'select_radio',
          options: [
            { label: this.$t('display_review_type_photo_text'), value: 0 },
            { label: this.$t('display_review_type_photo'), value: 10 },
            { label: this.$t('display_review_type_text'), value: 20 },
            { label: this.$t('display_review_type_photo_thumbnail'), value: 30 }
          ]
        },
        display_review_info: {
          type: 'infobox',
          value: this.$t('display_review_info')
        }
      };
    },
    groupDefinitionMapDisplayProducts() {
      return {
        product_display_filter: {
          type: 'select_radio',
          options: this.isShopifyBrand
            ? [
                { label: this.$t('product_display_filter.all'), value: 0 },
                {
                  label: this.$t('product_display_filter.selected_products'),
                  value: 20
                }
              ]
            : [
                { label: this.$t('product_display_filter.all'), value: 0 },
                {
                  label: this.$t('product_display_filter.selected_category'),
                  value: 10
                },
                {
                  label: this.$t('product_display_filter.selected_products'),
                  value: 20
                }
              ]
        },
        product_review_display_filter: {
          type: 'select_radio',
          options: [
            { label: this.$t('product_review_display_filter.all'), value: 0 },
            {
              label: this.$t('product_review_display_filter.with_review'),
              value: 10
            },
            {
              label: this.$t('product_review_display_filter.with_photo_review'),
              value: 20
            },
            {
              label: this.$t(
                'product_review_display_filter.with_tagged_review'
              ),
              value: 30
            }
          ]
        },
        exclude_product_ids: {
          type: 'select_product',
          multiple: true,
          groupDescription: this.$t('exclude_product_ids_group_description'),
          products: this.formObject.exclude_products,
          eventHandlers: {
            'change-items': products =>
              this.$set(this.formObject, 'exclude_products', products)
          }
        },
        exclude_category_ids: {
          label: this.isWidgetTypeProductReviews
            ? this.$t('exclude_category_ids.label.product_reviews_widget')
            : this.$t('exclude_category_ids.label.other_widgets'),
          type: 'multiple_select_product_category',
          placeholder: this.$t('exclude_category_ids.placeholder'),
          groupDescription: this.isWidgetTypeProductReviews
            ? this.$t('exclude_category_ids.description.product_reviews_widget')
            : this.$t('exclude_category_ids.description.other_widgets')
        },
        show_sold_out_products: { type: 'checkbox' }
      };
    },
    groupDefinitionMapSort() {
      return {
        display_orders_in_use: {
          options: this.reviewSortOptions([
            ReviewSort.RECENTLY_CREATED,
            ReviewSort.MOST_SCORES,
            ReviewSort.MOST_HELPS,
            ReviewSort.MOST_LIKES
          ]),
          validate: [
            'required',
            {
              rule: v =>
                v.includes(this.widget.display_order) &&
                this.widget.display_order,
              errorMessage: this.$t('validations.display_order')
            }
          ]
        },
        display_order: {
          type: 'select_radio',
          options: this.widgetTypeConfig.displayOrderOptions({
            displayOrdersInUse: this.widget.display_orders_in_use
          })
        },
        recommendation_review_sort: {},
        group_by_product_display_order: {
          type: 'select_radio',
          options: ReviewSort.options([
            ReviewSort.RECENTLY_CREATED,
            ReviewSort.ALPHABETICAL,
            ReviewSort.MOST_REVIEWED
          ])
        },
        managing_reviews_sort_asc: {
          type: 'select_radio',
          options: [
            { label: this.$t('managing_reviews_sort_asc_true'), value: true },
            { label: this.$t('managing_reviews_sort_asc_false'), value: false }
          ]
        },
        sort_types: {
          packingMethod: 'bit_flag',
          options: ProductSortType.options(),
          validate: [
            'required',
            {
              rule: v => v !== 0,
              errorMessage: this.$t('validations.required')
            },
            {
              rule: v =>
                (v & this.widget.default_sort_type) !== 0 &&
                this.widget.default_sort_type,
              errorMessage: this.$t('validations.default_sort_type')
            }
          ]
        },
        recommendation_product_sort: {}
      };
    },
    groupDefinitionMapFeature() {
      return {
        show_header_section: {
          type: 'checkbox',
          fields:
            this.widget.version === 3
              ? [
                  {
                    id: 'use_title_and_description',
                    validate:
                      this.showTitleSubSetting &&
                      this.widget.use_title_and_description
                        ? [{ rule: 'required', value: () => this.widget.title }]
                        : []
                  },
                  {
                    id: 'description_text',
                    validate:
                      this.showTitleSubSetting &&
                      this.widget.use_title_and_description
                        ? [{ rule: 'max_length', limit: 50 }]
                        : []
                  },
                  {
                    id: 'reviews_button_title',
                    validate:
                      this.showTitleSubSetting && this.widget.use_reviews_button
                        ? ['required']
                        : []
                  },
                  {
                    id: 'review_write_button_label',
                    validate:
                      this.showTitleSubSetting &&
                      this.widget.use_review_write_button
                        ? ['required']
                        : []
                  }
                ]
              : [
                  {
                    id: 'show_title',
                    validate:
                      this.widget.show_title &&
                      this.isShowHeaderSectionSubSetting('show_title')
                        ? [
                            {
                              rule: 'required',
                              value: () => this.widget.title
                            }
                          ]
                        : []
                  },
                  {
                    id: 'description_text',
                    validate: this.isShowHeaderSectionSubSetting(
                      'description_text'
                    )
                      ? [{ rule: 'max_length', limit: 50 }]
                      : []
                  },
                  {
                    id: 'reviews_button_title',
                    validate:
                      !this.isMobileProductReviewsRenewed &&
                      this.isShowHeaderSectionSubSetting('reviews_button_title')
                        ? ['required']
                        : []
                  }
                ]
        },
        display_review_position: { type: 'checkbox' },
        use_original_product_image: {
          type: 'checkbox',
          description: this.$t('use_original_product_image_description')
        },
        external_widget: {
          type: 'checkbox',
          description: this.$t('external_widget_description')
        },
        show_title: {
          type: 'checkbox',
          validate: this.widget.show_title
            ? [
                {
                  rule: 'required',
                  value: () => this.widget.title
                }
              ]
            : []
        },
        thumbnail_summary: {
          type: 'checkbox',
          disabled: this.widget.renewed
        },
        photo_thumbnail_color: {
          type: 'select_radio',
          options: [
            { label: this.$t('photo_thumbnail_color_true'), value: true },
            { label: this.$t('photo_thumbnail_color_false'), value: false }
          ]
        },
        horizontal_scroll_enable_autoscroll: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('horizontal_scroll_enable_autoscroll_true'),
              value: true
            },
            {
              label: this.$t('horizontal_scroll_enable_autoscroll_false'),
              value: false
            }
          ],
          fields: this.widget.horizontal_scroll_enable_autoscroll
            ? [
                {
                  id: 'horizontal_scroll_autoscroll_delay',
                  value: () => this.widget.horizontal_scroll_autoscroll_delay,
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 1, max: 99 }
                  ]
                }
              ]
            : this.showHorizontalScrollSeeAll
            ? [
                {
                  id: 'horizontal_scroll_show_see_all_at_last_slide',
                  type: 'checkbox',
                  validate: this.widget
                    .horizontal_scroll_show_see_all_at_last_slide
                    ? [
                        {
                          rule: 'required',
                          value: () =>
                            this.widget.horizontal_scroll_see_all_redirect_url
                        },
                        {
                          rule: 'url_format',
                          value: () =>
                            this.widget.horizontal_scroll_see_all_redirect_url
                        },
                        {
                          rule: 'check_url',
                          async: true,
                          value: () =>
                            this.widget.horizontal_scroll_see_all_redirect_url
                        }
                      ]
                    : []
                }
              ]
            : []
        },
        show_product_score: { type: 'checkbox' },
        show_my_review_first: { type: 'checkbox' },
        review_show_photo_first: {
          type: 'checkbox',
          description: this.$t('review_show_photo_first_description')
        },
        display_admin_comments_first: { type: 'checkbox' },
        show_social_media_title: {
          type: 'checkbox',
          hint: this.$t('instagram_review_source_message_hint')
        },
        display_review_source_message_summary_on_list: {
          type: 'checkbox',
          hint: this.$t('instagram_review_source_message_hint')
        },
        display_share_message: { type: 'checkbox' },
        item_display_fields: {
          type: 'multiple_select_checkbox',
          packingMethod: 'bit_flag',
          options: this.isShopifyBrand
            ? [
                {
                  label: this.$t('item_display_field.product_detail'),
                  value: 1
                },
                {
                  label: this.$t('item_display_field.review_summary'),
                  value: 4
                },
                { label: this.$t('item_display_field.reviews'), value: 8 }
              ]
            : [
                {
                  label: this.$t('item_display_field.product_detail'),
                  value: 1
                },
                {
                  label: this.$t('item_display_field.category'),
                  value: 2
                },
                {
                  label: this.$t('item_display_field.review_summary'),
                  value: 4
                },
                { label: this.$t('item_display_field.reviews'), value: 8 }
              ]
        },
        list_display_fields: {
          type: 'multiple_select_checkbox',
          packingMethod: 'bit_flag',
          options: this.isShopifyBrand
            ? [
                {
                  label: this.$t('list_display_field.advanced_search'),
                  value: ListDisplayField.ADVANCED_SEARCH
                },
                {
                  label: this.$t('list_display_field.sort'),
                  value: ListDisplayField.SORT
                }
              ]
            : [
                {
                  label: this.$t('list_display_field.sort'),
                  value: ListDisplayField.SORT
                },
                {
                  label: this.$t('list_display_field.advanced_search'),
                  value: ListDisplayField.ADVANCED_SEARCH
                },
                {
                  label: this.$t('list_display_field.category'),
                  value: ListDisplayField.CATEGORY
                },
                ...(this.widget.use_vuejs
                  ? [
                      {
                        label: this.$t('list_display_field.price_range'),
                        value: ListDisplayField.PRICE_RANGE
                      }
                    ]
                  : [])
              ]
        }
      };
    },
    groupDefinitionMapDesign() {
      const { widget_style, renewed } = this.widget;
      return {
        title: {
          type: 'text',
          placeholder: this.$t('title_placeholder')
        },
        highlight_statistical_results: { type: 'checkbox' },
        description_text: {
          type: 'text',
          placeholder: this.$t('description_text_placeholder'),
          description: this.$t('description_text_description'),
          validate: [{ rule: 'max_length', limit: 50 }]
        },
        main_color: { type: 'color' },
        horizontal_scroll_initial_posts_on_page_for_pc: {
          type: 'number',
          digits: 1,
          validate: [{ rule: 'min_max_integer', min: 3, max: 8 }, 'required']
        },
        horizontal_scroll_initial_posts_on_page: {
          type: 'select_radio',
          options: [1, 2, 3].map(value => ({
            value,
            label: this.$t('horizontal_scroll_initial_posts_on_page_option', [
              value
            ])
          }))
        },
        posts_per_line: {
          type: 'number',
          digits: 2,
          label: this.isWidgetTypeProducts
            ? this.$t('form.products_per_line')
            : null,
          validate: ['required', 'positive_integer']
        },
        mobile_posts_per_line: {
          type: 'select_radio',
          label: this.isWidgetTypeProducts
            ? this.$t('form.mobile_products_per_line')
            : null,
          options: [1, 2, 3].map(value => ({
            label: this.isWidgetTypeProducts
              ? this.$t('mobile_products_per_line_option', [value])
              : this.$t('mobile_posts_per_line_option', [value]),
            value
          }))
        },
        photo_review_thumbnails_per_widget: {
          type: 'number',
          digits: 2,
          default: 0
        },
        comments_count_on_list: {
          type: 'number',
          digits: 2,
          default: 0
        },
        message_initial_rows: {
          type: 'select_radio',
          options: this.messageInitialRowsOptions,
          fields:
            this.widget.message_initial_rows === 0
              ? [{ id: 'collapsed_title', validate: ['required'] }]
              : []
        },
        show_image_when_collapsed: {
          type: 'select_radio',
          options: [
            { label: this.$t('show_image_when_collapsed_true'), value: true },
            {
              label: this.isDeviceMobile
                ? this.$t('show_image_when_collapsed_false_mobile')
                : this.$t('show_image_when_collapsed_false_pc'),
              value: false
            }
          ]
        },
        use_reviews_button: { type: 'checkbox' },
        reviews_button_title: {
          type: 'text',
          placeholder: this.$t('reviews_button_title_placeholder'),
          validate: ['required']
        },
        show_thumbnail_as_square: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('show_thumbnail_as_square.true'),
              value: true
            },
            {
              label: this.$t('show_thumbnail_as_square.false'),
              value: false
            }
          ]
        },
        product_thumbnail_shape: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('product_thumbnail_shape_square'),
              value: ThumbnailShape.SQUARE
            },
            {
              label: this.$t('product_thumbnail_shape_circle'),
              value: ThumbnailShape.CIRCLE
            }
          ]
        },
        review_popup_display_type_for_mobile: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('review_popup_display_type_for_mobile_default'),
              value: 0
            },
            {
              label: this.$t('review_popup_display_type_for_mobile_detail'),
              value: 10
            }
          ]
        },
        pagination_method: {
          type: 'select_radio',
          options: PaginationMethod.available_options({
            widget_style,
            mobile: false,
            renewed,
            use_vuejs: this.widget.use_vuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          })
        },
        pagination_method_for_mobile: {
          type: 'select_radio',
          options: PaginationMethod.available_options({
            widget_style,
            mobile: true,
            renewed,
            use_vuejs: this.widget.use_vuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          })
        },
        keep_board_style_for_mobile: {
          type: 'checkbox'
        },
        align_row: {
          type: 'checkbox',
          description: this.$t('align_row_description')
        },
        product_thumbnail_ratio: {
          type: 'select_radio',
          groupDescription: this.$t('product_thumbnail_ratio.description'),
          groupDescriptionDetailClickHandler: () => {
            this.alert({
              title: this.$t('form.product_thumbnail_ratio'),
              width: DialogSize.MIDDLE,
              message: this.$t('product_thumbnail_ratio.popup_message', {
                image_asset_link:
                  'https://assets.cre.ma/m/admin/v2/product_thumbnail_ratio_dialog@2x.png'
              })
            });
          },
          options: [
            {
              label: this.$t('product_thumbnail_ratio.square'),
              value: ThumbnailRatio.SQUARE
            },
            {
              label: this.$t('product_thumbnail_ratio.original'),
              value: ThumbnailRatio.ORIGINAL
            }
          ]
        },
        horizontal_scroll_contents_to_show: {
          type: 'hash_select_checkbox',
          options: [
            this.isWidgetTypeProductReviews
              ? null
              : 'horizontal_scroll_show_product_info',
            'horizontal_scroll_show_review_message'
          ]
            .filter(Boolean)
            .map(id => ({ id, label: this.$t(id) }))
        },
        review_options_title_html: {
          type: 'text',
          placeholder: this.$t('review_options_title_html_placeholder'),
          description: this.$t('show_review_options_search_description')
        },
        camera_icon: {
          type: 'image',
          description: this.$t('form.camera_icon_description')
        },
        use_renewed_header_style: {
          type: 'checkbox',
          superAdmin: true,
          installationAgency: true
        },
        custom_top_image_url: {
          type: 'text',
          placeholder: this.$t('custom_image_url.placeholder'),
          hint: this.$t('custom_image_url.hint'),
          validate: [
            'url_format',
            this.widget.custom_top_image_url
              ? { rule: 'check_image', async: true }
              : null
          ].filter(v => v)
        },
        custom_bottom_image_url: {
          type: 'text',
          placeholder: this.$t('custom_image_url.placeholder'),
          hint: this.$t('custom_image_url.hint'),
          validate: [
            'url_format',
            this.widget.custom_bottom_image_url
              ? { rule: 'check_image', async: true }
              : null
          ].filter(v => v)
        },
        background_color: { type: 'color' },
        reviews_count_display_format: {
          hint: this.$t('reviews_count_display_format.hint'),
          validate: [
            'required',
            {
              rule: 'html_format',
              errorMessage: this.$t('validations.html_format')
            },
            {
              rule: () => {
                const reviewsCountDisplayFormat = this.widget
                  .reviews_count_display_format;
                const pattern = /%\{reviews_count\}|%\{stars\}|%\{score\}/;

                return pattern.test(reviewsCountDisplayFormat);
              },
              errorMessage: this.$t('validations.reviews_count_display_format')
            }
          ]
        },
        reviews_count_font_family: {
          type: 'text',
          placeholder: this.$t('font_family_placeholder')
        },
        reviews_count_star_format: {
          type: 'checkbox'
        },
        reviews_count_font: {
          type: 'text',
          groupDescription: this.$t('form.reviews_count_font_description')
        },
        vue_widget_css: {
          // TODO: vue위젯 포팅 작업 완료 후 제거
          type: 'code_editor',
          lang: 'css',
          groupDescription: this.$t('css_description')
        },
        vue_widget_javascript: {
          // TODO: vue위젯 포팅 작업 완료 후 제거
          type: 'code_editor',
          lang: 'javascript',
          groupDescription: this.$t('javascript_description')
        },
        css: {
          type: 'code_editor',
          lang: 'css',
          groupDescription: this.$t('css_description')
        },
        javascript: {
          type: 'code_editor',
          lang: 'javascript',
          groupTooltip: this.widget.use_vuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            ? this.$t('preview_not_supported_tooltip')
            : '',
          groupDescription: this.$t('javascript_description')
        },
        use_custom_width: {
          type: 'checkbox',
          groupTooltip: this.$t('preview_not_supported_tooltip'),
          fields: this.widget.use_custom_width
            ? [
                {
                  id: 'responsive_width',
                  validate: [
                    ...(this.widget.custom_width_type ===
                    WidgetWidthType.RESPONSIVE_WIDTH
                      ? ['required', { rule: 'lte', allowed: 100 }]
                      : [])
                  ]
                },
                {
                  id: 'fixed_width',
                  validate: [
                    ...(this.widget.custom_width_type ===
                    WidgetWidthType.FIXED_WIDTH
                      ? ['required']
                      : [])
                  ]
                }
              ]
            : []
        },
        margin: {
          groupTooltip: this.$t('preview_not_supported_tooltip')
        }
      };
    },
    widgetIdExist() {
      return Boolean(this.widgetId);
    },
    isDevicePc() {
      return [WidgetDeviceType.PC, WidgetDeviceType.PC_MOBILE].includes(
        this.widget.widget_device_type
      );
    },
    isDeviceMobile() {
      return [WidgetDeviceType.MOBILE, WidgetDeviceType.PC_MOBILE].includes(
        this.widget.widget_device_type
      );
    },
    isDevicePcMobile() {
      return this.widget.widget_device_type === WidgetDeviceType.PC_MOBILE;
    },
    isGodo() {
      return this.currentBrand.shop_builder === 'godo';
    },
    isGalleryRenewed() {
      // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
      return this.isWidgetStyleGallery && this.widget.use_vuejs;
    },
    isBoardRenewed() {
      // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
      return this.isWidgetStyleBoard && this.widget.use_vuejs;
    },
    isHorizontalScrollRenewed() {
      // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
      return this.isWidgetStyleHorizontalScroll && this.widget.use_vuejs;
    },
    isHeaderRenewed() {
      return (
        this.isGalleryRenewed ||
        this.isBoardRenewed ||
        this.isHorizontalScrollRenewed ||
        this.isWidgetStyleList
      );
    },
    isUsingProductCategoryProductType() {
      return this.reviewSettings.use_product_category_product_type;
    },
    isShowScoreSummary() {
      return this.reviewSettings.show_score_summary;
    },
    isMaxPostsAvailable() {
      return (
        this.isWidgetTypeProductScore ||
        [
          WidgetStyle.GRID,
          WidgetStyle.PHOTO_THUMBNAIL,
          WidgetStyle.OPEN_MARKET,
          WidgetStyle.HORIZONTAL_SCROLL,
          WidgetStyle.PRODUCT_OPTIONS,
          WidgetStyle.KEYWORD
        ].includes(this.widget.widget_style)
      );
    },
    showHorizontalScrollSeeAll() {
      return [WidgetType.REVIEWS, WidgetType.CATEGORY_REVIEWS].includes(
        this.widgetType
      );
    },
    isWidgetTypeProductReviews() {
      return this.widgetType === WidgetType.PRODUCT_REVIEWS;
    },
    isWidgetTypeReviews() {
      return this.widgetType === WidgetType.REVIEWS;
    },
    isWidgetTypeProducts() {
      return this.widgetType === WidgetType.PRODUCTS;
    },
    isWidgetTypeProductScore() {
      return this.widgetType === WidgetType.PRODUCT_SCORE;
    },
    isWidgetTypeManagingReviews() {
      return this.widgetType === WidgetType.MANAGING_REVIEWS;
    },
    noItemActionLabel() {
      if (this.isWidgetTypeProducts) {
        return 'no_item_action_min_products_count';
      } else if (
        this.isWidgetTypeProductScore ||
        (this.isWidgetTypeProductReviews && this.isWidgetStyleGallery)
      ) {
        return 'no_item_action_min_media_count';
      }

      return 'no_item_action_min_reviews_count';
    },
    tagIdMap() {
      return _.keyBy(this.tags, 'id');
    },
    keywords() {
      const basicKeywords = ['stars', 'score', 'reviews_count'];
      return basicKeywords.map(key => ({
        key,
        desc: this.$t(`keywords.${key}`),
        value: '{{{key}}}'
      }));
    },
    showHeaderSectionSubSettings() {
      return this.widgetTypeConfig.showHeaderSectionSubSettings({
        widgetStyle: this.widget.widget_style,
        isRenewed: this.widget.renewed,
        isDevicePc: this.isDevicePc,
        isDeviceMobile: this.isDeviceMobile,
        isGalleryRenewed: this.isGalleryRenewed,
        isUsingProductCategoryProductType: this
          .isUsingProductCategoryProductType,
        isUsingNlp: this.isUsingNlp,
        useVuejs: this.widget.use_vuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
      });
    },
    useThumbnailSummarySubSetting() {
      return this.widget.show_header_section && this.widget.thumbnail_summary;
    },
    showTitleSubSetting() {
      return this.widget.show_header_section && this.widget.show_title;
    },
    isWidgetStyleList() {
      return WidgetStyle.LIST === this.widget.widget_style;
    },
    isWidgetStyleGallery() {
      return WidgetStyle.GALLERY === this.widget.widget_style;
    },
    isWidgetStyleBoard() {
      return WidgetStyle.BOARD === this.widget.widget_style;
    },
    isWidgetStyleExternal() {
      return WidgetStyle.EXTERNAL === this.widget.widget_style;
    },
    isWidgetStyleHorizontalScroll() {
      return WidgetStyle.HORIZONTAL_SCROLL === this.widget.widget_style;
    },
    disabledShowTitleSubSetting() {
      return (
        !this.widget.show_header_section ||
        (this.isHeaderRenewed && !this.widget.show_title)
      );
    },
    disabledReviewsButtonTitle() {
      return (
        this.disabledShowTitleSubSetting || !this.widget.use_reviews_button
      );
    },
    messageInitialRowsOptions() {
      if (this.isGalleryRenewed || this.isHorizontalScrollRenewed) {
        return [1, 2, 3].map(value => ({
          label: this.$t('widget.gallery.message_initial_rows_option', [value]),
          value
        }));
      } else if (this.isWidgetStyleExternal) {
        return [1, 2, 3, 5].map(value => ({
          label: this.$t('widget.external.message_initial_rows_option', [
            value
          ]),
          value
        }));
      } else {
        return [
          {
            label: this.$t('widget.list.message_initial_rows_zero'),
            value: 0
          },
          ...[1, 3, 5].map(value => ({
            label: this.$t('widget.list.message_initial_rows_option', [value]),
            value
          })),
          {
            label: this.$t('widget.list.message_initial_rows_all'),
            value: -1
          }
        ];
      }
    },
    isPreviewSupported() {
      if (!this.previewUrl) return false;
      if (this.isPreviewUsable) return false;

      return !this.isDevicePcMobile;
    },
    currentWidgetStyle() {
      return this.widget.widget_style;
    },
    showWidgetTypeNotSelected() {
      if (this.isWidgetTypeProductScore) return true;

      return this.widgetStyleSelected;
    },
    // NOTE: 상품 모바일 리스트 위젯 v2에 '전체리뷰로 이동하는 버튼 문구' 설정이 정상 적용되면 이 함수를 사용하는 코드와 함께 제거
    isMobileProductReviewsListRenewed() {
      return (
        this.isWidgetTypeProductReviews &&
        this.isDeviceMobile &&
        this.widget.renewed &&
        this.isWidgetStyleList
      );
    },
    showBoardStyle() {
      return (
        this.orgFormObject.widget_device_type === WidgetDeviceType.PC &&
        this.orgFormObject.widget_style === WidgetStyle.BOARD
      );
    },
    isMobileProductReviewsRenewed() {
      // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
      return (
        this.isMobileProductReviewsListRenewed ||
        (this.isWidgetTypeProductReviews &&
          this.isDeviceMobile &&
          this.widget.use_vuejs)
      );
    }
  },
  watch: {
    currentWidgetStyle: {
      handler() {
        this.eventBus.$emit('reset-hidden');
      }
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get(
        this.widgetIdExist
          ? `/widgets/${this.widgetId}/edit`
          : `/widgets/new?widget_type=${this.widgetType}`
      )
      .then(({ data }) => {
        this.previewUrl = data.preview_url;
        this.useWidgetPreview = data.use_widget_preview;
        this.isPreviewUsable = data.is_preview_usable;
        if (data.tags) this.tags = data.tags;
        if (data.sub_brands) this.subBrands = data.sub_brands;
        if (data.recommendation_reviews)
          this.recommendationReviews = data.recommendation_reviews.map(r => ({
            ...r,
            tags: this.formatTags(r)
          }));
        this.orgFormObject = {
          ...data.widget,
          no_item_action: !!data.widget.no_item_action_type,
          no_item_action_type: data.widget.no_item_action_type || 'hide',
          limit_pages: data.widget.max_pages !== 0
        };
        this.keepBoardStyleForMobile = data.widget.keep_board_style_for_mobile;
        this.$nextTick(() => {
          this.$watch('widget.renewed', value => {
            this.setDefaultReviewsButtonTitle(value);
          });
        });
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('reviewWidgets', [
      'CREATE_REVIEW_WIDGET',
      'UPDATE_REVIEW_WIDGET'
    ]),
    ...mapActions('dialog', ['openDialog', 'alert']),
    filterSections(sections, type) {
      switch (type) {
        case 'keep_board_style_for_mobile':
          return this.filterKeepBoardStyleForMobile(sections);
        case 'background_color':
          return this.filterBackgroundColor(sections);
        case 'review_popup_display_type_for_mobile':
          return this.filterReviewPopupDisplayTypeForMobile(sections);
        case 'reviews_count':
          return this.filterReviewsCount(sections);
      }
    },
    filterKeepBoardStyleForMobile(sections) {
      if (
        this.widget.widget_style === WidgetStyle.LIST &&
        this.isDeviceMobile &&
        !this.keepBoardStyleForMobile
      ) {
        sections.design = sections.design.filter(
          v => v !== 'keep_board_style_for_mobile'
        );
      }
    },
    filterBackgroundColor(sections) {
      if (
        sections.design &&
        !this.widgetTypeConfig.useBackgroundColor({
          widgetStyle: this.widget.widget_style
        })
      ) {
        sections.design = sections.design.filter(v => v !== 'background_color');
      }
    },
    filterReviewPopupDisplayTypeForMobile(sections) {
      if (
        this.widgetStyleSelected &&
        this.reviewSettings.use_renewed_review_popup &&
        this.isDeviceMobile
      ) {
        sections.design = sections.design.filter(
          v => v !== 'review_popup_display_type_for_mobile'
        );
      }
    },
    filterReviewsCount(sections) {
      const { widget_style } = this.widget;
      if (widget_style === WidgetStyle.COUNT_AND_SCORE) {
        sections.design = [
          'reviews_count_display_format',
          'reviews_count_font',
          'reviews_count_font_family',
          'reviews_count_star',
          this.isDevicePc ? 'use_custom_width' : null,
          'margin'
        ];
      }
    },
    getWidgetDefaultSettings() {
      api
        .get('/widgets/new', {
          params: {
            widget_type: this.widgetType,
            widget_style: this.widget.widget_style,
            widget_sub_style: this.widget.widget_sub_style,
            widget_device_type: this.widget.widget_device_type
          }
        })
        .then(({ data }) => {
          this.formObject = {
            ...data.widget,
            no_item_action: !!data.widget.no_item_action_type,
            no_item_action_type: data.widget.no_item_action_type || 'hide',
            limit_pages: data.widget.max_pages !== 0
          };
          this.keepBoardStyleForMobile =
            data.widget.keep_board_style_for_mobile;
        })
        .finally(() => this.validateField('widget_style'));
    },
    widgetStyleChanged() {
      if (this.isWidgetTypeProducts) return;

      this.getWidgetDefaultSettings();
    },
    widgetDeviceTypeChanged() {
      const section = this.formSections.find(s => s.id === 'common');
      if (!section) return;

      const group = section.groups.find(g => g.id === 'widget_style');
      if (
        group &&
        this.widgetStyleOptions.every(o => o.value !== this.widget.widget_style)
      ) {
        const default_widget_style = this.isWidgetTypeProducts
          ? this.widgetStyleOptions[0].value
          : null;
        this.$set(this.widget, 'widget_style', default_widget_style);
      }
      if (this.isWidgetTypeProducts) this.getWidgetDefaultSettings();
    },
    renewedChanged() {
      if (this.widget.renewed) {
        this.$set(this.widget, 'thumbnail_summary', false);
      } else if (
        this.widget.pagination_method_for_mobile === PaginationMethod.SEE_ALL
      ) {
        this.$set(
          this.widget,
          'pagination_method_for_mobile',
          PaginationMethod.PAGINATE
        );
      }
    },
    recommendationReviewsChanged() {
      this.widget.recommendation_review_ids = this.recommendationReviews.map(
        r => r.id
      );
      this.validateField('recommendation_review_sort');
    },
    formGroupChanged(group) {
      switch (group.id) {
        case 'renewed':
          this.renewedChanged();
          break;
        case 'widget_device_type':
          this.widgetDeviceTypeChanged();
          break;
        case 'horizontal_scroll_see_all_redirect_url':
          this.validateField('horizontal_scroll_show_see_all_at_last_slide');
          break;
        case 'posts_per_page':
          this.validateField('min_items_count');
          break;
        case 'title':
          this.validateField('show_title');
          break;
        case 'min_items_count':
        case 'no_item_image_url':
        case 'no_item_action_type':
          this.validateField('no_item_action');
          break;
      }
    },
    changeDefaultType(fieldName, defaultTypeName, defaultType) {
      this.$set(this.widget, defaultTypeName, defaultType);
      this.validateField(fieldName);
    },
    formatTags({ tag_ids }) {
      return tag_ids.map(id => ({
        id,
        label: this.tagIdMap[id.toString()].name,
        style: 'grey-outline',
        size: 'small'
      }));
    },
    setDefaultReviewsButtonTitle(renewed) {
      this.$set(
        this.widget,
        'reviews_button_title',
        this.$t(`reviews_button_title.widget_renewed_${renewed}`)
      );
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .request({
          data: formData,
          ...(this.widgetIdExist
            ? {
                url: `/widgets/${this.widgetId}`,
                method: 'patch',
                successMessage: this.$t('app.saved')
              }
            : {
                url: '/widgets',
                method: 'post',
                successMessage: this.$t('app.created')
              })
        })
        .then(({ data }) => {
          this.widgetIdExist
            ? this.UPDATE_REVIEW_WIDGET(data.widget)
            : this.CREATE_REVIEW_WIDGET(data.widget) &
              this.eventBus.$emit('focus-row');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    changeFontColor(color) {
      this.formObject.font_color = color;
      this.validateField('font_color');
    },
    changeStarColor(color) {
      this.formObject.star_color = color;
      this.validateField('star_color');
    },
    isShowHeaderSectionSubSetting(name) {
      return this.showHeaderSectionSubSettings.includes(name);
    },
    openShowThumbnailAsSquareDescriptionDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('form.show_thumbnail_as_square'),
          markdownText: this.$t('show_thumbnail_as_square.popup_message'),
          width: DialogSize.MIDDLE
        }
      ]);
    },
    updatePreview() {
      if (this.hasError) return;
      if (!this.useWidgetPreview) {
        this.displayVersion = this.currentVersion;
        return;
      }

      const formData = new FormData(this.$el.getElementsByTagName('form')[0]);
      const version = this.currentVersion;
      api
        .patch(`/widgets/${this.widgetId}/preview`, formData)
        .then(({ data }) => {
          this.displayVersion = version;
          this.previewUrl = data.preview_url;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.ReviewSettingsWidgetsNormalFormDialog__label {
  display: block;
  margin-bottom: 4px;
}

.ReviewSettingsWidgetsNormalFormDialog__group-fields {
  display: inline-block;
  vertical-align: top;
  & + & {
    margin-left: 24px;
  }
}

.ReviewSettingsWidgetsNormalFormDialog__summary-box {
  margin-bottom: 24px;
}

.ReviewSettingsWidgetsNormalFormDialog__number-input,
.ReviewSettingsWidgetsNormalFormDialog__select,
.ReviewSettingsWidgetsNormalFormDialog__color-input {
  margin-left: 5px;
  vertical-align: middle;
}

.ReviewSettingsWidgetsNormalFormDialog__group-title {
  @include text-label;
}

.ReviewSettingsWidgetsNormalFormDialog__sub-item {
  margin-left: 22px;
}
.ReviewSettingsWidgetsNormalFormDialog__sub-group {
  margin-top: 4px;
}

.ReviewSettingsWidgetsNormalFormDialog__show_thumbnail_as_square_detail {
  cursor: pointer;
}

.ReviewSettingsWidgetsNormalFormDialog__icon {
  position: relative;
  top: 2px;
}

::v-deep {
  .ReviewSettingsWidgetsNormalFormDialog__group-title-required {
    color: $color-red;
  }

  .AppModal__handle {
    position: absolute;
    right: 678px;
    width: 5px;
    height: 100%;
    cursor: ew-resize;
    z-index: 1;

    &:hover,
    &.AppModal__handle--visible {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

<i18n locale="ko">
{
  "title_edit": "{widget_type} 설정",
  "title_new": "{widget_type} 추가",
  "sub_title": "{widget_name} (위젯 ID {widget_id})",
  "use_vuejs_group_description": "크리마 리뷰 위젯이 디자인도 기능도 한층 개선되었습니다.<br>쇼핑몰에 맞게 설정 후 아래 [저장] 버튼을 통해 실제 쇼핑몰에 적용해보세요.",
  "widget_style_placeholder": "위젯을 선택해주세요.",
  "widget_style_description": "위젯 스타일 변경 시, 현재 설정된 위젯의 설정값은 모두 초기화 됩니다.",
  "name_group_description": "관리자 페이지 내 위젯 목록에서 표시하는 이름입니다.",
  "name_placeholder": "이름을 입력해주세요.",
  "no_item_action_min_reviews_count": "전체 리뷰가 {0} 개 이하일 때",
  "no_item_action_min_products_count": "전체 상품이 {0} 개 이하일 때",
  "no_item_action_min_media_count": "노출 포토/동영상 수가 {0} 개 이하일 때",
  "no_item_action_min_reviews_count_widget": "상품의 전체 리뷰가 0개 일 때 위젯을 숨깁니다.",
  "no_item_action_hide": "위젯 숨기기",
  "no_item_action_image": "대체 이미지 표시",
  "category_id_placeholder": "노출 카테고리를 선택해주세요.",
  "category_id_group_description": "선택한 카테고리에 포함되는 상품의 리뷰만 위젯에 노출합니다.",
  "product_ids_placeholder": "지정 상품 선택",
  "sub_brand_ids_description": "선택한 서브 브랜드 상품의 리뷰만 위젯에 노출합니다.",
  "sub_brand_ids_placeholder": "노출 브랜드를 선택해주세요.",
  "product_review_display_filter_tag_ids_placeholder": "지정 태그 선택",
  "tag_id_group_description": "선택한 태그를 포함하는 리뷰만 위젯에 노출합니다.",
  "tag_id_placeholder": "노출 태그 선택",
  "exclude_tag_id_group_description": "선택한 태그를 포함하는 리뷰를 위젯에 노출하지 않습니다.",
  "exclude_tag_id_placeholder": "미노출 태그 선택",
  "filter_photo_only_false": "모든 리뷰를 노출",
  "filter_photo_only_true": "포토/동영상 리뷰만 노출",
  "display_review_type_photo_text": "모든 리뷰",
  "display_review_type_photo": "포토/동영상 리뷰만",
  "display_review_type_text": "텍스트 리뷰만",
  "display_review_type_photo_thumbnail": "포토/동영상 리뷰의 첨부 이미지만",
  "recommendation_review_ids_dialog_title": "먼저 표시할 리뷰 추가",
  "recommendation_review_ids_select_button_label": "먼저 표시할 리뷰 추가",
  "recommendation_review_sort_type": "추천순 사용 시, 먼저 표시할 리뷰 다음 리뷰 정렬 방식 설정",
  "recommendation_product_ids_dialog_title": "먼저 표시할 상품 설정",
  "recommendation_product_ids_select_button_label": "먼저 표시할 상품 추가",
  "recommendation_product_sort_type": "추천 상품순 사용 시, 먼저 표시할 상품 다음 상품 정렬 방식 설정",
  "use_original_product_image_description": "크리마 서버에 저장된 이미지 대신 쇼핑몰에서 사용하는 원본 이미지를 보여줍니다. 원본 이미지 사용 시 GIF 이미지 표시가 가능합니다.",
  "external_widget_description": "위젯에서 리뷰작성버튼이 노출되지 않고, 상품 이미지 클릭시 상품 페이지로 이동하지 않습니다.",
  "show_title_list": "위젯 제목 영역 사용",
  "show_title_list_label": "위젯 제목",
  "title_placeholder": "위젯 상단에 표시할 제목을 입력해주세요.",
  "description_text_placeholder": "위젯 제목 하단에 추가할 설명을 입력해주세요.",
  "description_text_description": "입력하지 않으면 위젯 제목만 표시합니다.",
  "align_row_description": "위젯의 세로 높이를 가장 긴 내용에 맞추어 동일하게 표시합니다.",
  "review_options_title_html_placeholder": "추가 정보 검색 상단에 표시할 제목을 입력해주세요.",
  "show_review_options_search_description": "추가정보 항목 중 검색허용 항목이 있을 때만 표시합니다.",
  "use_review_keyword_filter_description": "AI 키워드 필터는 상품에 분석한 결과값이 있을 경우에만 노출됩니다.",
  "thumbnail_sort": "프리뷰 정렬 순서",
  "no_item_message": "프리뷰 없을 때, 대체 메시지",
  "no_item_message_placeholder": "프리뷰가 없을 때 표시할 대체 메시지를 입력해주세요.",
  "no_item_message_hint": "미입력 시 기본문구 '검색 결과가 없습니다.'를 표시합니다.",
  "instagram_review_source_message_hint": "인스타그램의 경우, 본문을 가져올 수 없으므로 해당 설정에 영향을 받지 않습니다. 본문이 필요한 경우, 등록 시 본문에 입력해주세요.",
  "horizontal_scroll_see_all_redirect_url_placeholder": "모두보기 버튼 클릭 시, 이동할 게시판 URL을 입력해주세요.",
  "horizontal_scroll_initial_posts_on_page_option": "리뷰 {0}개 표시",
  "mobile_posts_per_line_option": "한 줄에 {0}개 리뷰 표시",
  "mobile_products_per_line_option": "한 줄에 {0}개 상품 표시",
  "collapsed_title_description": "리뷰 본문을 모두 접었을 때, 표시하는 문구",
  "collapsed_title_placeholder": "문구를 입력해주세요.",
  "media_summary_sort": "포토&동영상 모아보기 정렬 순서",
  "managing_reviews_sort_asc_true": "과거 구매순",
  "managing_reviews_sort_asc_false": "최근 구매순",
  "show_image_when_collapsed_true": "썸네일 표시",
  "show_image_when_collapsed_false_pc": "아이콘 표시",
  "show_image_when_collapsed_false_mobile": "텍스트 표시",
  "product_thumbnail_shape_circle": "원형",
  "product_thumbnail_shape_square": "정사각형",
  "review_popup_display_type_for_mobile_default": "기본 팝업 (검은 배경)",
  "review_popup_display_type_for_mobile_detail": "상세 정보 노출 팝업 (흰 배경)",
  "enable_pagination_scroll": "페이지 이동 시, 최상단으로 자동 스크롤",
  "fixed_top_menu_height": "상단 고정된 메뉴 높이 (px)",
  "fixed_top_menu_height_hint": "홈페이지 상단에 고정한 메뉴가 있다면, 메뉴가 위젯을 가리지 않도록 메뉴의 높이를 입력하세요.",
  "horizontal_scroll_autoscroll_delay": "자동 스크롤 속도 {0} 초",
  "horizontal_scroll_enable_autoscroll_true": "자동 스크롤",
  "horizontal_scroll_enable_autoscroll_false": "수동 스크롤",
  "horizontal_scroll_show_product_info": "상품 정보",
  "horizontal_scroll_show_review_message": "리뷰 본문",
  "photo_thumbnail_color_true": "컬러",
  "photo_thumbnail_color_false": "흑백",
  "review_show_photo_first_description": "설정 시 정렬 기준으로 동영상과 포토리뷰를 먼저 표시한 다음, 텍스트 리뷰를 표시합니다.",
  "enable_tab_scroll": "탭 이동 시, 최상단으로 자동 스크롤",
  "font_family_placeholder": "폰트 패밀리 이름을 입력해주세요.",
  "css_description": "CSS를 적용하여 위젯을 원하는 형태로 변경할 수 있지만 이 후 위젯 업데이트 시 디자인이 의도와 다르게 변경되거나 문제가 발생할 수 있습니다.<br />크리마에서는 CSS 적용으로 인해 발생한 문제에 대해서는 대응이 어려우니 참고하여 진행 부탁드립니다.",
  "javascript_description": "JavaScript를 적용하여 위젯을 원하는 형태로 변경할 수 있지만 이 후 위젯 업데이트 시 디자인이 의도와 다르게 변경되거나 문제가 발생할 수 있습니다.<br />크리마에서는 JavaScript 적용으로 인해 발생한 문제에 대해서는 대응이 어려우니 참고하여 진행 부탁드립니다.",
  "preview_not_supported_tooltip": "미리보기 영역에서는 이 설정이 적용되어 보이지 않지만,\n실제 쇼핑몰에서는 적용됩니다.",
  "top_filter_disabled_reason": "'지정 카테고리 상품만 노출' 설정 시 카테고리 필터를 사용할 수 없습니다.",
  "show_widget_on_all_categories_group_description": "어떤 상품 페이지에 위젯을 노출할지 설정할 수 있습니다.",
  "show_widget_on_all_categories_true": "모든 카테고리 페이지에 노출",
  "show_widget_on_all_categories_false": "선택한 카테고리 페이지에서만 노출",
  "exclude_product_ids_group_description": "선택한 상품의 리뷰는 위젯에 노출하지 않습니다.",
  "widget_inclusion_category_ids_placeholder": "노출 카테고리를 선택해주세요.",
  "display_review_info": "카테고리 페이지를 자동으로 인식하여, 해당 카테고리에 포함되는 리뷰 중 아래 설정값을 만족하는 리뷰를 노출합니다.",
  "exclude_category_ids": {
    "label": {
      "product_reviews_widget": "지정 카테고리 미노출",
      "other_widgets": "지정 카테고리 상품 미노출"
    },
    "description": {
      "product_reviews_widget": "설정한 카테고리의 상품에는 해당 위젯이 노출되지 않습니다.",
      "other_widgets": "선택한 카테고리에 포함되는 상품의 리뷰는 위젯에 노출하지 않습니다."
    },
    "placeholder": "미노출 카테고리를 선택해주세요."
  },
  "widget": {
    "list": {
      "message_initial_rows_zero": "모두 접기",
      "message_initial_rows_option": "{0}줄 보여주고 나머지 접기",
      "message_initial_rows_all": "모두 보여주기"
    },
    "gallery": {
      "message_initial_rows_option": "{0}줄까지 보여주기"
    },
    "external": {
      "message_initial_rows_option": "{0}줄까지 보여주기"
    }
  },
  "form": {
    "use_vuejs": "새로운 크리마 위젯 사용하기",
    "widget_displayable": "위젯 노출 여부",
    "select_widget_device_type": "디바이스 선택",
    "widget_device_type": "디바이스",
    "widget_style": "위젯 스타일",
    "renewed": "위젯 V2 사용",
    "name": "위젯 이름",
    "no_item_action": "위젯 숨김 설정",
    "reviews_count_no_item_action": "위젯 숨김 설정",

    "display_widgets": "위젯 노출 설정",
    "show_widget_on_all_categories": "위젯 사용 페이지",
    "enable_widget_exclusion_category_ids": "다음 카테고리 페이지는 제외",

    "display_reviews": "노출 리뷰 설정",
    "category_id": "지정 카테고리 상품만 노출",
    "sub_brand_ids": "지정 서브 브랜드 상품만 노출",
    "tag_id": "지정 태그 리뷰만 노출",
    "exclude_tag_id": "지정 태그 리뷰 미노출",
    "filter_photo_only": "노출 리뷰 종류",
    "display_review_type": "노출 리뷰 종류",

    "display_products": "노출 상품 설정",
    "product_display_filter": "노출 상품",
    "product_review_display_filter": "리뷰 조건에 따른 노출 상품",
    "exclude_product_ids": "지정 상품 리뷰 미노출",
    "show_sold_out_products": "품절 상품 표시",

    "sort": "정렬 방식 관리",
    "display_orders_in_use": "사용할 리뷰 정렬 방식",
    "display_order": "기본 정렬 방식",
    "group_by_product_display_order": "상품별 보기 시, 기본 리뷰 정렬",
    "recommendation_review_sort": "추천순 사용 시, 우선 정렬 리뷰 설정",
    "sort_types": "사용할 상품 정렬 방식",
    "recommendation_product_sort": "추천 상품순 사용 시, 우선 정렬 상품 설정",
    "managing_reviews_sort_asc": "작성가능한 리뷰 정렬 방식",

    "feature": "사용 항목 및 기능 설정",
    "show_header_section": "리뷰 상단 영역 사용",
    "show_notices": "공지사항 사용",
    "display_review_position": "리뷰 순번 사용 (PC)",
    "use_original_product_image": "쇼핑몰 원본 상품 썸네일 사용",
    "external_widget": "쇼핑몰 외 사이트에서 사용",
    "show_title": "위젯 제목 사용",
    "title": "위젯 제목",
    "highlight_statistical_results": "위젯 내 특정 통계 강조",
    "main_color": "그래프 및 강조 문구 색상",
    "show_search_section": "정렬 및 검색 사용",
    "show_review_options_search": "추가 정보 검색 사용",
    "show_top_filter_menu": {
      "v1": "카테고리 영역 사용",
      "v2": "카테고리 필터 사용"
    },
    "thumbnail_summary": "포토/동영상 리뷰 프리뷰 사용",
    "photo_thumbnail_color": "프리뷰 기본 색상",
    "horizontal_scroll_show_see_all_at_last_slide": "스크롤 마지막에 모두보기 버튼 사용",
    "show_product_score": "평점 영역 사용",
    "show_media_summary": "포토&동영상 모아보기 사용",
    "show_my_review_first": "본인 리뷰 우선 표시",
    "show_score_filter": "별점 필터 사용",
    "use_review_keyword_filter": "AI 키워드 필터 사용",
    "review_show_photo_first": "포토/동영상 리뷰 우선표시",
    "display_admin_comments_first": "관리자 댓글 우선 표시",
    "show_social_media_title": "소셜리뷰 원문 표시",
    "display_review_source_message_summary_on_list": "소셜리뷰 원문 요약 표시",
    "display_share_message": "등록시 작성한 내용 표시",
    "item_display_fields": "위젯 표시 정보",
    "list_display_fields": "위젯 상단 검색 및 정렬 메뉴 표시 정보",

    "design": "위젯 디자인",
    "align_row": "위젯의 세로 높이를 동일하게 설정",
    "horizontal_scroll_initial_posts_on_page_for_pc": "한 페이지에 표시할 리뷰수 (PC)",
    "horizontal_scroll_initial_posts_on_page": "한 페이지에 표시할 리뷰수 (모바일)",
    "posts_per_page": "한 페이지에 표시할 리뷰수",
    "products_per_page": "한 페이지에 표시할 상품수",
    "use_custom_layout": "리뷰 표시 방식",
    "reviews_custom_layout": "리뷰 표시 방식",
    "posts_per_line": "한 줄에 표시할 리뷰수",
    "products_per_line": "한 줄에 표시할 상품수 (PC)",
    "mobile_posts_per_line": "한 줄에 표시할 리뷰수 (모바일)",
    "mobile_products_per_line": "한 줄에 표시할 상품수 (모바일)",
    "max_media": "위젯 전체에 표시할 포토/동영상 수",
    "max_posts": "위젯 전체에 표시할 리뷰수",
    "max_pages": "최대 페이지수",
    "photo_review_thumbnails_per_widget": "위젯 전체에 표시할 프리뷰 수",
    "comments_count_on_list": "위젯 전체에 표시할 댓글 수",
    "message_initial_rows": "리뷰 본문 표시 방법",
    "show_image_when_collapsed": "본문 접혀있을 때, 포토/동영상 리뷰 표시 방법",
    "use_reviews_button": "전체 리뷰 게시판 이동 버튼 사용",
    "reviews_button_title": "전체리뷰로 이동하는 버튼 문구",
    "reviews_button_title_super_admin_description": "해당 설정은 모바일 리스트 위젯 V2, 모바일 갤러리 위젯 V2 위젯에서 표시할 공간이 부족하여 미표시되고 있습니다.\n해당 문제가 해결되기 전까지 이 설정은 최고관리자 설정으로 표시됩니다.",
    "show_thumbnail_as_square": "리뷰 포토/동영상 썸네일 표시 방법",
    "product_thumbnail_shape": "상품 썸네일 모양",
    "review_popup_display_type_for_mobile": "리뷰 팝업 (모바일)",
    "pagination_method": "페이지 이동방식 (PC)",
    "pagination_method_for_mobile": "페이지 이동방식 (모바일)",
    "keep_board_style_for_mobile": "모바일에서 이전 스타일(게시판형) 유지",
    "product_thumbnail_ratio": "상품 썸네일 표시 방법",
    "horizontal_scroll_contents_to_show": "리뷰 표시 정보",
    "horizontal_scroll_enable_autoscroll": "스크롤 동작 방식",
    "review_options_title_html": "추가 정보 검색 제목",
    "camera_icon": "포토리뷰 아이콘 변경 (PC)",
    "camera_icon_description": "아이콘 사이즈는 가로 14px, 세로 12px 을 기준으로 합니다.",
    "use_renewed_header_style": "상단탭에 디자인 시스템 적용",
    "custom_top_image_url": "이 위젯의 상단에 추가할 이미지 주소",
    "custom_bottom_image_url": "이 위젯의 하단에 추가할 이미지 주소",
    "background_color": "위젯의 배경 색상",
    "reviews_count_display_format": "표시 정보",
    "reviews_count_font": "글자 스타일",
    "reviews_count_font_description": "기본적으로 쇼핑몰 스타일(CSS)을 따라 글자 스타일이 자동 적용됩니다.<br>스타일 변경을 원할 경우에만 변경하려는 옵션을 선택하여 값을 설정해주세요.",
    "reviews_count_font_family": "적용 폰트",
    "reviews_count_star": "별점 아이콘 스타일",
    "reviews_count_star_format": "별점 아이콘으로 평점 표시",
    "margin": "이 위젯에만 적용할 여백값",
    "css": "이 위젯에만 적용할 CSS",
    "javascript": "이 위젯에만 적용할 JavaScript",
    "description_text": "위젯 설명",
    "use_custom_width": "위젯의 너비 설정",
    "vue_widget_css": "새로운 위젯에만 적용할 CSS",
    "vue_widget_javascript": "새로운 위젯에만 적용할 Javascript"
  },
  "item_display_field": {
    "product_detail": "상품 정보 (상품명, 가격)",
    "category": "상품 카테고리",
    "review_summary": "상품 전체 리뷰 요약 정보 (평점, 전체 리뷰수)",
    "reviews": "상품 리뷰 최대 {0}개"
  },
  "product_thumbnail_ratio": {
    "description": "상품 이미지의 썸네일 비율을 선택할 수 있습니다.",
    "square": "정사각형으로 표시",
    "original": "원본 비율대로 표시",
    "popup_message": "- 정사각형으로 표시할 경우 상품의 행높이가 고정되며 정사각형의 썸네일에 맞춰 상품 이미지를 노출합니다.\n- 원본 비율대로 표시할 경우 상품 이미지를 원본 비율에 맞춰 노출합니다.\n\n<br />![]({image_asset_link})"
  },
  "list_display_field": {
    "sort": "정렬 방식 사용",
    "advanced_search": "상세 검색 사용",
    "category": "카테고리 필터 사용",
    "price_range": "가격대 필터 사용"
  },
  "reviews_button_title": {
    "widget_renewed_false": "후기게시판",
    "widget_renewed_true": "전체 상품 리뷰 보기"
  },
  "product_display_filter": {
    "all": "모든 상품",
    "selected_category": "지정 카테고리 상품",
    "selected_products": "지정 상품"
  },
  "product_review_display_filter": {
    "all": "모든 상품",
    "with_review": "리뷰가 있는 상품",
    "with_photo_review": "포토/동영상 리뷰가 있는 상품",
    "with_tagged_review": "지정 태그의 리뷰가 있는 상품"
  },
  "product_sort_type": {
    "recently_most_reviewed": "{0}일 이내 최근 리뷰 많은순",
    "most_purchased": "{0}일 이내 최근 판매량순"
  },
  "limit_pages": {
    "false": "전체 페이지 표시",
    "true": "{0}페이지 표시"
  },
  "show_thumbnail_as_square": {
    "description": "리뷰 포토/동영상의 썸네일 비율을 선택할 수 있습니다.",
    "hint": "원본 비율에 따라, 상하 혹은 좌우로 여백이 생길 수 있습니다.",
    "false": "원본 비율대로 표시",
    "true": "정사각형으로 표시",
    "popup_message": "* 정사각형으로 표시할 경우 리뷰의 행높이가 고정되며 정사각형의 썸네일에 맞춰 리뷰 포토/동영상을 노출합니다.\n* 원본 비율대로 표시할 경우 리뷰 포토/동영상을 원본 비율에 맞춰 노출합니다.\n\n![](https://assets.cre.ma/m/show_thumbnail_as_square_gallery_dialog@x2.png)"
  },
  "validations": {
    "display_order": "사용 중인 리뷰 정렬 방식 중 하나를 기본 방식으로 선택해야 합니다.",
    "default_sort_type": "사용 중인 상품 정렬 방식 중 하나를 기본 방식으로 선택해야 합니다.",
    "min_items_count": "전체 표시할 리뷰수보다 크거나 같은 숫자로는 설정할 수 없습니다.",
    "reviews_count_display_format": "사용 가능한 예약어가 입력되지 않았습니다. 1개 이상의 사용 가능한 예약어를 입력해주세요."
  },
  "reviews_count_display_format": {
    "hint": "사용 가능한 예약어 1개 이상을 입력하여 표시할 정보를 설정해주세요."
  },
  "custom_image_url": {
    "placeholder": "이미지 주소를 입력해주세요.",
    "hint": "쇼핑몰 주소가 https로 시작하는 경우에는 이미지의 주소 역시 https로 되어있어야만 이미지가 노출되며, http로 시작하는 경우에는 http, https 모두 이미지가 노출됩니다."
  },
  "margin": {
    "top": "상단 여백 {0}px",
    "right": "오른쪽 여백 {0}px",
    "bottom": "하단 여백 {0}px",
    "left": "왼쪽 여백 {0}px"
  },
  "keywords": {
    "stars": "별점 아이콘",
    "score": "평점",
    "reviews_count": "상품 리뷰 수"
  },
  "font": {
    "size": "글자 크기",
    "weight": "글자 굵기",
    "color": "글자 색상"
  },
  "star": {
    "size": "별점 아이콘 크기(px)",
    "color": "별점 아이콘 색상"
  },
  "custom_width": {
    "responsive_width": "{0}% 차지하게 하기",
    "fixed_width": "{0}px 까지 가득 채우기",
    "list_style_hint": "리스트 위젯에 720px 미만의 너비값을 입력하면 잘림현상이 발생할 수 있습니다."
  },
  "fixed_number_posts_per_page": {
    "label": "한 페이지에 표시할 리뷰수 고정",
    "count": "{0} 개"
  },
  "review_custom_layout": {
    "label": "리뷰 레이아웃 고정",
    "column": "가로: {0} 개",
    "row": "세로: {0} 줄"
  },
  "gap_between_reviews": {
    "label": "리뷰 사이 여백",
    "column": "좌우: {0} px",
    "row": "상하: {0} px"
  },
  "widget_exclusion_category_ids": {
    "hint": "예를 들어 [아우터] 카테고리 페이지에서만 위젯을 노출 이고, [아우터 > 재킷] 카테고리 페이지 제외 이면<br/>[아우터 > 재킷]을 제외한 [아우터]의 모든 하위 카테고리에 위젯을 노출합니다.",
    "placeholder": "미노출 카테고리를 선택해주세요."
  }
}
</i18n>
<i18n locale="en">
{
  "no_item_action_min_reviews_count": "If the total number of reviews is less than {0}",
  "no_item_action_min_media_count": "If the number of photo/video(s) is below {0}",
  "item_display_field": {
    "reviews": "Maximum of {0} product reviews"
  },
  "product_sort_type": {
    "recently_most_reviewed": "Most reviewed within {0} days",
    "most_purchased": "Most sold within {0} days"
  },
  "limit_pages": {
    "true": "Display {0} pages"
  },
  "margin": {
    "top": "Top margin {0}px",
    "right": "Right margin {0}px",
    "bottom": "Bottom margin {0}px",
    "left": "Left margin {0}px"
  },
  "custom_width": {
    "responsive_width": "Occupy {0} % of the screen",
    "fixed_width": "Make it full to maximum {0} px",
    "list_style_hint": "If the width of main reviews widget is smaller than 720px, it may cause cropping issues."
  }
}
</i18n>
