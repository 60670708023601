var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppButton',{staticClass:"ReviewSettingsBrandEvaluationTypes__add-button",attrs:{"label":_vm.$t('add_brand_evaluation_type')},on:{"click":_vm.addBrandEvaluationType}}),_c('AppTable',{attrs:{"table-id":"review-settings-brand_evaluation_types-table","columns":_vm.COLUMNS,"rows":_vm.rows,"no-data":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(column.id === 'name')?[_c('ul',[_c('li',{staticClass:"table-line"},[_c('input',{attrs:{"type":"hidden","name":_vm.inputName('id')},domProps:{"value":row.id}}),_c('input',{attrs:{"type":"hidden","name":_vm.inputName('evaluation_type_id')},domProps:{"value":row.evaluation_type_id}}),_c('AppSelectFilterable',{attrs:{"value":row.evaluation_type_id,"options":_vm.evaluationTypeOptions,"disabled":_vm.isDisabled(row),"invalid":!!_vm.errors[
                  ("brand_evaluation_types[" + (row.id) + "][evaluation_type_id]")
                ],"placeholder":_vm.$t('placeholder')},on:{"change":function($event){return _vm.changeEvaluationTypeId(row.id, $event)}}}),_c('AppFormError',{attrs:{"error":_vm.errors[
                  ("brand_evaluation_types[" + (row.id) + "][evaluation_type_id]")
                ]}})],1)])]:(column.id === 'options')?[_c('div',[_vm._v(" "+_vm._s(row.evaluation_type_id ? _vm.evaluationTypesMap[row.evaluation_type_id].options || '-' : '-')+" ")])]:(column.id === 'showable')?[_c('AppCheckbox',{attrs:{"checked":row.showable,"name":_vm.inputName('showable'),"input-style":"standalone","disabled":_vm.isTextType(row.evaluation_type_id) ||
              !(row.showable || _vm.enableToSetShowable)},on:{"change":function($event){return _vm.changeShowable(row.id, $event)}}})]:(column.id === 'searchable')?[_c('AppCheckbox',{attrs:{"checked":row.searchable,"name":_vm.inputName('searchable'),"input-style":"standalone","disabled":_vm.isTextType(row.evaluation_type_id)},on:{"change":function($event){return _vm.changeSearchable(row.id, $event)}}})]:(column.id === 'displayable')?[_c('AppCheckbox',{attrs:{"checked":row.displayable,"name":_vm.inputName('displayable'),"input-style":"standalone"},on:{"change":function($event){return _vm.changeDisplayable(row.id, $event)}}})]:(column.id === 'required')?[_c('AppCheckbox',{attrs:{"checked":row.required,"name":_vm.inputName('required'),"input-style":"standalone"},on:{"change":function($event){return _vm.changeRequired(row.id, $event)}}})]:(column.id === 'actions')?[_c('AppButton',{attrs:{"button-style":"red-outline","label":_vm.$t('app.delete'),"disabled":_vm.isDisabled(row)},on:{"click":function($event){return _vm.deleteBrandEvaluationType(row.id)}}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }