<template>
  <AppAjaxContent :is-loading="isLoading" class="ReviewSettingsWidgetsNormal">
    <AppSearchbar v-if="shouldDisplayAppSearchBar">
      <li>
        <AppButton
          :class="isWidgetTypeEtc ? 'super-admin__item' : ''"
          :label="
            $t('head.new_widget', { widget_type: WidgetType.t(widgetType) })
          "
          button-style="red"
          @click="newWidgetClicked"
        />
      </li>
    </AppSearchbar>
    <AppResourceTable
      table-id="widgets-table"
      :columns="columns"
      :rows="rows"
      :event-bus="tableEventBus"
    >
      <template #cell="{ column, value, rowIndex }">
        <AppBadge
          v-if="column === 'widget_display_status'"
          :badge-style="value ? 'mint-green' : 'default'"
          :label="
            value
              ? $t('widget_display_status.displayed')
              : $t('widget_display_status.hidden')
          "
        />
        <template v-else-if="column === 'widget_view_status'">
          <div>
            {{ value }}
            <AppExternalLinkWithIcon
              v-if="
                !isWidgetTypeManagingReviews(rowIndex) &&
                  selectedWidget(rowIndex).inserted_url
              "
              v-tooltip="$t('review_widgets.found_inserted_url')"
              :to="widgetInsertedUrl(rowIndex)"
              tabindex="-1"
            />
          </div>
        </template>
        <template v-else-if="column === 'actions'">
          <AppButton
            v-if="
              (isSuperAdminAccessible || isCurrentUserInstallationAgency) &&
                isSmartInstallationSupported
            "
            class="super-admin__item"
            :label="smartInstallLabel(rowIndex)"
            @click="widgetSmartInstallClicked(rowIndex)"
          />
          <AppButton
            :label="$t('app.settings')"
            @click="widgetSettingsClicked(rowIndex)"
          />
          <AppDropdownMenuButton
            :label="$t('app.manage')"
            :disabled="isWidgetTypeManagingReviews(rowIndex)"
            :tooltip="manageButtonTooltip(rowIndex)"
            :menu-items="[
              selectedWidget(rowIndex).widget_type !== WidgetType.ETC
                ? [
                    {
                      label: $t('table_body.copy_script'),
                      clickHandler: () => widgetCopyScriptClicked(rowIndex)
                    },
                    {
                      label: $t('review_widgets.move_to_widget_inserted_url'),
                      type: 'external_link',
                      to: widgetInsertedUrl(rowIndex),
                      disabled: !selectedWidget(rowIndex).inserted_url,
                      tooltip: selectedWidget(rowIndex).inserted_url
                        ? null
                        : $t('review_widgets.not_found_inserted_url')
                    },
                    ...(isSuperAdminAccessible
                      ? [
                          {
                            label: $t('review_widgets.move_to_widget_page'),
                            type: 'external_link',
                            to: selectedWidget(rowIndex).widget_shortcut_url,
                            superAdmin: true
                          },
                          {
                            label: $t('review_widgets.override_widget_setting'),
                            superAdmin: true,
                            clickHandler: () =>
                              overrideWidgetSettingClicked(rowIndex)
                          }
                        ]
                      : isCurrentUserInstallationAgency
                      ? [
                          {
                            label: $t('review_widgets.move_to_widget_page'),
                            type: 'external_link',
                            to: selectedWidget(rowIndex).widget_shortcut_url
                          },
                          {
                            label: $t('review_widgets.override_widget_setting'),
                            clickHandler: () =>
                              overrideWidgetSettingClicked(rowIndex)
                          }
                        ]
                      : [])
                  ]
                : [],
              [
                {
                  label: $t('review_widgets.delete_widget'),
                  clickHandler: () =>
                    deleteWidget(reviewWidgets[rowIndex].id, rowIndex),
                  style: 'danger',
                  disabled:
                    reviewWidgets[rowIndex].default ||
                    (!isSuperAdminAccessible &&
                      !isCurrentUserInstallationAgency),
                  tooltip: reviewWidgets[rowIndex].default
                    ? $t('review_widgets.tooltip_cannot_delete_default')
                    : !isSuperAdminAccessible &&
                      !isCurrentUserInstallationAgency
                    ? $t('review_widgets.tooltip_must_be_admin')
                    : null
                }
              ]
            ]"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetInstallMethod from '@/enums/WidgetInstallMethod';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetType from '@/enums/WidgetType';

import ReviewSettingsWidgetsView from './ReviewSettingsWidgetsView';

export default {
  name: 'ReviewSettingsWidgetsNormal',
  mixins: [ReviewSettingsWidgetsView],
  props: {
    widgetType: {
      type: Number,
      required: true
    },
    widgetSummary: {
      type: Function,
      default: () => () => ''
    }
  },
  data() {
    return {
      WIDGETS_URL: `/widgets?widget_type=${this.widgetType}`
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'reviewSettings']),
    ...mapState(['env']),
    ...mapState('reviewWidgets', ['reviewWidgets']),
    ...mapGetters('session', [
      'isSmartInstallationSupported',
      'isCurrentUserInstallationAgency',
      'isShopifyBrand',
      'isImwebBrand'
    ]),
    WidgetType() {
      return WidgetType;
    },
    isWidgetTypeEtc() {
      return this.widgetType === WidgetType.ETC;
    },
    columns() {
      return [
        { id: 'id', label: this.$t('table_head.id') },
        {
          id: 'widget_view_status',
          label: this.$t('table_head.widget_view_status'),
          tooltip: {
            message: this.$t('tooltip.widget_view_status'),
            info: this.$t('tooltip.widget_view_status_info')
          }
        },
        {
          id: 'widget_display_status',
          label: this.$t('table_head.widget_display_status'),
          tooltip: this.$t('tooltip.widget_display_status'),
          superAdmin: true
        },
        {
          id: 'widget_implementation_method', // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          label: this.$t('table_head.widget_implementation_method'),
          superAdmin: true
        },
        {
          id: 'widget_install_method',
          label: this.$t('table_head.widget_install_method'),
          superAdmin: true
        },
        {
          id: 'widget_device_type',
          label: this.$t('table_head.widget_device_type')
        },
        this.widgetType === WidgetType.PRODUCT_SCORE
          ? null
          : { id: 'widget_style', label: this.$t('app.style') },
        { id: 'name', label: this.$t('app.name'), align: 'left' },
        this.widgetType === WidgetType.ETC
          ? null
          : { id: 'summary', label: this.$t('app.summary'), align: 'left' },
        { id: 'actions', label: this.$t('app.actions') }
      ].filter(v => v);
    },
    rows() {
      const rows = this.reviewWidgets.map(widget => ({
        id: widget.id,
        widget_view_status: this.widgetViewStatus(widget),
        widget_display_status: widget.widget_displayable,
        widget_implementation_method: this.widgetImplementationMethod(widget),
        widget_install_method: WidgetInstallMethod.label(widget.install_method),
        widget_device_type: WidgetDeviceType.t(widget.widget_device_type),
        widget_style: this.widgetStyleName(widget),
        name: widget.name,
        summary: this.widgetSummary(widget),
        superAdmin: widget.widget_style === WidgetStyle.COUNT_AND_SCORE
      }));

      if (this.isSuperAdminAccessible || this.isCurrentUserInstallationAgency) {
        return rows;
      }

      return rows.filter(row => !row.superAdmin);
    },
    shouldDisplayAppSearchBar() {
      return (
        !this.isImwebBrand &&
        (!this.isWidgetTypeEtc ||
          this.isSuperAdminAccessible ||
          this.isCurrentUserInstallationAgency)
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    selectedWidget(rowIndex) {
      const widgetId = this.rows[rowIndex].id;
      return this.reviewWidgets.find(w => w.id == widgetId);
    },
    widgetStyleName(widget) {
      if (this.widgetType === WidgetType.PRODUCT_SCORE) return null;
      return widget.widget_sub_style
        ? WidgetStyle.t(widget.widget_sub_style)
        : WidgetStyle.t(widget.widget_style);
    },
    newWidgetClicked() {
      this.openDialog([
        'ReviewSettingsWidgetsNormalFormDialog',
        { widgetType: this.widgetType }
      ]).then(eventBus => eventBus.$on('focus-row', this.focusOnRow));
    },
    widgetCopyScriptClicked(index) {
      const widget = this.selectedWidget(index);
      this.openDialog(['ReviewSettingsWidgetScriptDialog', { widget }]);
    },
    widgetSettingsClicked(index) {
      const widget = this.selectedWidget(index);
      this.openDialog([
        'ReviewSettingsWidgetsNormalFormDialog',
        {
          widgetId: widget.id,
          widgetType: widget.widget_type
        }
      ]);
    },
    widgetSmartInstallClicked(index) {
      const widget = this.selectedWidget(index);
      this.openDialog([
        'ReviewSmartInstallationAttachWidgetManagerFormDialog',
        {
          widgetId: widget.id,
          smartInstallations: widget.smart_installations
        }
      ]);
    },
    isWidgetTypeManagingReviews(index) {
      const widget = this.selectedWidget(index);
      return widget.widget_type === WidgetType.MANAGING_REVIEWS;
    },
    manageButtonTooltip(index) {
      if (!this.isWidgetTypeManagingReviews(index)) {
        return;
      }
      return this.$t('tooltip.manage_button');
    },
    widgetInsertedUrl(index) {
      return this.addParamsToUrlString(
        this.selectedWidget(index).inserted_url,
        {
          'crema-scroll-to-widget-id': this.selectedWidget(index).id
        }
      );
    },
    addParamsToUrlString(urlString, params) {
      if (!urlString) return;

      const url = new URL(urlString);
      Object.keys(params).forEach(key => {
        url.searchParams.set(key, params[key]);
      });
      return url.href;
    },
    widgetViewStatus(widget) {
      if (widget.inserted_url) {
        return this.$t('widget_view_status.viewed');
      } else {
        return this.$t('widget_view_status.not_viewed');
      }
    },
    widgetImplementationMethod(widget) {
      if (widget.use_vuejs) {
        return this.$t('widget_implementation_method.vuejs');
      } else {
        return this.$t('widget_implementation_method.latte_rails');
      }
    },
    smartInstallLabel(rowIndex) {
      let label = this.$t('table_body.smart_install');

      const widget = this.selectedWidget(rowIndex);
      const installCount = widget.smart_installations.length;
      if (installCount > 0) label += `(${installCount})`;

      return label;
    },
    overrideWidgetSettingClicked(index) {
      if (!confirm(this.$t('review_widgets.override_widget_setting_confirm')))
        return;
      const widget = this.selectedWidget(index);
      this.openDialog(['ReviewSettingsSettingOverrideDialog', { widget }]);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .widgets-table__id {
    flex: 0 0 60px;
  }

  .widgets-table__widget-display-status {
    flex: 0 0 88px;
  }

  .widgets-table__widget-implementation-method {
    flex: 0 0 80px;
  }

  .widgets-table__widget-device-type {
    flex: 0 0 104px;
  }

  .widgets-table__widget-style {
    flex: 0 0 100px;
  }

  .widgets-table__name {
    flex: 1 0 0;
  }

  .widgets-table__summary {
    flex: 2 0 0;
  }

  .widgets-table__actions {
    flex: 0 0 260px;
  }
  .AppExternalLinkIcon {
    margin-left: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "head": {
    "new_widget": "{widget_type} 추가"
  },
  "table_head": {
    "id": "위젯 ID",
    "widget_device_type": "디바이스",
    "widget_view_status": "사용 상태",
    "widget_display_status": "노출 상태",
    "widget_implementation_method": "구현 방식",
    "widget_install_method": "설치 방식"
  },
  "table_body": {
    "copy_script": "스크립트 복사",
    "smart_install": "간편 설치"
  },
  "widget_view_status": {
    "not_viewed": "사용 안함",
    "viewed": "사용 중"
  },
  "widget_implementation_method": {
    "latte_rails": "latte-rails",
    "vuejs": "Vue.js"
  },
  "tooltip": {
    "manage_button": "이 위젯에서는 지원하지 않는 기능입니다.",
    "widget_view_status": "최근 7일간 쇼핑몰 고객의 위젯 확인 데이터를 바탕으로 사용 상태를 표시합니다.",
    "widget_view_status_info": "쇼핑몰에 위젯이 설치되어 있어도 쇼핑몰 고객이 위젯에 접속하지 않을 경우 사용 안함 상태로 보일 수 있습니다.",
    "widget_display_status": "위젯의 노출 여부 설정에 따라 표기됩니다.\n(위젯 설정 > 위젯 노출여부)"
  },
  "widget_display_status": {
    "displayed": "노출",
    "hidden": "숨김"
  }
}
</i18n>
