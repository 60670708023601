<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps, objectId: 'channel_units' }"
    width="middle"
    v-on="formEvents"
  >
    <template #group="{ id, errors }">
      <template v-if="id === 'channel_filter_type'">
        <AppSelectRadio
          v-model="formObject.filterType"
          class="ChannelFeedProductsUnitsDialog__filter-type-radio"
          name="channel_filter_type"
          :options="channelUnitFilterTypeOptions"
        />
      </template>
      <template v-if="id === 'channel_filter_units'">
        <ChannelFeedFilterUnitsFormGroup
          v-model="formObject.units"
          v-bind="{ productOptionTypes, errors }"
          name="channel_units"
          @validate-unit-field="validateField($event)"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<style lang="scss" scoped>
.ChannelFeedProductsUnitsDialog__delete {
  float: right;
}
</style>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import ChannelProductFilterType from '@/enums/ChannelProductFilterType';
import ChannelUnitFilterType from '@/enums/ChannelUnitFilterType';

export default {
  name: 'ChannelFeedProductsUnitsDialog',
  mixins: [DialogFormView],
  props: { feedId: { type: Number, default: null } },
  data() {
    return {
      isLoading: true,
      orgFormObject: { units: [], filterType: ChannelUnitFilterType.EXCLUDE },
      productOptionTypes: []
    };
  },
  computed: {
    channelUnitFilterTypeOptions() {
      return ChannelUnitFilterType.options().map(option => ({
        ...option,
        label: this.$t(
          `form.channel_filter_type.${ChannelUnitFilterType.key(option.value)}`
        )
      }));
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'channel_filter_type',
              label: this.$t('form.channel_filter_type.label')
            },
            {
              id: 'channel_filter_units',
              label: this.$t('form.channel_filter_units.label'),
              groupDescription: this.$t(
                'form.channel_filter_units.description'
              ),
              fields: this.unitFields
            }
          ]
        }
      ];
    },
    ChannelUnitFilterType: () => ChannelUnitFilterType,
    unitFields() {
      return _.flatten(
        (this.formObject.units || []).map((unit, index) => [
          {
            id: `${index}[filter_compare_value]`,
            value: unit.filter_compare_value,
            validate: [
              'required',
              {
                rule: () => this.isFilterCompareValueValid(unit),
                errorMessage: this.filterCompareValueErrorMessage(unit)
              }
            ]
          },
          {
            id: `${index}[date_compare_value]`,
            value: unit.date_compare_value,
            validate: [
              'required',
              { rule: 'min_max_integer', min: 0, max: 999 }
            ]
          },
          {
            id: `${index}[product_option_type_id]`,
            validate: [
              {
                rule: () => this.isProductOptionTypeIdValid(unit),
                errorMessage: this.$t('validations.required')
              }
            ]
          },
          {
            id: `${index}[product_option_value_id]`,
            validate: [
              {
                rule: () => this.isProductOptionValueValid(unit),
                errorMessage: this.$t('validations.required')
              }
            ]
          }
        ])
      );
    }
  },
  mounted() {
    api
      .get(`/channel/feeds/${this.feedId || 0}/filter_units`)
      .then(({ data }) => {
        this.orgFormObject = {
          filterType: data.filter_type,
          units: data.units
            .map((unit, groupIndex) => [
              { ...unit, group_index: groupIndex },
              ...(unit.child_units || []).map(childUnit => ({
                ...childUnit,
                group_index: groupIndex
              }))
            ])
            .flat()
        };
        this.productOptionTypes = data.product_option_types;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/channel/feeds/${this.feedId || 0}/filter_units`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    filterCompareValueErrorMessage(unit) {
      if (
        ChannelProductFilterType.isCompareDecimalValueFilterType(
          unit.filter_type
        )
      ) {
        return this.$t('validations.decimal_filter_value');
      }
      if (
        ChannelProductFilterType.HIGHEST_PURCHASED_PRICE === unit.filter_type
      ) {
        return this.$t('validations.highest_purchased_price');
      }
      return this.$t('validations.filter_value');
    },
    isFilterCompareValueValid(unit) {
      if (
        ChannelProductFilterType.isCompareDecimalValueFilterType(
          unit.filter_type
        )
      ) {
        const decimal_place_count =
          unit.filter_compare_value.toString().split('.')[1]?.length || 0;
        return (
          0 <= unit.filter_compare_value &&
          unit.filter_compare_value <= 100 &&
          decimal_place_count < 2
        );
      }
      if (
        ChannelProductFilterType.HIGHEST_PURCHASED_PRICE === unit.filter_type
      ) {
        return (
          0 <= unit.filter_compare_value &&
          unit.filter_compare_value <= 999999999999
        );
      }
      return 0 <= unit.filter_compare_value && unit.filter_compare_value <= 999;
    },
    isProductOptionTypeIdValid(unit) {
      return (
        !ChannelProductFilterType.isProductOptionFilterType(unit.filter_type) ||
        unit.product_option_type_id
      );
    },
    isProductOptionValueValid(unit) {
      return (
        !ChannelProductFilterType.isProductOptionFilterType(unit.filter_type) ||
        unit.product_option_value
      );
    }
  }
};
</script>

<style scoped>
.ChannelFeedProductsUnitsDialog__filter-type-radio {
  margin-top: 2px;
}
</style>

<i18n locale="ko">
{
  "title": "광고 피드 규칙 설정",
  "form": {
    "channel_filter_type": {
      "label": "광고 피드 규칙 동작",
      "INCLUDE": "선정된 상품만 피드에 불러옵니다.",
      "EXCLUDE": "선정된 상품을 피드에서 제외합니다."
    },
    "channel_filter_units": {
      "label": "광고 피드 상품 선정 조건",
      "description": "다수의 상위 조건 추가 시 “또는” 관계를 가집니다. 하나의 상품이 여러 선정 조건에 해당하는 경우 한번만 선정됩니다."
    }
  },
  "validations": {
    "filter_value": "0과 999사이 정수만 입력 가능합니다.",
    "highest_purchased_price": "0과 999,999,999,999사이 정수만 입력 가능합니다.",
    "decimal_filter_value": "0과 100사이 소수점 1번째 자리까지만 입력 가능합니다."
  }
}
</i18n>
