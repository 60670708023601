<template>
  <form
    :class="[
      'AppForm',
      formStyle ? `AppForm--${formStyle}` : null,
      sectionStyle ? `AppForm--section-style-${sectionStyle}` : null
    ]"
    @submit.prevent="submit"
  >
    <slot />
    <slot name="head" />
    <div v-if="currentSections.length" class="AppForm__sections">
      <fieldset
        v-for="section in currentSections"
        :id="section.id"
        :key="section.id"
        :class="{
          AppForm__section: !!section.id,
          'super-admin__item': section.superAdmin
        }"
      >
        <legend v-if="section.label" class="AppForm__section-title">
          {{ section.label }}
          <AppFormHint
            v-bind="hintProps(section)"
            class="AppForm__section-hint"
          />
        </legend>
        <div
          v-for="(group, index) in section.groups"
          :id="group.groupElementId"
          :key="`${group.id}-${index}`"
          :class="['AppForm__group', { 'super-admin__item': group.superAdmin }]"
        >
          <div
            v-if="group.label"
            :class="[
              'AppForm__group-title',
              group.labelDisabled ? 'AppForm__group-title--disabled' : ''
            ]"
          >
            <AppBubble v-if="group.bubble" :side="group.bubbleSide">
              {{ group.bubbleMessage }}
            </AppBubble>
            <div v-if="group.type === 'switch'">
              <label class="AppForm__group-title-switch">
                <AppSwitch
                  :id="group.inputId"
                  :checked="group.value"
                  :name="group.inputName"
                  @change="changeGroup(group, $event)"
                />
                {{ group.label }}
              </label>
            </div>
            <AppCheckbox
              v-else-if="group.type === 'checkbox'"
              :id="group.inputId"
              :name="group.inputName"
              :checked="group.value"
              :label="group.label"
              :disabled="disabled || group.disabled"
              :invalid="!!getFieldError(group.id)"
              class="AppForm__group-title-checkbox"
              @change="changeGroup(group, $event)"
            />
            <div
              v-else-if="group.type === 'advanced_group_title'"
              class="AppForm__advanced-group-title"
            >
              {{ group.label }}
            </div>
            <label
              v-else-if="group.type !== 'agreement_checkbox'"
              :for="group.inputId"
              v-text="group.label"
            />
            <span
              v-if="isRequired(group)"
              class="AppForm__group-title-required"
            >
              *</span
            >
            <span v-if="group.titleBadge" class="AppForm__group-title-badge">
              <AppBadge
                :label="group.titleBadge.label"
                :badge-style="group.titleBadge.badgeStyle"
                :badge-size="group.titleBadge.badgeSize"
              />
            </span>
            <span
              v-if="group.groupTooltip"
              v-tooltip="group.groupTooltip"
              class="AppForm__group-tooltip"
            >
              <AppSvgIcon name="icon-info-tooltip" />
            </span>
          </div>
          <AppTipBox
            v-if="group.tipMessage"
            class="AppForm__group-tip-box"
            :text="group.tipMessage"
            :margin-bottom="0"
          />
          <AppFormGroupDescription
            v-if="group.groupDescription"
            class="AppForm__group-description"
            :disabled="!!group.disabled"
            :message="
              typeof group.groupDescription === 'object'
                ? group.groupDescription.message
                : group.groupDescription
            "
            :detail-button-click-handler="
              group.groupDescriptionDetailClickHandler
            "
            :mode="
              typeof group.groupDescription === 'object'
                ? group.groupDescription.mode
                : 'html'
            "
          />
          <AppFormGroupDescriptionBox
            v-if="group.groupDescriptionBox"
            class="AppForm__group-description-box"
            :message="group.groupDescriptionBox"
          />
          <slot
            name="group"
            v-bind="{
              ...group,
              disabled: disabled || group.disabled,
              error: getFieldError(group.id),
              errors: validationErrors
            }"
          >
            <div
              v-if="group.type !== 'checkbox'"
              :class="[
                'AppForm__group-field',
                group.fieldStyle
                  ? `AppForm__group-field--${group.fieldStyle}`
                  : null
              ]"
            >
              <AppFormField
                v-focus="focusGroupId === group.id"
                :group="group"
                :disabled="disabled"
                :invalid="!!getFieldError(group.id)"
                :input-size="inputSize"
                :errors="validationErrors"
                @blur="blurGroup(group.id)"
                @change="changeGroup(group, $event)"
                @validate="validateOrUnset"
              >
                <template v-if="$scopedSlots['group-label']" #label="props">
                  <slot name="group-label" v-bind="{ ...props, disabled }" />
                </template>
                <template
                  v-if="$scopedSlots['group-sub-item']"
                  #sub-item="props"
                >
                  <slot name="group-sub-item" v-bind="{ ...props, disabled }" />
                </template>
              </AppFormField>
            </div>
          </slot>
          <slot name="description" v-bind="group">
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="group.description"
              class="AppForm__description"
              v-html="group.description"
            />
            <!-- eslint-enable vue/no-v-html -->
          </slot>
          <AppFormHint v-bind="hintProps(group)" :disabled="group.disabled" />
          <AppFormError :error="getFieldError(group.id)" />
          <slot :id="group.id" name="group-foot" />
        </div>
        <AppAdvancedSettingsButton
          v-if="section.advancedGroups && section.advancedGroups.length"
          :is-showing-advanced-setting="advancedGroupVisibleMap[section.id]"
          class="AppForm__advanced-settings-button"
          @change="
            SET_ADVANCED_GROUP_VISIBLE({
              sectionId: section.id,
              visible: $event
            })
          "
        />
      </fieldset>
    </div>
    <AppAdvancedSettingsButton
      v-if="advancedSection"
      v-tooltip="isAdvancedSectionVisible ? '' : advancedSection.tooltip"
      :is-showing-advanced-setting="isAdvancedSectionVisible"
      class="AppForm__advanced-settings-button"
      @change="SET_IS_ADVANCED_SECTION_VISIBLE"
    />
    <div v-if="submitButton" class="AppForm__form-controls">
      <AppButtonSubmit v-bind="submitButtonProps" />
    </div>
    <!-- Implicit Submission -->
    <!-- https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#implicit-submission -->
    <input v-else v-show="false" type="submit" />
    <AppLoadingScreen v-if="!noScreen" :is-loading="isSubmitting" />
  </form>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapMutations } from 'vuex';
import focusChild from '@/lib/focusChild';
import FormValidator from '@/mixins/FormValidator';
import Scrollable from '@/mixins/Scrollable';

export default {
  name: 'AppForm',
  mixins: [FormValidator, Scrollable],
  model: {
    prop: 'object',
    event: 'change'
  },
  props: {
    id: { type: String, default: null },
    sections: { type: Array, required: true },
    objectId: { type: String, default: null },
    object: { type: Object, default: null },
    formStyle: { type: String, default: null },
    submitButton: { type: [Boolean, Object], default: false },
    eventBus: { type: Object, default: null },
    isSubmitting: { type: Boolean, default: false },
    isFormDataChanged: { type: Boolean, default: false },
    detectFormDataChange: { type: Boolean, default: false },
    focusGroupId: { type: String, default: null },
    groupLabelNamespace: {
      type: String,
      default() {
        return `settings.${this.id}.groups`;
      }
    },
    disabled: { type: Boolean, default: false },
    noScreen: { type: Boolean, default: false },
    validateOnlyOnSubmit: { type: Boolean, default: false },
    sectionStyle: { type: String, default: '' }
  },
  computed: {
    ...mapState('form', [
      'isAdvancedSectionVisible',
      'advancedGroupVisibleMap'
    ]),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', ['isCurrentUserInstallationAgency']),
    advancedSection() {
      return this.sections.find(({ id }) => id === 'advanced');
    },
    visibleSections() {
      return this.advancedSection && !this.isAdvancedSectionVisible
        ? this.sections.filter(s => s !== this.advancedSection)
        : this.sections;
    },
    currentSections() {
      return this.visibleSections.map(section => {
        const advancedGroupVisible = this.advancedGroupVisibleMap[section.id];
        const advancedGroups = advancedGroupVisible
          ? [
              {
                type: 'advanced_group_title',
                label: this.$t('app.advanced_settings')
              },
              ...section.advancedGroups
            ]
          : [];

        const groups = section.groups.concat(advancedGroups);

        return {
          ...section,
          groups: groups
            .filter(g => this.isVisibleGroup(g))
            .map(group => {
              let inputName =
                'name' in group ? group.name : this.inputName(group.id);
              if (
                !group.packingMethod &&
                (group.type === 'tags' ||
                  group.type === 'multiple_select' ||
                  group.type === 'multiple_select_box' ||
                  group.type === 'multiple_select_checkbox' ||
                  group.type === 'multiple_select_product_category' ||
                  group.multiple)
              )
                inputName = `${inputName}[]`;

              if (group.type == 'multiple_select_box')
                group.fieldStyle = 'mt12';

              const result = {
                ...group,
                inputId: `${this.id}_${group.id}`,
                invalid: !!group.invalid,
                inputName
              };

              if (group.type === 'hash_select_checkbox')
                result.options = group.options.map(option => ({
                  ...option,
                  name: this.inputName(option.id)
                }));

              if (!('value' in result)) {
                if (group.type === 'new_password_requirements')
                  result.value = this.object.password
                    ? this.object.password.password
                    : '';
                else if (group.type === 'hash_select_checkbox') {
                  const optionIds = group.options.map(o => o.id);
                  result.value = _.pick(this.object, optionIds);
                } else if (group.type === 'hash_select_button') {
                  const ids = group.selectButtons.map(o => o.id);
                  result.value = _.pick(this.object, ids);
                } else result.value = this.objectValue(group.id);
              }

              if (result.label === undefined) {
                result.label = this.$t(
                  `${this.groupLabelNamespace}.${group.id}`
                );
              }

              result.groupElementId = `${section.id ? `${section.id}--` : ''}${
                group.id
              }`;

              if (group.invalid) {
                this.setFieldError(group.id, {
                  errorMessage: group.invalid === true ? '' : group.invalid
                });
              }

              if (result.i18n && !('input' in result)) result.inline = true;

              result.eventHandlers = result.eventHandlers || {};
              return result;
            })
        };
      });
    },
    inputSize() {
      return this.formStyle === 'wide' ? 'large' : null;
    },
    submitButtonProps() {
      return {
        disabled: this.detectFormDataChange && !this.isFormDataChanged,
        isSubmitting: this.isSubmitting,
        errorSummary: this.validateOnlyOnSubmit ? null : this.errorSummary,
        size: this.inputSize,
        ...(typeof this.submitButton === 'object' ? this.submitButton : {})
      };
    }
  },
  mounted() {
    this.$emit('mounted');
    if (this.eventBus) {
      this.eventBus.$on('submit', () => this.submit());
      this.eventBus.$on('validate-field', this.validateField);
      this.eventBus.$on('validate-all', this.validateAll);
      this.eventBus.$on('unset-field-error', id => {
        this.unsetFieldError(id);
      });
      this.eventBus.$on('set-field-error', (id, error) => {
        this.setFieldError(id, error);
      });
      this.eventBus.$on('scroll-to-group', id => {
        const group = this.findGroupById(id);
        if (group) this.scrollToGroup(group);
      });
    }
  },
  created() {
    this.sections.forEach(section => {
      this.SET_ADVANCED_GROUP_VISIBLE({
        sectionId: section.id,
        visible: false
      });
    });
  },
  methods: {
    ...mapMutations('form', [
      'SET_IS_ADVANCED_SECTION_VISIBLE',
      'SET_ADVANCED_GROUP_VISIBLE'
    ]),
    hintProps({ hint }) {
      return _.isObject(hint)
        ? { message: hint.message, priority: hint.priority }
        : { message: hint, priority: null };
    },
    validateAll() {
      this.clearErrors();

      let firstInvalidGroup = null;
      this.currentSections.forEach(section => {
        section.groups.forEach(group => {
          this.doValidateField(group.id, true);
          if (!!this.getFieldError(group.id) && !firstInvalidGroup)
            firstInvalidGroup = group;

          if (group.fields) {
            group.fields.forEach(field => {
              this.doValidateField(field.id, true);
              if (!!this.getFieldError(field.id) && !firstInvalidGroup)
                firstInvalidGroup = group;
            });
          }
        });
      });

      return firstInvalidGroup;
    },
    submit() {
      const firstInvalidGroup = this.validateAll();

      if (!this.hasError && !this.isSubmitting) {
        const formData = new FormData(this.$el);
        this.$emit('submit', formData);
      } else this.scrollToGroup(firstInvalidGroup);
    },
    findGroupById(id) {
      let group = null;
      this.currentSections.some(section => {
        group = section.groups.find(g => g.id === id);
        return !!group;
      });
      return group;
    },
    scrollToGroup(group) {
      this.scrollToElement(document.getElementById(group.groupElementId));
    },
    isVisibleGroup(group) {
      if (this.isSuperAdminAccessible && group.superAdmin) return true;
      if (this.isCurrentUserInstallationAgency && group.installationAgency)
        return true;
      return (
        !!group.superAdmin === false && !!group.installationAgency === false
      );
    },
    isRequired(group) {
      if ('required' in group) return group.required;

      return group.validate?.some(arg => this.validateRule(arg) === 'required');
    },
    validateRule(arg) {
      return typeof arg === 'string' ? arg : arg.rule;
    },
    inputName(id) {
      return this.objectId ? `${this.objectId}[${id}]` : id;
    },
    objectValue(id) {
      return this.object ? _.get(this.object, id) : null;
    },
    changeGroup(group, value) {
      if ((group.type === 'checkbox' || group.type === 'switch') && value)
        focusChild(document.getElementById(group.groupElementId));

      const newObject = { ...this.object };
      switch (group.type) {
        case 'image':
          newObject[group.id] = value.imageUrl;
          newObject[`remove_${group.id}`] = value.removeImage || undefined;
          break;
        case 'hash_select_checkbox':
        case 'hash_select_button':
          Object.assign(newObject, value);
          break;
        default:
          _.set(newObject, group.id, value);
          break;
      }

      this.$emit('change', _.omitBy(newObject, _.isUndefined));
      this.$emit('change-group', { ...group, value });
      this.validateOrUnset(group.id);
    },
    blurGroup(id) {
      this.validateOrUnset(id);
      this.$emit('blur-group', id);
    },
    validateOrUnset(id) {
      if (this.validateOnlyOnSubmit) this.unsetFieldError(id);
      else this.validateField(id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_inputs.scss';
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_breakpoints.scss';

.AppForm {
  @include text-content;
  @include clearfix;
  position: relative;
  text-align: left;
}

.AppForm__section {
  position: relative;
  padding: 32px 0;

  @include media-breakpoint-each(desktop) {
    .AppForm--hor & {
      padding-left: 200px;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & + & {
    border-top: 1px solid $color-layout-section;
    padding-top: 33px;
  }

  .AppForm--section-style-narrow & + & {
    border-top: 0;
    padding-top: 0;
  }
}

.AppForm__group-title-advanced {
  @include text-sub-title;
  border-top: 1px solid $color-layout-section;
  margin-top: 32px;
  padding-top: 32px;
}

.AppForm__advanced-group-title {
  @include text-sub-title;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid $color-layout-section;
}

.AppForm__section-title {
  display: block;
  @include text-sub-title;

  .AppForm--section-style-narrow & {
    & + .AppForm__group {
      padding-top: 12px;
    }
  }

  @include media-breakpoint-each(desktop) {
    .AppForm--hor & {
      @include text-label;
      position: absolute;
      width: 200px;
      left: 0;
    }
  }
}

legend.AppForm__section-title {
  float: left;
  + * {
    clear: both;
  }
}

.AppForm__section-hint {
  margin-bottom: 8px;
}

.AppForm__group {
  @include text-content;

  & + & {
    margin-top: 32px;

    .AppForm--narrow & {
      margin-top: 20px;
    }

    .AppForm--wide & {
      margin-top: 16px;
    }
  }
}

.AppForm__group-title {
  @include text-label;

  &--disabled {
    color: $color-disable-text;
  }
}

.AppForm__group-title-switch {
  display: flex;
  align-items: center;
  gap: 4px;
}

.AppForm__group-title-checkbox {
  transform: translateY(-1px);
}

.AppForm__group-title-required {
  color: $color-red;
}

.AppForm__group-title-badge {
  margin-left: 4px;
}

.AppForm__group-tip-box {
  margin-top: 4px;
}

.AppForm__group-description {
  margin-top: 4px;
}

.AppForm__group-description-box {
  margin-top: 4px;
}

.AppForm__group-tooltip {
  position: relative;
  top: 1px;
  color: $color-grey-50;
  margin-left: 5px;
}

.AppForm__advanced-settings-button {
  margin-top: 32px;
}

.AppForm__form-controls {
  padding-top: 32px;

  .AppForm--narrow & {
    padding-top: 24px;
  }

  .AppForm--wide & {
    padding-top: 24px;
  }
}
</style>

<style lang="scss">
@import '@/scss/mixins/_texts.scss';

.AppForm__group-field {
  margin-top: 4px;

  &--right {
    text-align: right;
  }

  &--mt0 {
    margin-top: 0;
  }

  &--mt8 {
    margin-top: 8px;
  }

  &--mt12 {
    margin-top: 12px;
  }

  &--disabled {
    color: $color-disable-text;
  }
}

.AppForm__group-field--mt16 {
  margin-top: 16px;
}

.AppForm__description {
  margin-top: 4px;

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
}

.AppForm__description-link {
  color: $color-grey-60;
  &:hover {
    text-decoration: underline;
  }
}

.AppForm__sub-group {
  & + & {
    margin-top: 8px;
  }
}

.AppForm__sub-group-hint {
  @include text-caption;
  color: inherit;
  opacity: 0.7;
  margin-top: 4px;
}

.AppForm__sub-group-item {
  margin-top: 8px;
}

.AppForm__sub-group-title {
  display: block;
  padding-bottom: 4px;

  &--disabled {
    color: $color-disable-text;
  }
}
</style>
