<template>
  <AppContainer class="ReviewBulkReviewCsvs">
    <AppForm
      v-bind="formProps"
      object-id="review_csv"
      :submit-button="{
        submitLabel: $t('app.upload'),
        submittingLabel: $t('app.uploading')
      }"
      v-on="formEvents"
      @change-group="formGroupChanged"
    />
  </AppContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import BrandCsvType from '@/enums/BrandCsvType';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewBulkReviewCsvs',
  mixins: [FormView],
  data() {
    return {
      showDownloadCsvForm: false
    };
  },
  computed: {
    ...mapGetters('session', ['isShopifyBrand']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'brand_csv_type',
              type: 'select',
              label: this.$t('csv_type_input_title'),
              placeholder: this.$t('csv_type_placeholder'),
              options: this.isShopifyBrand
                ? BrandCsvType.options([
                    BrandCsvType.BULK_SHOPIFY_REVIEW,
                    BrandCsvType.BULK_JUDGE_ME_REVIEW,
                    BrandCsvType.BULK_REVIEW
                  ])
                : BrandCsvType.options([
                    BrandCsvType.BULK_V_REVIEW,
                    BrandCsvType.BULK_ALPHA_REVIEW,
                    BrandCsvType.BULK_SNAP_REVIEW,
                    BrandCsvType.BULK_REVIEW
                  ]),
              validate: ['required']
            },
            this.showDownloadCsvForm
              ? {
                  id: 'download_csv_template',
                  type: 'button_download',
                  label: this.$t('csv_form'),
                  groupDescription: this.$t('csv_download_button_description'),
                  value: this.$t('csv_download_button_label'),
                  url: '/csv/review_csv_template.csv',
                  filename: 'review_csv_template.csv'
                }
              : null
          ].filter(v => v)
        }
      ];
    }
  },
  methods: {
    submit() {
      alert('test');
    },
    formGroupChanged(group) {
      if (group.id === 'brand_csv_type') {
        this.showDownloadCsvForm = group.value === BrandCsvType.BULK_REVIEW;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "csv_type_input_title": "CSV 파일 종류",
  "csv_type_placeholder": "리뷰 앱 선택",
  "csv_form": "CSV 파일 양식",
  "csv_download_button_description": "하단 버튼을 클릭해 파일을 다운로드하고, 양식에 맞게 파일을 수정하여 업로드해주세요. 양식이 다를 경우 문제가 발생할 수 있습니다.",
  "csv_download_button_label": "예시 CSV 파일 다운로드"
}
</i18n>
