<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppStatCard
      v-tooltip="
        stats.campaign_start_date
          ? `${stats.campaign_start_date} ~ ${stats.end_date}`
          : null
      "
      class="ReviewMessagesCampaign__stat-card"
      :title="$t('stats.review_with_message_rate')"
      v-bind="reviewWithMessageStat"
    />
    <AppStatCard
      v-if="isSuperAdminAccessible"
      class="ReviewMessagesCampaign__stat-card super-admin__item"
      :title="$t('stats.review_without_message_rate')"
      v-bind="reviewWithoutMessageStat"
    />
    <div class="ReviewMessagesCampaign__stat-hint">
      {{
        $t('date_range', [
          formatDate(stats.start_date),
          formatDate(stats.end_date)
        ])
      }}
    </div>
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'ReviewMessagesCampaigns',
  data: () => ({ isLoading: true, stats: {}, usingReviewCampaign: false }),
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    reviewWithMessageStat() {
      if (!this.usingReviewCampaign)
        return { detail: this.$t('stats.not_using_review_campaign') };
      const {
        campaign_start_date,
        reviews_with_message_count,
        orders_with_message_count
      } = this.stats;
      if (!campaign_start_date) return { detail: this.$t('stats.empty') };

      return {
        stat: this.stat(reviews_with_message_count, orders_with_message_count),
        detail: this.detail(
          reviews_with_message_count,
          orders_with_message_count
        )
      };
    },
    reviewWithoutMessageStat() {
      const {
        reviews_without_message_count,
        orders_without_message_count
      } = this.stats;
      return {
        stat: this.stat(
          reviews_without_message_count,
          orders_without_message_count
        ),
        detail: this.detail(
          reviews_without_message_count,
          orders_without_message_count
        )
      };
    }
  },
  mounted() {
    api
      .get('/review/campaigns')
      .then(({ data }) => {
        this.usingReviewCampaign = data.using_review_campaign;
        this.stats = data.stats;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    stat(reviewsCount, ordersCount) {
      return ordersCount ? reviewsCount / ordersCount : 0;
    },
    detail(reviewsCount, ordersCount) {
      if (!reviewsCount || !ordersCount) return '';
      return `${reviewsCount.toLocaleString()}/${ordersCount.toLocaleString()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesCampaign__stat-card {
  width: 260px;
  display: inline-block;

  & + & {
    margin-left: 8px;
    margin-top: 0;
  }
}

.ReviewMessagesCampaign__stat-hint {
  @include text-caption;
  margin-top: 4px !important;
}
</style>

<i18n locale="ko">
{
  "stats": {
    "review_with_message_rate": "리뷰 작성률 (리뷰 작성유도 대상 주문)",
    "review_without_message_rate": "리뷰 작성률 (리뷰 작성유도 대상 외 주문)",
    "not_using_review_campaign": "리뷰 작성유도 캠페인 미사용 중",
    "empty": "효과 수집 중입니다."
  },
  "date_range": "*. 효과 수집을 완료한 {0} ~ {1} 사이의 값입니다."
}
</i18n>
