<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm
      v-if="data"
      v-model="fit_settings"
      v-bind="fitFormProps"
      :event-bus="formEventBus"
      @submit="submit"
      @mounted="formMounted"
    >
      <template #group="{ id, error, errors }">
        <template v-if="id === 'category_configs'">
          <div class="AppForm__group-field AppForm__group-field--mt8">
            <div class="table-head-control">
              <AppButton
                :label="$t('fit_settings.category_configs.new_category_config')"
                @click="addCategoryConfig"
              />
            </div>
            <AppSmallDeviceScrollable>
              <AppTable
                :columns="CATEGORY_CONFIG_HEADERS"
                :rows="
                  categoryConfigs.map(config => ({
                    ...config,
                    primary_user_property_type: config.fit_product_type_id
                      ? fitProductTypesMap[config.fit_product_type_id]
                          .primary_user_property_type.name
                      : null,
                    secondary_user_property_type: config.fit_product_type_id
                      ? fitProductTypesMap[config.fit_product_type_id]
                          .secondary_user_property_type.name
                      : null
                  }))
                "
              >
                <template #cell="{ row, column, rowIndex }">
                  <template v-if="column.id === 'product_category'">
                    <div v-if="row.default">
                      {{ $t('fit_settings.category_configs.default_category') }}
                      <input
                        type="hidden"
                        name="fit_settings[category_config_data][][default]"
                        value="1"
                      />
                      <input
                        type="hidden"
                        name="fit_settings[category_config_data][][product_category_id]"
                      />
                    </div>
                    <div v-else>
                      <input
                        type="hidden"
                        name="fit_settings[category_config_data][][default]"
                        value="0"
                      />
                      <AppSelectFilterable
                        v-model="categoryConfigs[rowIndex].product_category_id"
                        class="AppTable__select"
                        name="fit_settings[category_config_data][][product_category_id]"
                        :options="productCategoryOptions"
                        :placeholder="
                          $t('fit_settings.category_configs.select_category')
                        "
                        :invalid="
                          !!errors[
                            `category_configs[${row.id}][product_category_id]`
                          ]
                        "
                        @blur="
                          validateField(
                            `category_configs[${row.id}][product_category_id]`
                          )
                        "
                        @change="
                          validateCategoryConfigProductCategoryId(row, errors)
                        "
                      />
                      <AppFormError
                        :error="
                          errors[
                            `category_configs[${row.id}][product_category_id]`
                          ]
                        "
                      />
                    </div>
                  </template>
                  <template v-else-if="column.id === 'fit_product_type'">
                    <AppSelect
                      v-model="categoryConfigs[rowIndex].fit_product_type_id"
                      class="AppTable__select"
                      name="fit_settings[category_config_data][][fit_product_type_id]"
                      :options="fitProductTypesOptions"
                      :invalid="
                        !!errors[
                          `category_configs[${row.id}][fit_product_type_id]`
                        ]
                      "
                      @blur="
                        validateField(
                          `category_configs[${row.id}][fit_product_type_id]`
                        )
                      "
                      @change="
                        validateField(
                          `category_configs[${row.id}][fit_product_type_id]`
                        )
                      "
                    />
                    <AppFormError
                      :error="
                        errors[
                          `category_configs[${row.id}][fit_product_type_id]`
                        ]
                      "
                    />
                  </template>
                  <template
                    v-else-if="column.id === 'additional_user_property_type'"
                  >
                    <AppSelect
                      v-model="
                        categoryConfigs[rowIndex]
                          .additional_user_property_type_id
                      "
                      name="fit_settings[category_config_data][][additional_user_property_type_id]"
                      :options="reviewOptionTypesOptions"
                    />
                  </template>
                  <template v-else-if="column.id === 'actions'">
                    <AppButton
                      v-if="!row.default"
                      button-style="red"
                      :label="$t('app.delete')"
                      @click="deleteCategoryConfig(rowIndex)"
                    />
                  </template>
                </template>
              </AppTable>
            </AppSmallDeviceScrollable>
          </div>
        </template>
        <div
          v-else-if="id === 'review_option_popup_target'"
          class="AppForm__group-field"
        >
          <i18n path="fit_settings.review_options_info.collecting_target_html">
            <AppNumberInput
              slot="max_review_pending_days"
              v-model="fit_settings.max_review_pending_days"
              name="fit_settings[max_review_pending_days]"
              :digits="2"
              :invalid="!!error"
            />
            <AppNumberInput
              slot="min_review_pending_days"
              v-model="fit_settings.min_review_pending_days"
              name="fit_settings[min_review_pending_days]"
              :digits="2"
              :invalid="!!error"
            />
          </i18n>
        </div>
        <div
          v-else-if="id === 'review_option_popup_scroll_condition'"
          class="AppForm__group-field"
        >
          <i18n path="fit_settings.review_options_info.scroll_condition_html">
            <AppRadio
              v-model="fit_settings.review_option_popup_check_direction"
              name="fit_settings[review_option_popup_check_direction]"
              :value="0"
              :label="$t('app.top')"
              input-style="inline"
            />
            <AppRadio
              v-model="fit_settings.review_option_popup_check_direction"
              name="fit_settings[review_option_popup_check_direction]"
              :value="10"
              :label="$t('app.bottom')"
              input-style="inline"
            />
            <AppNumberInput
              v-model="fit_settings.review_option_popup_check_offset_y"
              name="fit_settings[review_option_popup_check_offset_y]"
              :digits="4"
              :invalid="!!error"
            />
          </i18n>
        </div>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import FitSettingsView from './FitSettingsView';
import NineWayDirection from '@/enums/NineWayDirection';

export default {
  name: 'FitSettingsReviewOptionsInfo',
  mixins: [FitSettingsView],
  data() {
    return {
      SETTINGS_ID: 'fit_settings_review_options_info',
      FIT_SETTINGS_URL: '/fit/settings/review_options_info',
      CATEGORY_CONFIG_HEADERS: [
        {
          id: 'product_category',
          label: this.$t('fit_settings.category_configs.product_category')
        },
        {
          id: 'fit_product_type',
          label: this.$t('fit_settings.category_configs.fit_product_type')
        },
        {
          id: 'primary_user_property_type',
          label: this.$t(
            'fit_settings.category_configs.primary_user_property_type'
          )
        },
        {
          id: 'secondary_user_property_type',
          label: this.$t(
            'fit_settings.category_configs.secondary_user_property_type'
          )
        },
        {
          id: 'additional_user_property_type',
          label: this.$t(
            'fit_settings.category_configs.additional_user_property_type'
          )
        },
        { id: 'actions', label: this.$t('app.actions') }
      ]
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapGetters('productCategory', ['productCategoryOptions']),
    groupOptions() {
      return {
        enable_review_option_popup: {
          type: 'checkbox',
          description: this.$t(
            'fit_settings.review_options_info.enable_collecting_review_options_description'
          )
        },
        review_option_popup_excluding_category_ids: {
          type: 'multiple_select',
          options: this.productCategoryOptions
        },
        review_option_popup_lock_interval_days: {
          type: 'number',
          digits: 2,
          i18n: 'fit_settings.review_options_info.lock_interval_days_html',
          validate: ['required']
        },
        review_option_popup_fullscreen: { type: 'checkbox' },
        review_option_popup_direction: {
          type: 'select_radio',
          options: NineWayDirection.options(),
          cols: 3
        },
        review_option_popup_offset_x: {
          type: 'number',
          digits: 3,
          allowNegative: true,
          validate: ['required']
        },
        review_option_popup_offset_y: {
          type: 'number',
          digits: 3,
          allowNegative: true,
          validate: ['required']
        },
        review_option_popup_check_min_pageviews: {
          type: 'number',
          digits: 2,
          i18n: 'fit_settings.review_options_info.min_pageviews_html',
          validate: ['required']
        },
        review_option_types: {
          type: 'button',
          value: this.$t(
            'fit_settings.review_options_info.review_option_types'
          ),
          clickHandler: this.reviewSettings.use_product_category_product_type
            ? this.redirectReviewOptionSettings
            : this.openReviewOptionTypeSettingsDialog
        },
        category_configs: this.reviewSettings.use_product_category_product_type
          ? null
          : {
              fields: _.flatten(
                this.fit_settings.category_configs.map(config =>
                  config.default
                    ? []
                    : [
                        {
                          id: `category_configs[${config.id}][product_category_id]`,
                          value: () => config.product_category_id,
                          validate: [
                            'required',
                            {
                              rule: v =>
                                this.productCategoryIdCountsMap[v] === 1,
                              errorMessage: this.$t(
                                'errors.duplicated_product_category'
                              )
                            }
                          ]
                        },
                        {
                          id: `category_configs[${config.id}][fit_product_type_id]`,
                          value: () => config.fit_product_type_id,
                          validate: ['required']
                        }
                      ]
                )
              )
            },
        review_option_popup_target: {
          validate: [
            {
              rule: 'required',
              value: () => this.fit_settings.min_review_pending_days
            },
            {
              rule: 'required',
              value: () => this.fit_settings.max_review_pending_days
            },
            {
              rule: () =>
                this.fit_settings.max_review_pending_days >=
                this.fit_settings.min_review_pending_days,
              errorMessage: this.$t('validations.max_days_should_gte_min_days')
            }
          ]
        },
        review_option_popup_scroll_condition: {
          validate: [
            {
              rule: 'required',
              value: () => this.fit_settings.review_option_popup_check_offset_y
            }
          ]
        }
      };
    },
    fitProductTypesOptions() {
      return [
        {
          value: null,
          label: this.$t(
            'fit_settings.category_configs.select_fit_product_type'
          )
        },
        ...this.data.fit_product_types.map(item => {
          return { value: item.id, label: item.name };
        })
      ];
    },
    reviewOptionTypesOptions() {
      return [
        { value: null, label: this.$t('app.none') },
        ...this.data.review_option_types_with_user_property.map(item => {
          return { value: item.user_property_type_id, label: item.name };
        })
      ];
    },
    fitProductTypesMap() {
      return _.keyBy(this.data.fit_product_types, t => t.id);
    },
    categoryConfigs() {
      return this.fit_settings.category_configs;
    },
    productCategoryIdCountsMap() {
      return _.countBy(this.categoryConfigs, 'product_category_id');
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('session', ['redirectToNextPage']),
    formMounted() {
      this.$watch('fit_settings.max_review_pending_days', () => {
        this.validateField('review_option_popup_target');
      });

      this.$watch('fit_settings.min_review_pending_days', () => {
        this.validateField('review_option_popup_target');
      });

      this.$watch('fit_settings.review_option_popup_check_offset_y', () => {
        this.validateField('review_option_popup_scroll_condition');
      });
    },
    addCategoryConfig() {
      this.categoryConfigs.push({
        id: _.uniqueId('category_config'),
        default: false,
        product_category_id: null,
        fit_product_type_id: null,
        additional_user_property_type_id: null
      });
    },
    openReviewOptionTypeSettingsDialog() {
      this.openDialog('ReviewSettingsOptionTypeDialog').then(eventBus => {
        eventBus.$on('change', reviewOptionTypes => {
          this.data.review_option_types_with_user_property = _.sortBy(
            reviewOptionTypes
              .filter(t => t.user_property_type_id)
              .map(t => _.pick(t, 'user_property_type_id', 'name')),
            t => t.name
          );
        });
      });
    },
    redirectReviewOptionSettings() {
      this.redirectToNextPage(
        '/review/settings/review_option/category_inclusion'
      );
    },
    deleteCategoryConfig(index) {
      if (confirm(this.$t('app.delete_confirm'))) {
        this.$delete(this.categoryConfigs, index);
      }
    },
    validateCategoryConfigProductCategoryId(config, errors) {
      this.validateField(`category_configs[${config.id}][product_category_id]`);
      Object.keys(errors).forEach(id => this.validateField(id));
    },
    setData(data) {
      this.data = {
        ...data,
        fit_settings: {
          ...data.fit_settings,
          category_configs: data.fit_settings.category_configs.map(config => ({
            ...config,
            id: _.uniqueId('category_config')
          }))
        }
      };
    }
  }
};
</script>

<i18n locale="ko">
{
  "errors": {
    "select_product_category": "표시 카테고리를 선택하세요.",
    "select_product_type": "제품 타입을 선택하세요.",
    "duplicated_product_category": "같은 표시 카테고리를 중복해서 선택할 수 없습니다."
  }
}
</i18n>
