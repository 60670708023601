<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps }"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'product_image'">
        <AppImageInput
          id="product-image"
          :image-url="formObject.image.imageUrl"
          :caption="$t('caption')"
          :accept="ACCEPT_IMAGE_FILE_TYPES.join(',')"
          :remove-image-label="$t('remove_image')"
          :placeholder="channelImageName || $t('select_image')"
          preview-height="120px"
          name="product_image"
          @change="changeImage($event)"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import { mapGetters } from 'vuex';

export default {
  name: 'ChannelFeedProductsImageUploadDialog',
  mixins: [DialogFormView],
  props: {
    productId: { type: Number, required: true },
    channelImageUrl: { type: String, default: null },
    channelImageName: { type: String, default: null }
  },
  data() {
    return {
      orgFormObject: {
        image: {
          imageUrl: this.channelImageUrl,
          removeImage: false
        }
      },
      fileType: null
    };
  },
  computed: {
    ...mapGetters('session', ['isChannelMultiFeedEnabled']),
    formSections() {
      return [
        {
          groups: [
            ...(this.isChannelMultiFeedEnabled
              ? [
                  {
                    type: 'infobox',
                    label: null,
                    value: this.$t('infobox')
                  }
                ]
              : []),
            {
              id: 'product_image',
              label: this.$t('label'),
              hint: this.channelImageUrl ? this.$t('hint') : null,
              validate: [
                {
                  rule: this.checkImageFileType,
                  errorMessage: this.$t('invalid_file_type')
                }
              ]
            }
          ]
        }
      ];
    }
  },
  created() {
    this.ACCEPT_IMAGE_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/channel/product/images/${this.productId}`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.close(true);
        })
        .finally(() => {
          this.isSubmitting = false;
          this.eventBus.$emit('submit');
        });
    },
    checkImageFileType() {
      if (this.formObject.image.removeImage || !this.fileType) return true;

      return this.ACCEPT_IMAGE_FILE_TYPES.includes(this.fileType);
    },
    changeImage(event) {
      const { fileType, ...image } = event;
      this.fileType = fileType;
      this.formObject.image = image;
      this.validateField('product_image');
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "상품 이미지 수정",
  "label": "대체 상품 이미지 등록",
  "caption": "쇼핑몰로 부터 동기화 된 기본 이미지와 다른 이미지를 사용하려면 등록해 주세요. (JPG, PNG)",
  "hint": "체크 후 저장 시 쇼핑몰로 부터 동기화 된 기본 이미지가 노출됩니다.",
  "invalid_file_type": "사용할 수 없는 이미지입니다. JPG, PNG 형태의 이미지를 등록해 주세요.",
  "remove_image": "대체 상품 이미지 삭제",
  "select_image": "이미지를 등록해주세요.",
  "infobox": "대체 상품 이미지는 크리마 애드의 모든 광고 피드에서 공통적으로 적용됩니다."
}
</i18n>
