<template>
  <AppModalForm
    :title="$t('title')"
    width="1200px"
    :form-props="{
      ...formProps,
      objectId: 'product_category_product_type'
    }"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #group="group">
      <template
        v-if="group.id === 'product_category_id'"
        class="AppForm__group-field"
      >
        <AppSelectFilterable
          v-model="formObject.product_category_id"
          :name="group.inputName"
          :options="group.options"
          :placeholder="group.placeholder"
          :invalid="!!group.error"
          :disabled="isUnselectedCategories"
          @change="validateField('product_category_id')"
        />
      </template>
      <template
        v-else-if="group.id === 'product_type_id'"
        class="AppForm__group-field"
      >
        <AppSelectFilterable
          v-model="formObject.product_type_id"
          :name="group.inputName"
          :options="group.options"
          :placeholder="group.placeholder"
          :invalid="!!group.error"
          @change="changeProductType"
        />
      </template>
      <template
        v-else-if="group.id === 'brand_evaluation_types'"
        class="AppForm__group-field"
      >
        <ReviewSettingsBrandEvaluationTypes
          v-model="formObject.brand_evaluation_types"
          :evaluation-types="selectableEvaluationTypes"
          :errors="group.errors"
          @validate-field="validateField($event)"
        />
      </template>
      <template
        v-else-if="group.id === 'brand_user_property_types'"
        class="AppForm__group-field"
      >
        <ReviewSettingsBrandUserPropertyTypes
          v-model="formObject.brand_user_property_types"
          :user-property-types="userPropertyTypes"
          :errors="group.errors"
          @validate-field="validateField($event)"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewOptionFieldType from '@/enums/ReviewOptionFieldType';
import ReviewOptionPropertyType from '@/enums/ReviewOptionPropertyType';

export default {
  name: 'ReviewSettingsProductCategoryProductTypeDialog',
  mixins: [DialogFormView],
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      selectableProductCategoryIdSet: new Set(),
      orgFormObject: {
        brand_evaluation_types: [],
        brand_user_property_types: []
      },
      productTypes: [],
      evaluationTypes: [],
      globalReviewOptionTypes: []
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryOptions']),
    isUnselectedCategories() {
      return this.id && !this.orgFormObject.product_category_id;
    },
    productType() {
      const { product_type_id } = this.formObject;
      return product_type_id
        ? this.productTypes.find(t => t.id === product_type_id)
        : null;
    },
    evaluationTypesMap() {
      return _.keyBy(this.evaluationTypes, 'id');
    },
    selectableEvaluationTypes() {
      return this.productType
        ? this.productType.evaluation_types.map(
            t => this.evaluationTypesMap[t.evaluation_type_id]
          )
        : this.evaluationTypes;
    },
    globalReviewOptionTypesMap() {
      return _.keyBy(this.globalReviewOptionTypes, 'id');
    },
    userPropertyTypes() {
      return this.globalReviewOptionTypes
        .filter(e => e.property_type === ReviewOptionPropertyType.CUSTOMER)
        .map(t => ({
          id: t.id,
          field_type: t.field_type,
          unit: t.unit,
          storable: t.storable,
          min: t.min,
          max: t.max,
          name: t.name,
          interval: t.interval,
          display_interval: t.display_interval,
          displayable: t.displayable,
          required: t.required,
          searchable: t.searchable,
          options: t.option_values.map(e => ({
            label: e.display_name || e.name,
            value: e.value
          }))
        }));
    },
    formSections() {
      if (this.isLoading) return [];

      return [
        {
          id: 'product_category_product_type',
          groups: [
            {
              id: 'product_category_id',
              label: this.$t('product_category_id.label'),
              placeholder: this.isUnselectedCategories
                ? this.$t('unselected_categories')
                : this.$t('product_category_id.placeholder'),
              groupDescription: this.$t('product_category_id.description'),
              options: this.productCategoryOptions.filter(({ value }) =>
                this.selectableProductCategoryIdSet.has(value)
              ),
              validate: this.isUnselectedCategories ? null : ['required']
            },
            {
              id: 'product_type_id',
              label: this.$t('product_type_id.label'),
              placeholder: this.$t('product_type_id.placeholder'),
              groupDescription: this.$t('product_type_id.description'),
              options: this.productTypes.map(t => ({
                label: t.name,
                value: t.id
              })),
              validate: ['required']
            }
          ]
        },
        {
          id: 'review_option_types',
          label: this.$t('review_option_types.label'),
          groups: [
            {
              id: 'review_option_types_info',
              label: '',
              type: 'infobox',
              value: this.$t('review_option_types_info')
            },
            ...[
              {
                id: 'brand_user_property_types',
                fields: _.flatten(
                  this.formObject.brand_user_property_types.map(type => [
                    {
                      id: `brand_user_property_types[${type.id}][name]`,
                      value: () => type.name,
                      validate: ['required']
                    },
                    ...(type.field_type === ReviewOptionFieldType.NUMBER
                      ? [
                          {
                            id: `brand_user_property_types[${type.id}][min]`,
                            value: () => type.min,
                            validate: ['required', 'positive_integer']
                          },
                          {
                            id: `brand_user_property_types[${type.id}][max]`,
                            value: () => type.max,
                            validate: [
                              'required',
                              'positive_integer',
                              { rule: 'gt', allowed: type.min }
                            ]
                          },
                          {
                            id: `brand_user_property_types[${type.id}][interval]`,
                            value: () => type.interval,
                            validate: ['required', 'positive_integer']
                          },
                          {
                            id: `brand_user_property_types[${type.id}][display_interval]`,
                            value: () => type.display_interval,
                            validate: ['required', { rule: 'gte', allowed: 0 }]
                          }
                        ]
                      : type.field_type === ReviewOptionFieldType.SELECT
                      ? [
                          {
                            id: `brand_user_property_types[${type.id}][option_values]`,
                            value: () => type.option_values,
                            validate: ['required']
                          }
                        ]
                      : [])
                  ])
                )
              },
              {
                id: 'brand_evaluation_types',
                fields: this.formObject.brand_evaluation_types.map(type => {
                  return {
                    id: `brand_evaluation_types[${type.id}][evaluation_type_id]`,
                    value: () => type.evaluation_type_id,
                    validate: [
                      {
                        rule: 'required',
                        errorMessage: this.$t(
                          'error.select_brand_evaluation_type'
                        )
                      }
                    ]
                  };
                })
              }
            ].map(g => ({
              ...g,
              label: this.$t(`${g.id}.label`),
              groupDescription: this.$t(`${g.id}.description`)
            }))
          ]
        }
      ];
    }
  },
  mounted() {
    const url = this.id
      ? `/product_category_product_types/${this.id}/edit`
      : '/product_category_product_types/new';

    api
      .get(url)
      .then(({ data }) => {
        this.orgFormObject = {
          ...this.orgFormObject,
          ...data.product_category_product_type
        };
        this.selectableProductCategoryIdSet = new Set(
          data.product_category_ids
        );
        this.productTypes = data.product_types;
        this.evaluationTypes = data.evaluation_types.map(t => ({
          ...t,
          options:
            t.field_type === ReviewOptionFieldType.SELECT
              ? _.map(t.options, 'label').join(',')
              : null
        }));
        this.globalReviewOptionTypes = data.global_review_option_types;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    resetBrandEvaluationTypes() {
      const evaluationTypes = this.productType
        ? this.productType.evaluation_types.filter(e => e.default)
        : [];

      const MAX_SHOWABLE_COUNT = 3;
      const showableIds = evaluationTypes
        .filter(
          e =>
            this.globalReviewOptionTypesMap[e.global_review_option_type_id]
              .field_type !== ReviewOptionFieldType.TEXT
        )
        .slice(0, MAX_SHOWABLE_COUNT)
        .map(e => e.evaluation_type_id);

      const brandEvaluationTypes = evaluationTypes.map(e => {
        const optionType = this.globalReviewOptionTypesMap[
          e.global_review_option_type_id
        ];
        return {
          id: _.uniqueId('brand_evaluation_types_id'),
          evaluation_type_id: e.evaluation_type_id,
          global_review_option_type_id: e.global_review_option_type_id,
          showable: showableIds.includes(e.evaluation_type_id),
          searchable: optionType.searchable,
          displayable: optionType.displayable,
          required: optionType.required
        };
      });

      this.$set(
        this.formObject,
        'brand_evaluation_types',
        brandEvaluationTypes
      );
    },
    resetBrandUserPropertyTypes() {
      const brandUserPropertyTypes = this.productType
        ? this.productType.user_property_types.map(e => {
            const optionType = this.globalReviewOptionTypesMap[
              e.global_review_option_type_id
            ];
            return {
              id: _.uniqueId('brand_user_property_type_id'),
              field_type: optionType.field_type,
              unit: optionType.unit,
              storable: optionType.storable,
              min: optionType.min,
              max: optionType.max,
              name: optionType.name,
              interval: optionType.interval,
              display_interval: optionType.display_interval,
              displayable: optionType.displayable,
              required: optionType.required,
              searchable: optionType.searchable,
              option_values: optionType.option_values.map(e => e.value),
              user_property_type_id: e.user_property_type_id,
              global_review_option_type_id: e.global_review_option_type_id,
              selected: e.selected
            };
          })
        : [];

      this.$set(
        this.formObject,
        'brand_user_property_types',
        brandUserPropertyTypes
      );
    },
    changeProductType() {
      this.resetBrandEvaluationTypes();
      this.resetBrandUserPropertyTypes();
      this.validateField('product_type_id');
    },
    submit(formData) {
      this.isSubmitting = true;

      const args = this.id
        ? {
            url: `/product_category_product_types/${this.id}`,
            method: 'patch',
            successMessage: this.$t('app.saved')
          }
        : {
            url: '/product_category_product_types',
            method: 'post',
            successMessage: this.$t('app.created')
          };

      api
        .request({ data: formData, ...args })
        .then(() => {
          this.eventBus.$emit('save');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "카테고리별 추가 정보 설정",
  "review_option_types": {
    "label": "리뷰 작성 시 입력받을 추가 정보"
  },
  "unselected_categories": "선택하지 않은 모든 카테고리",
  "review_option_types_info": "선택한 카테고리 상품의 리뷰를 작성할 때 함께 입력 받을 평가 항목과 고객 속성을 선택해 주세요.",
  "product_category_id": {
    "label": "쇼핑몰 상품 카테고리 선택",
    "placeholder": "카테고리 선택",
    "description": "선택한 카테고리의 상품 리뷰 작성 시 이 페이지에서 설정한 추가 정보를 입력 받습니다."
  },
  "product_type_id": {
    "label": "제품 타입 선택",
    "placeholder": "제품 타입 선택",
    "description": "선택한 쇼핑몰 카테고리가 어떤 종류의 제품인지 선택해주세요. 선택한 제품 타입에 따라 리뷰 작성 시 입력받을 추가 정보가 달라집니다."
  },
  "brand_user_property_types": {
    "label": "고객 속성",
    "description": "대표 속성으로 지정하시면 해당 속성의 선택값 별로 '통계 위젯'의 통계를 볼 수 있습니다. 입력 방식이 텍스트인 경우 대표 속성 지정이 불가합니다. 설정하지 않으면, 전체 구매자의 통계가 노출됩니다."
  },
  "brand_evaluation_types": {
    "label": "평가 항목",
    "description": "선택한 평가 항목들을 리뷰 작성 시 입력받게 됩니다. 사이즈 한줄평을 제외한 선착 3개 평가 항목의 통계가 ‘통계 위젯’에서 노출됩니다.<br />크리마 핏 설정과 연결된 항목은 삭제할 수 없습니다."
  },
  "error": {
    "select_brand_evaluation_type" : "선택해 주세요.",
    "min_length_brand_user_property_types": "{0}개 이상의 고객 속성을 설정해주세요."
  }
}
</i18n>
