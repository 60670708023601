<template>
  <AppModalFormLivePreview
    :title="title"
    :sub-title="$t('subtitle')"
    :form-props="{ ...formProps, objectId: 'campaign' }"
    width="530px"
    v-bind="{ isLoading }"
    v-on="formEvents"
    @change-group="changeGroup"
  >
    <template #left>
      <AppAjaxContent :is-loading="isLoadingPreview">
        <BizMessageTemplatePreview :template="template" />
      </AppAjaxContent>
    </template>
    <template #group="{ id, value }">
      <template v-if="id === 'template_id'">
        <template
          v-if="formObject.template_type === BizMessageTemplateType.NORMAL"
        >
          <AppContainer
            class="AppForm__group-field BizMessageCampaignFormDialog__template-description"
          >
            {{ $t('template_description') }}
            <AppButton
              to="https://help-review.cre.ma/hc/ko/articles/9872095799961--%EB%A6%AC%EB%B7%B0-%EB%A6%AC%EB%B7%B0-%EC%9E%91%EC%84%B1-%EC%9C%A0%EB%8F%84-%EB%A9%94%EC%8B%9C%EC%A7%80-%EB%AC%B8%EC%9E%90-%EB%A5%BC-%EC%95%8C%EB%A6%BC%ED%86%A1%EC%9C%BC%EB%A1%9C-%EB%B0%9C%EC%86%A1%ED%95%A0-%EC%88%98-%EC%9E%88%EB%82%98%EC%9A%94"
              type="external_link"
              button-style="underline"
              >{{ $t('template_description_link') }}</AppButton
            >
          </AppContainer>
          <AppButton
            class="AppForm__group-field"
            :label="$t('add_template')"
            @click="clickNewTemplate"
          />
          <BizMessageTemplateSelectTable
            v-model="formObject.template_id"
            class="AppForm__group-field"
            :templates="templates"
            @invalid="isTemplateNotSelectable = $event"
          />
          <input
            type="hidden"
            :value="formObject.template_id"
            name="campaign[template_id]"
          />
        </template>
      </template>
      <template v-else-if="id === 'item_list'">
        <div class="AppForm__group-field">
          <BizMessageTemplateItemList v-model="template.item_list" readonly />
        </div>
      </template>
      <template v-else-if="id === 'content'">
        <div
          class="BizMessageCampaignFormDialog__template-content AppForm__group-field"
        >
          {{ value }}
        </div>
      </template>
      <template v-else-if="id === 'buttons'">
        <div class="AppForm__group-field">
          <BizMessageTemplateButtons :buttons="template.buttons" readonly />
        </div>
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import api from '@/lib/api';
import BizMessageCampaignType from '@/enums/BizMessageCampaignType';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import CremaServiceType from '@/enums/CremaServiceType';
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplateShowView from '@/mixins/BizMessageTemplateShowView';
import BizMessageTemplatePreview from './BizMessageTemplatePreview';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'BizMessageCampaignFormDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView, BizMessageTemplateShowView],
  props: {
    campaignType: { type: Number, required: true }
  },
  data() {
    return {
      isLoading: true,
      isLoadingPreview: false,
      isLoadingTemplates: false,
      isTemplateNotSelectable: false
    };
  },
  computed: {
    ...mapState('bizMessageTemplate', ['templates']),
    BizMessageTemplateType: () => BizMessageTemplateType,
    title() {
      return BizMessageCampaignType.t(this.campaignType);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('tip_message')
            },
            {
              id: 'template_type',
              type: 'select_radio',
              label: this.$t('biz_message_template_type.label'),
              options: [
                {
                  value: BizMessageTemplateType.GROUP,
                  label: this.$t('biz_message_template_type.group')
                },
                {
                  value: BizMessageTemplateType.NORMAL,
                  label: this.$t('biz_message_template_type.custom')
                }
              ]
            },
            ...(this.isLoadingTemplates ? [] : this.templateSections)
          ]
        }
      ];
    },
    templateSections() {
      if (this.formObject.template_type === BizMessageTemplateType.GROUP)
        return [
          {
            id: 'template_id',
            type: 'select_filterable',
            options: this.templates.map(e => ({
              label: e.name,
              value: e.id
            })),
            label: this.$t('template_id'),
            placeholder: this.$t('template_id_placeholder'),
            validate: ['required']
          },
          ...(this.formObject.template_id
            ? this.templateOptionIds.map(id => ({
                id,
                label: this.$t(`template.${id}`),
                value: this.template[id],
                ...this.templateOptions[id]
              }))
            : [])
        ];
      else
        return [
          {
            id: 'template_id',
            label: this.$t('template_id'),
            type: 'custom',
            invalid: this.isTemplateNotSelectable
              ? this.$t('template_not_selectable')
              : false,
            validate: ['required']
          }
        ];
    }
  },
  watch: {
    'formObject.template_type'() {
      this.setTemplateType(this.formObject.template_type);
      this.isLoadingTemplates = true;
      const promise =
        this.formObject.template_type === BizMessageTemplateType.GROUP
          ? this.fetchDefaultGroupTemplates
          : this.fetchTemplates;
      promise(CremaServiceType.REVIEW).finally(
        () => (this.isLoadingTemplates = false)
      );
    },
    'formObject.template_id'() {
      this.setTemplateData();
      this.validateField('template_id');
    }
  },
  mounted() {
    this.fetchCampaign();
  },
  beforeDestroy() {
    this.setTemplateType(null);
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('bizMessageTemplate', [
      'fetchTemplate',
      'fetchTemplates',
      'fetchDefaultGroupTemplates',
      'setTemplateType'
    ]),
    setTemplateData() {
      if (this.formObject.template_id) {
        this.isLoadingPreview = true;
        this.fetchTemplate(this.formObject.template_id)
          .then(
            ({ data }) => (this.template = this.formatTemplate(data.template))
          )
          .finally(() => (this.isLoadingPreview = false));
      } else this.template = { buttons: [] };
    },
    clickNewTemplate() {
      this.openDialog([
        'BizMessageTemplateFormDialog',
        { serviceType: CremaServiceType.REVIEW, inspectDirectly: true }
      ]);
    },
    fetchCampaign() {
      this.isLoadingPreview = false;
      this.isLoading = true;
      return api
        .get('/biz_message/campaigns/campaign', {
          params: { campaign_type: this.campaignType }
        })
        .then(({ data }) => (this.orgFormObject = data.campaign))
        .finally(() => (this.isLoading = false));
    },
    createCampaign(formData) {
      this.isSubmitting = true;
      return api
        .post('/biz_message/campaigns', formData, {
          params: { campaign_type: this.campaignType },
          successMessage: this.$t('app.saved')
        })
        .finally(() => (this.isSubmitting = false));
    },
    updateCampaign(formData) {
      this.isSubmitting = true;
      return api
        .patch(`/biz_message/campaigns/${this.formObject.id}`, formData, {
          successMessage: this.$t('app.saved')
        })
        .finally(() => (this.isSubmitting = false));
    },
    changeGroup(group) {
      if (group.id !== 'template_type') return;

      if (group.value === BizMessageTemplateType.GROUP) {
        this.formObject.template_id = BizMessageCampaignType.defaultTemplateId(
          this.campaignType
        );
      } else this.formObject.template_id = null;
    },
    submit(formData) {
      const promise = this.formObject.id
        ? this.updateCampaign(formData)
        : this.createCampaign(formData);
      promise.then(() => {
        this.eventBus.$emit('saved');
        this.close(true);
      });
    }
  }
};
</script>

<style scoped>
.BizMessageCampaignFormDialog__template-content {
  white-space: pre-line;
}

.BizMessageCampaignFormDialog__template-description {
  white-space: pre-line;
}
</style>

<i18n locale="ko">
{
  "subtitle": "알림톡 발송설정",
  "tip_message": "바로 발송이 가능한 <b>기본 메시지 사용</b>을 추천드립니다.<br /><b>직접 메시지 입력</b>은 카카오 검수 승인 이전에는 메시지 발송이 불가합니다.",
  "biz_message_template_type": {
    "label": "메시지 유형",
    "group": "기본 메시지 사용",
    "custom": "직접 메시지 사용"
  },
  "template_id": "발송할 메시지",
  "template_id_placeholder": "발송할 메시지를 선택하세요.",
  "template": {
    "category_code": "템플릿 카테고리",
    "header": "헤더",
    "title": "타이틀",
    "subtitle": "서브 타이틀",
    "image": "이미지 첨부",
    "highlight_image": "썸네일 이미지 (1:1 비율)",
    "item_list": "아이템 리스트",
    "content": "발송 문구",
    "extra": "부가정보",
    "buttons": "버튼"
  },
  "add_template": "+ 메시지 템플릿 생성",
  "template_description": "카카오톡 정책상 다음 과정을 거쳐야 합니다.\n\n1. 메시지 템플릿 생성\n2. 템플릿 카카오톡 검수후 승인\n3. 검수 승인된 템플릿으로 알림톡 발송\n\n",
  "template_description_link": "상세 메뉴얼 보기",
  "template_not_selectable": "사용가능한 '직접 메시지 입력' 템플릿이 없습니다.\n'기본 메시지 사용'을 선택하여 저장해주세요."
}
</i18n>
