<template>
  <AppModalDefault
    :title="$t('dialog_title')"
    is-maximized-by-default
    is-max-height
    @close="clickCloseButton"
  >
    <template #body>
      <div class="ReviewDashboardRequestInstallationDialog__containter">
        <div class="ReviewDashboardRequestInstallationDialog__section">
          <div class="ReviewDashboardRequestInstallationDialog__section-title">
            {{ $t('title.optional_widgets') }}
          </div>
          <div
            class="ReviewDashboardRequestInstallationDialog__section-content"
          >
            <AppInfoBox
              class="ReviewDashboardRequestInstallationDialog__info-box"
            >
              {{ $t('info_content') }}
            </AppInfoBox>
            <div
              v-for="(request, i) in requestsOnPageType"
              :key="i"
              class="ReviewDashboardRequestInstallationDialog__image-table"
            >
              <div class="ReviewDashboardRequestInstallationDialog__image">
                <AppImage :src="imageUrl[request.page_type]" />
              </div>
              <div class="ReviewDashboardRequestInstallationDialog__table">
                <div
                  class="ReviewDashboardRequestInstallationDialog__table-topbar"
                >
                  <div>
                    <span
                      class="ReviewDashboardRequestInstallationDialog__table-topbar__table-name"
                    >
                      {{ WidgetPageType.t(request.page_type) }}
                    </span>
                    <AppBadge
                      v-tooltip="
                        $t(
                          `table.tooltip.${WidgetPageType.key(
                            request.page_type
                          ).toLowerCase()}`
                        )
                      "
                      class="ReviewDashboardRequestInstallationDialog__table-topbar-badge"
                      :label="badgeLabel(request.page_type)"
                      :badge-style="badgeStyle(request.page_type)"
                    />
                  </div>
                  <div>
                    <AppButton
                      :label="$t('table.buttons.select_widget')"
                      :button-style="'blue'"
                      @click="openSelectWidgetFormDialog(request.page_type)"
                    />
                  </div>
                </div>
                <AppTable
                  v-if="request.rows.length"
                  table-id="request-installation-table"
                  :columns="pageTypeColumns"
                  :rows="request.rows"
                >
                  <template #cell="{ row, column }">
                    <template v-if="column.id === 'preview'">
                      <AppButton
                        label="PC"
                        :disabled="isCustomPlacement(row.presetPlacementType)"
                        @click="
                          openWidgetSetPreviewDialog(
                            false,
                            row.pageType,
                            row.presetPlacementType,
                            row.presetWidgetType
                          )
                        "
                      />
                      <AppButton
                        label="모바일"
                        :disabled="isCustomPlacement(row.presetPlacementType)"
                        @click="
                          openWidgetSetPreviewDialog(
                            true,
                            row.pageType,
                            row.presetPlacementType,
                            row.presetWidgetType
                          )
                        "
                      />
                    </template>
                  </template>
                </AppTable>
                <div
                  v-else
                  class="ReviewDashboardRequestInstallationDialog__reminder"
                >
                  {{ $t('table.reminder.select_widget') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ReviewDashboardRequestInstallationDialog__section">
          <div class="ReviewDashboardRequestInstallationDialog__section-title">
            {{ $t('title.count_and_score_widgets') }}
          </div>
          <div
            class="ReviewDashboardRequestInstallationDialog__section-content"
          >
            <div class="ReviewDashboardRequestInstallationDialog__image-table">
              <div class="ReviewDashboardRequestInstallationDialog__image">
                <AppImage
                  :src="
                    imageUrl[
                      InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
                    ]
                  "
                />
              </div>
              <div class="ReviewDashboardRequestInstallationDialog__table">
                <div
                  class="ReviewDashboardRequestInstallationDialog__table-topbar"
                >
                  <div>
                    <span
                      class="ReviewDashboardRequestInstallationDialog__table-topbar__table-name"
                    >
                      {{
                        InstallationPresetWidgetType.t(
                          InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
                        )
                      }}
                    </span>
                    <AppBadge
                      v-tooltip="$t('table.tooltip.count_and_score_widget')"
                      class="ReviewDashboardRequestInstallationDialog__table-topbar-badge"
                      :label="
                        badgeLabel(
                          InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
                        )
                      "
                      :badge-style="
                        badgeStyle(
                          InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
                        )
                      "
                    />
                  </div>
                  <AppButton
                    :label="$t('table.buttons.select_attach_type')"
                    :button-style="'blue'"
                    @click="openSelectCountAndScoreWidgetFormDialog()"
                  />
                </div>
                <AppTable
                  v-if="countAndScoreWidgetRows.length"
                  table-id="count-and-score-widget-request-installation-table"
                  :columns="countAndScoreWidgetColumns"
                  :rows="countAndScoreWidgetRows"
                >
                  <template #cell="{ row, column }">
                    <template v-if="column.id === 'preview'">
                      <template
                        v-if="
                          row.attach_type ===
                            InstallationPresetAttachType.REPLACE
                        "
                      >
                        -
                      </template>
                      <template v-else>
                        <AppButton
                          :label="$t('app.pc')"
                          @click="
                            openWidgetSetPreviewDialog(
                              false,
                              row.pageType,
                              row.presetPlacementType,
                              row.presetWidgetType,
                              row.displayFormat
                            )
                          "
                        />
                        <AppButton
                          :label="$t('app.mobile')"
                          @click="
                            openWidgetSetPreviewDialog(
                              true,
                              row.pageType,
                              row.presetPlacementType,
                              row.presetWidgetType,
                              row.displayFormat
                            )
                          "
                        />
                      </template>
                    </template>
                  </template>
                </AppTable>
                <div
                  v-else
                  class="ReviewDashboardRequestInstallationDialog__reminder"
                >
                  {{ $t('table.reminder.select_attach_type') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ReviewDashboardRequestInstallationDialog__section">
          <div class="ReviewDashboardRequestInstallationDialog__section-title">
            {{ $t('title.required_widgets') }}
          </div>
          <div
            class="ReviewDashboardRequestInstallationDialog__section-content"
          >
            <div
              class="ReviewDashboardRequestInstallationDialog__section-content--required-widgets"
            >
              <div
                v-for="requiredWidget in requiredWidgets"
                :key="requiredWidget.label"
                class="ReviewDashboardRequestInstallationDialog__required-widget"
              >
                <div>
                  <span
                    class="ReviewDashboardRequestInstallationDialog__required-widget-label"
                  >
                    {{ requiredWidget.label }}
                  </span>
                  <span>
                    {{
                      $t('install_position', [
                        requiredWidget.presetPlacementTypeText
                      ])
                    }}
                  </span>
                </div>
                <div>
                  <AppInlineButton
                    class="ReviewDashboardRequestInstallationDialog__required-widget-detail-button"
                    :label="$t('preview.pc')"
                    button-style="caption"
                    @click="
                      openWidgetPreviewDialog(
                        false,
                        requiredWidget.presetWidgetType
                      )
                    "
                  />
                  <AppInlineButton
                    class="ReviewDashboardRequestInstallationDialog__required-widget-detail-button"
                    :label="$t('preview.mobile')"
                    button-style="caption"
                    @click="
                      openWidgetPreviewDialog(
                        true,
                        requiredWidget.presetWidgetType
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #foot>
      <div class="ReviewDashboardRequestInstallationDialog__buttons">
        <AppButton
          button-style="blue"
          :label="$t('submit_label')"
          :disabled="submitButtonDisabled"
          @click="submit"
        />
        <AppButton
          button-style="default"
          :label="$t('close.label')"
          @click="clickCloseButton"
        />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DialogView from '@/mixins/DialogView';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallationPresetWidgetType from '@/enums/InstallationPresetWidgetType';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';
import InstallationPresetAttachType from '@/enums/InstallationPresetAttachType';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'ReviewDashboardRequestInstallationDialog',
  mixins: [DialogView],
  computed: {
    ...mapState('requestInstallations', ['requestInstallations']),
    ...mapGetters('easyJoin', ['isPaymentCompleted']),
    ...mapGetters('requestInstallations', [
      'productDetailPageTypeRequests',
      'mainPageTypeRequests',
      'reviewBoardPageTypeRequests',
      'countAndScoreWidgetRequests'
    ]),
    WidgetPageType() {
      return WidgetPageType;
    },
    InstallationPresetAttachType: () => InstallationPresetAttachType,
    InstallationPresetWidgetType: () => InstallationPresetWidgetType,
    imageUrl() {
      return {
        [WidgetPageType.PRODUCT_DETAIL]:
          'https://assets.cre.ma/m/admin/v2/request_installation_product_detail@3x.png',
        [WidgetPageType.REVIEW_BOARD]:
          'https://assets.cre.ma/m/admin/v2/request_installation_reviews@3x.png',
        [WidgetPageType.MAIN]:
          'https://assets.cre.ma/m/admin/v2/request_installation_main@3x.png',
        [InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET]:
          'https://assets.cre.ma/m/admin/v2/request_installation_count_and_score_widget@3x.png'
      };
    },
    pageTypeColumns() {
      return [
        { id: 'widget', label: this.$t('table.columns.widget') },
        {
          id: 'presetPlacementTypeText',
          label: this.$t('table.columns.position')
        },
        { id: 'preview', label: this.$t('table.columns.preview') }
      ];
    },
    countAndScoreWidgetColumns() {
      return [
        {
          id: 'attach_type_text',
          label: this.$t('table.columns.attach_type')
        },
        { id: 'page_type', label: this.$t('table.columns.page_type') },
        {
          id: 'presetPlacementTypeText',
          label: this.$t('table.columns.position')
        },
        { id: 'preview', label: this.$t('table.columns.preview') }
      ];
    },
    requestsOnPageType() {
      return [
        {
          page_type: WidgetPageType.PRODUCT_DETAIL,
          rows: this.productDetailWidgetRows
        },
        {
          page_type: WidgetPageType.REVIEW_BOARD,
          rows: this.reviewBoardWidgetRows
        },
        {
          page_type: WidgetPageType.MAIN,
          rows: this.mainWidgetRows
        }
      ];
    },
    requiredWidgets() {
      return [
        {
          label: this.$t('widget.review_popup.label'),
          presetPlacementTypeText: this.$t('widget.review_popup.position'),
          presetWidgetType: InstallationPresetWidgetType.REVIEW_POPUP_WIDGET
        },
        {
          label: this.$t('widget.managing_reviews.label'),
          presetPlacementTypeText: this.$t('widget.managing_reviews.position'),
          presetWidgetType:
            InstallationPresetWidgetType.MY_REVIEWS_MANAGING_REVIEWS_WIDGET
        }
      ];
    },
    productDetailWidgetRows() {
      return this.productDetailPageTypeRequests.map(i => ({
        widget: InstallationPresetWidgetType.t(i.presetWidgetType),
        presetPlacementTypeText: InstallationPresetPlacementType.t(i.position),
        presetWidgetType: i.presetWidgetType,
        presetPlacementType: i.position,
        pageType: i.pageType,
        widgetSetType: i.widgetSetType
      }));
    },
    reviewBoardWidgetRows() {
      return this.reviewBoardPageTypeRequests.map(i => ({
        widget: InstallationPresetWidgetType.t(i.presetWidgetType),
        presetPlacementTypeText: InstallationPresetPlacementType.t(i.position),
        presetWidgetType: i.presetWidgetType,
        presetPlacementType: i.position,
        pageType: i.pageType,
        widgetSetType: i.widgetSetType
      }));
    },
    mainWidgetRows() {
      return this.mainPageTypeRequests.map(i => ({
        widget: InstallationPresetWidgetType.t(i.presetWidgetType),
        presetPlacementTypeText: InstallationPresetPlacementType.t(i.position),
        presetWidgetType: i.presetWidgetType,
        presetPlacementType: i.position,
        pageType: i.pageType,
        widgetSetType: i.widgetSetType
      }));
    },
    countAndScoreWidgetRows() {
      return this.countAndScoreWidgetRequests
        .slice(0)
        .sort((a, b) => {
          if (a.pageType === WidgetPageType.PRODUCT_DETAIL) return 1;
          if (b.pageType === WidgetPageType.PRODUCT_DETAIL) return -1;
          return 0;
        })
        .map(i => ({
          attach_type: i.attachType,
          attach_type_text: InstallationPresetAttachType.t(i.attachType),
          presetWidgetType: i.presetWidgetType,
          displayFormat: i.display_format,
          presetPlacementType:
            i.position || InstallationPresetPlacementType.AFTER_THUMBNAIL_IMAGE,
          presetPlacementTypeText: i.position
            ? InstallationPresetPlacementType.t(i.position)
            : '-',
          page_type: WidgetPageType.hasValue(i.pageType)
            ? WidgetPageType.t(i.pageType)
            : '-',
          pageType: i.pageType || WidgetPageType.MAIN
        }));
    },
    submitButtonDisabled() {
      return this.productDetailWidgetRows.length === 0;
    }
  },
  methods: {
    ...mapActions('dialog', ['alert', 'confirm', 'openDialog']),
    ...mapActions('easyJoin', ['requestWidgetInstall']),
    ...mapMutations('requestInstallations', [
      'SET_INSTALLATIONS',
      'SET_PAGE_TYPE_INSTALLATIONS'
    ]),
    openSelectWidgetFormDialog(pageType) {
      this.openDialog(['RequestInstallWidgetSelectionDialog', { pageType }]);
    },
    openSelectCountAndScoreWidgetFormDialog() {
      this.openDialog('RequestInstallCountAndScoreWidgetSelectionDialog');
    },
    openWidgetSetPreviewDialog(
      isMobile,
      pageType,
      presetPlacementType,
      presetWidgetType,
      displayFormat
    ) {
      this.openDialog([
        'ReviewDashboardRequestWidgetInstallationPreviewDialog',
        {
          isMobile,
          pageType,
          presetPlacementType,
          presetWidgetType,
          displayFormat
        }
      ]);
    },
    openWidgetPreviewDialog(isMobile, presetWidgetType) {
      this.openDialog([
        'ReviewDashboardRequestInstallationWidgetPreviewDialog',
        {
          isMobile,
          presetWidgetType
        }
      ]);
    },
    isCustomPlacement(presetPlacementType) {
      return presetPlacementType === InstallationPresetPlacementType.CUSTOM;
    },
    clickCloseButton() {
      this.confirm({
        title: this.$t('close.confirm_title'),
        message: this.$t('close.confirm_message'),
        closeButtonLabel: this.$t('close.label')
      }).then(result => {
        if (!result) return;

        this.SET_INSTALLATIONS([]);
        this.close();
      });
    },
    submit() {
      this.showWidgetInstallRequestConfirm();
    },
    showWidgetInstallRequestConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          title: this.$t('widget_install_request_confirm_title'),
          markdownText: this.isPaymentCompleted
            ? this.$t('widget_install_request_confirm_message_after_payment')
            : this.$t('widget_install_request_confirm_message'),
          closeButtonLabel: this.$t('app.complete'),
          checkboxLabel: this.$t('check_caution'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', this.submitWidgetInstallRequest);
      });
    },
    submitWidgetInstallRequest() {
      const install_requests = this.requestInstallations.map(i => ({
        page_type: i.pageType,
        install_position: i.position,
        install_widget_type: i.presetWidgetType,
        pc_image_url: i.pc_image_url,
        pc_comment: i.pc_comment,
        mobile_image_url: i.mobile_image_url,
        mobile_comment: i.mobile_comment,
        display_format: i.display_format,
        attach_type: i.attachType
      }));

      this.requestWidgetInstall(install_requests)
        .then(this.close)
        .catch(() => {
          this.alert({
            title: this.$t('widget_install_request_failure_title'),
            message: this.$t('widget_install_request_failure_message'),
            closeButtonLabel: this.$t('app.confirmed')
          });
        });
    },
    badgeStyle(value) {
      switch (value) {
        case WidgetPageType.PRODUCT_DETAIL:
          return 'mint-green-outline';
        case WidgetPageType.MAIN:
        case WidgetPageType.REVIEW_BOARD:
        case InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET:
          return 'grey-outline';
        default:
          return 'default';
      }
    },
    badgeLabel(value) {
      switch (value) {
        case WidgetPageType.PRODUCT_DETAIL:
          return this.$t('required');
        case WidgetPageType.MAIN:
        case WidgetPageType.REVIEW_BOARD:
        case InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET:
          return this.$t('optional');
        default:
          return this.$t('optional');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  .AppModal__main {
    background-color: $color-grey-05;
  }
  .AppModal__head {
    background-color: #fff;
  }

  .request-installation-table__widget,
  .request-installation-table__position,
  .request-installation-table__preview {
    flex: 1 0 0;
    min-width: 201px;
  }
}

.ReviewDashboardRequestInstallationDialog__containter {
  width: 900px;
  margin: 0 auto;
  text-align: center;
}
.ReviewDashboardRequestInstallationDialog__section {
  & + & {
    margin-top: 12px;
  }
}
.ReviewDashboardRequestInstallationDialog__section-title {
  @include text-sub-title;
  padding: 28px 0 16px;
}
.ReviewDashboardRequestInstallationDialog__info-box {
  margin-bottom: 16px;
}
.ReviewDashboardRequestInstallationDialog__image-table {
  display: flex;
  flex-direction: row;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 12px;
  min-height: 208px;
}
.ReviewDashboardRequestInstallationDialog__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  background-color: #e7eaed;
  border-radius: 4px;
  margin-right: 20px;
  padding: 0 20px;
  box-sizing: content-box;
}
.ReviewDashboardRequestInstallationDialog__table {
  width: 100%;
}
.ReviewDashboardRequestInstallationDialog__table-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 10px;
  line-height: 34px;
}
.ReviewDashboardRequestInstallationDialog__table-topbar__table-name {
  @include text-sub-title;
}

.ReviewDashboardRequestInstallationDialog__table-topbar-badge {
  position: relative;
  margin-left: 4px;
}
.ReviewDashboardRequestInstallationDialog__reminder {
  @include text-caption;
  display: flex;
  height: 105px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  white-space: pre-wrap;
}
.ReviewDashboardRequestInstallationDialog__buttons {
  text-align: center;
}

.ReviewDashboardRequestInstallationDialog__section-content {
  &--required-widgets {
    border: 1px solid $color-grey-25;
    border-radius: 4px;
    padding: 20px;
  }
}

.ReviewDashboardRequestInstallationDialog__required-widget {
  width: 516px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }

  &-detail-button {
    & + & {
      margin-left: 8px;
    }
  }
}
</style>

<i18n locale="ko">
{
  "dialog_title": "위젯 설치 요청",
  "title": {
    "optional_widgets": "각 페이지에 설치할 위젯을 선택해 주세요.",
    "required_widgets": "아래 위젯은 크리마 리뷰 이용시 기본적으로 설치해야할 필수 위젯입니다.",
    "count_and_score_widgets": "리뷰 수 + 평점 위젯의 설치 방식을 선택해 주세요."
  },
  "submit_label": "위젯 설치 요청",
  "close": {
    "label": "다음에 다시하기",
    "confirm_title": "설치 요청을 다음에 다시 하시겠습니까?",
    "confirm_message": "다음에 다시할 경우 현재까지 입력한 정보는 저장되지 않습니다.\n설치 요청을 다음에 다시 하시겠습니까?"
  },
  "info_content": "쇼핑몰에 기존에 존재하는 리뷰 영역은 설치 요청하지 않아도 크리마팀에서 적절한 위젯으로 변경해드릴 예정입니다.",
  "table": {
    "columns": {
      "widget": "설치 위젯",
      "position": "설치 위치",
      "attach_type": "설치 방식",
      "page_type": "설치 페이지",
      "preview": "설치 예시 미리보기"
    },
    "tooltip": {
      "product_detail": "제품의 상세 정보를 볼 수 있는 페이지에 위젯을 설치하여 고객의 구매 결정을 도울 수 있습니다.",
      "review_board": "고객이 작성한 구매평을 모아 보여주는 페이지에 위젯을 설치하여 모든 상품에 대한 리뷰를 더욱 효과적으로 보여줄 수 있습니다.\n선택하지 않을 경우 기존 영역 교체만 진행됩니다. 전체 리뷰 페이지를 사용하지 않는다면 설치되지 않습니다.",
      "main": "쇼핑몰 사이트에 들어왔을 때 가장 먼저 보여지는 페이지에 다양한 스타일의 리뷰 위젯을 효과적으로 적용할 수 있습니다.\n선택하지 않을 경우 기존 영역 교체만 진행됩니다.",
      "count_and_score_widget": "쇼핑몰 내 추가 리뷰 수 + 평점 위젯 설치 여부를 선택할 수 있습니다.\n선택하지 않아도 기존 리뷰 영역 교체는 진행됩니다. 기존 리뷰 수 영역이 없다면 교체 작업이 진행되지 않습니다."
    },
    "buttons": {
      "select_widget": "위젯 선택",
      "select_attach_type": "설치 방식 선택"
    },
    "reminder": {
      "select_widget": "[위젯 선택] 버튼 클릭 시, 이 페이지에 설치할 위젯을 선택할 수 있습니다.",
      "select_attach_type": "[설치 방식 선택] 버튼 클릭 시, 이 위젯을 설치할 방식을 선택할 수 있습니다."
    }
  },
  "widget": {
    "review_popup": {
      "label": "리뷰 작성 팝업",
      "position": "메인, 상품 상세, 주문 조회, 마이 페이지"
    },
    "managing_reviews": {
      "label": "내 리뷰 위젯",
      "position": "내 게시글 관리 페이지 하단"
    }
  },
  "install_position": "(설치 위치: {0})",
  "preview": {
    "pc": "PC 미리보기",
    "mobile": "모바일 미리보기"
  },
  "widget_install_request_confirm_title": "설치 요청을 완료하시겠습니까?",
  "widget_install_request_confirm_message": "요청 내용은 결제 후 바로 설치팀에 접수되어 진행되기 때문에 변경할 수 없습니다. 변경을 원할 경우 설치 완료 후 운영팀에 요청할 수 있습니다.",
  "widget_install_request_confirm_message_after_payment": "요청 내용은 바로 설치팀에 접수되어 진행되기 때문에 변경할 수 없습니다. 변경을 원할 경우 설치 완료 후 운영팀에 요청할 수 있습니다.",
  "check_caution": "주의 사항 확인 후 신청합니다.",
  "widget_install_request_failure_title": "위젯 설치 요청을 실패했습니다.",
  "widget_install_request_failure_message": "위젯 설치 요청 화면을 유지한 상태로 약 5분 뒤 다시 시도해주세요.\n화면을 닫을 경우 현재까지 입력한 정보는 저장되지 않습니다.\n문제가 반복될 경우 관리자 페이지 우측 하단 [ 물음표(?) 아이콘 > 서비스 문의] 기능을 통해 크리마 운영팀에 문의해주세요.",
  "required": "필수",
  "optional": "선택"
}
</i18n>
