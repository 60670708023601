<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
    >
      <template #group="{ id, errors }">
        <template v-if="id === 'excluding_category_ids'">
          <AppMultipleSelectProductCategory
            v-if="isChannelMultiFeedEnabled"
            v-model="formObject.excluding_category_ids"
            class="AppForm__group-field"
            name="channel_settings[excluding_category_ids][]"
          />
          <AppSelectProductCategory
            v-else
            v-model="formObject.excluding_category_ids"
            class="AppForm__group-field"
            name="channel_settings[excluding_category_ids][]"
            multiple
          />
        </template>
        <template v-else-if="id === 'purchase_rate_filter_criteria'">
          <div class="AppForm__group-field">
            <i18n path="form.purchase_rate_filter_criteria.field">
              <AppSelect
                v-model="formObject.purchase_rate_filter_type"
                name="channel_settings[purchase_rate_filter_type]"
                :options="purchaseRateFilterTypeOptions"
                inline
              />
              <AppNumberInput
                v-model="formObject.purchase_rate_filter_min_count"
                name="channel_settings[purchase_rate_filter_min_count]"
                :digits="3"
                :invalid="!!errors.purchase_rate_filter_min_count"
                inline
                @change="validateField('purchase_rate_filter_min_count')"
                @blur="validateField('purchase_rate_filter_min_count')"
              />
            </i18n>
            <AppFormError :error="errors.purchase_rate_filter_min_count" />
          </div>
        </template>
        <template v-else-if="id === 'cohort_csv_download'">
          <AppButton class="AppForm__group-field">
            <a :href="cohortCsvDownloadUrl">
              {{ $t('csv_download_button') }}
            </a>
          </AppButton>
        </template>
        <template v-else-if="id === 'product_categories_csv'">
          <AppButton class="AppForm__group-field">
            <a :href="productCategoriesCsvDownloadUrl">
              {{ $t('csv_download_button') }}
            </a>
          </AppButton>
          <div class="AppForm__group-field">
            <AppFileInput
              id="product-categories-csv-file"
              class="ChannelSettings__csv-file-input"
              :event-bus="fileEventBus"
              :accept="'.csv'"
              :placeholder="$t('csv_upload_file_input_placeholder')"
              @change="changeCategoriesCsvFile"
            />
            <AppButton
              :disabled="!isCategoriesCsvSelected"
              :label="$t('app.upload')"
              @click="uploadCategoriesCsv"
            />
          </div>
        </template>
      </template>
      <template #group-foot="{ id, value }">
        <template v-if="id === 'min_products_count'">
          <AppCheckbox
            v-model="formObject.check_products_count"
            class="AppForm__group-field"
            :label="$t('form.check_products_count.label')"
            name="channel_settings[check_products_count]"
            :disabled="!formObject.min_products_count"
          />
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import TargetContentView from '@/mixins/TargetContentView';
import ProductThumbnail from '@/enums/ProductThumbnail';
import ChannelProductFilterType from '@/enums/ChannelProductFilterType';

export default {
  name: 'ChannelSettingsBasic',
  mixins: [FormView, SettingsView, TargetContentView],
  data: () => ({
    isLoading: true,
    isCategoriesCsvSelected: false,
    fileEventBus: new Vue(),
    selectedCategoriesCsvFile: null
  }),
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters(['pathWithBrandParams']),
    ...mapGetters('session', ['isChannelMultiFeedEnabled']),
    productThumbnailOptions() {
      return ProductThumbnail.options();
    },
    SETTINGS_ID() {
      return 'channel_settings_basic';
    },
    objectId() {
      return 'channel_settings';
    },
    groupOptions() {
      if (this.isLoading) return {};

      return {
        excluding_category_ids: {
          label: this.isChannelMultiFeedEnabled
            ? this.$t('form.new_label.excluding_category_ids')
            : undefined
        },
        in_categories: {
          type: 'checkbox',
          label: this.isChannelMultiFeedEnabled
            ? this.$t('form.new_label.in_categories')
            : undefined
        },
        excluding_product_keywords: {
          type: 'tags',
          label: this.isChannelMultiFeedEnabled
            ? this.$t('form.new_label.excluding_product_keywords')
            : undefined,
          placeholder: this.$t('form.excluding_product_keywords.placeholder'),
          searchPlaceholder: this.$t(
            'form.excluding_product_keywords.search_placeholder'
          ),
          searchNoResults: this.$t(
            'form.excluding_product_keywords.search_no_results'
          ),
          tagAlreadyExistsMessage: this.$t(
            'form.excluding_product_keywords.tag_already_exists'
          )
        },
        ...(this.isChannelMultiFeedEnabled
          ? {
              product_categories_csv: {
                groupDescription: {
                  message: this.$t('form.product_categories_csv.description'),
                  mode: 'markdown'
                }
              }
            }
          : {
              min_products_count: {
                type: 'number',
                digits: 4,
                i18n: 'unit.count',
                validate: [{ rule: 'min_max_integer', min: 0, max: 9999 }]
              },
              proper_products_count: {
                type: 'number',
                digits: 4,
                i18n: 'unit.count',
                validate: [{ rule: 'min_max_integer', min: 0, max: 9999 }]
              },
              purchase_rate_filter_criteria: {
                fields: [
                  {
                    id: 'purchase_rate_filter_min_count',
                    validate: [
                      'required',
                      { rule: 'min_max_integer', min: 0, max: 999 }
                    ]
                  }
                ]
              }
            })
      };
    },
    cohortCsvDownloadUrl() {
      return this.pathWithBrandParams('/api/channel/feeds/0/csvs/download');
    },
    productCategoriesCsvDownloadUrl() {
      return this.pathWithBrandParams(
        '/api/channel/product_categories/download_csv'
      );
    },
    purchaseRateFilterTypeOptions() {
      return [
        {
          label: this.$t('purchase_rate_filter_type.click_count'),
          value: ChannelProductFilterType.MOST_CLICKED
        },
        {
          label: this.$t('purchase_rate_filter_type.purchase_count'),
          value: ChannelProductFilterType.MOST_PURCHASED
        }
      ];
    }
  },
  watch: {
    'formObject.min_products_count'() {
      if (this.formObject.min_products_count === null)
        this.formObject.check_products_count = false;
    }
  },
  mounted() {
    api
      .get('/channel/settings')
      .then(({ data }) => {
        this.orgFormObject = data.settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/channel/settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    },
    changeCategoriesCsvFile(file) {
      this.isCategoriesCsvSelected = true;
      this.selectedCategoriesCsvFile = file;
    },
    uploadCategoriesCsv() {
      var data = new FormData();
      data.append('file', this.selectedCategoriesCsvFile);
      api
        .post('/channel/product_categories/upload_csv', data, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.isCategoriesCsvSelected = false;
          this.fileEventBus.$emit('reset');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelSettings__csv-file-input {
  display: inline-block;
  width: 372px;
  margin-right: 8px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "new_label": {
      "excluding_category_ids": "모든 피드에서 특정 상품 카테고리 노출 제한",
      "in_categories": "모든 피드에서 카테고리에 미포함된 광고 상품 노출 제외",
      "excluding_product_keywords": "모든 피드에서 특정 상품 키워드 포함 상품 노출 제한"
    },
    "excluding_product_keywords": {
      "placeholder": "상품 키워드를 선택해주세요.",
      "search_placeholder": "노출 제한할 키워드를 입력해 주세요.",
      "search_no_results": "입력한 키워드를 추가할 수 있습니다.",
      "tag_already_exists": "이미 포함된 키워드입니다."
    },
    "check_products_count": {
      "label": "광고 피드에 포함된 상품이 최소 상품 수 이하일 때 알림을 발송합니다."
    },
    "purchase_rate_filter_criteria": {
      "field": "{0}{1}회 이상인 상품 중 클릭한 사람수 대비 주문 비율"
    },
    "product_categories_csv": {
      "description": "CSV 파일 열기 또는 편집에 대한 도움이 필요하시다면 [CSV 파일 열기 또는 편집 가이드](https://www.notion.so/crema/CSV-fd014f3ae3aa4185bc8ecf53b1d82080)를 참고해주세요."
    }
  },
  "purchase_rate_filter_type": {
    "click_count": "상품 조회수",
    "purchase_count": "판매량"
  },
  "csv_download_button": "CSV 다운로드",
  "csv_upload_file_input_placeholder": "업로드할 CSV 파일을 등록해주세요."
}
</i18n>
