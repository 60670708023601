<template>
  <div class="QaNewFeatureSettingsBrand">
    <AppAjaxContent :is-loading="isLoading">
      <AppForm
        object-id="settings"
        v-bind="formProps"
        submit-button
        v-on="formEvents"
      />
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';

export default {
  name: 'QaNewFeatureSettingsBrand',
  mixins: [FormView],
  data() {
    return { isLoading: false, data: null };
  },
  computed: {
    formSections() {
      if (this.data === null) return [];

      const groups = Object.keys(this.data).map(key => ({
        id: key,
        label: `${this.$t(key)} (${key})`,
        type: 'checkbox'
      }));

      return [{ groups }];
    }
  },
  created() {
    this.fetchBrandNewFeatureSettings();
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/qa/brand_new_feature_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    },
    fetchBrandNewFeatureSettings() {
      this.isLoading = true;
      api
        .get('/qa/brand_new_feature_settings')
        .then(({ data }) => {
          this.data = data;
          this.orgFormObject = data;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "enable_smartstore_review_sync": "스마트스토어 리뷰 매일 연동 사용",
  "disable_danal_vaccount": "무통장 결제버튼 사용",
  "enable_webp": "위젯에서 webp 사용"
}
</i18n>
