<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar>
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.sort_type"
            :options="sortTypeOptions"
            @change="searchResource"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="sorted-products-table"
      :resources="products"
      :columns="columns"
      :rows="rows"
      @paginate="paginate"
    >
      <template #cell="{ row, column }">
        <template v-if="column === 'price'">
          <div class="table-line">
            {{ $t('org_price') }} {{ row.org_price_cents | formatCurrency }}
          </div>
          <div class="table-line">
            {{ $t('final_price') }} {{ row.final_price_cents | formatCurrency }}
          </div>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import moment from 'moment';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import TargetProductDataSortType from '@/enums/TargetProductDataSortType';

export default {
  name: 'ProductSortedProducts',
  mixins: [ResourceView],
  data() {
    return {
      isLoading: false,
      products: nullResources,
      columns: [
        { id: 'id', label: this.$t('table_header.id') },
        { id: 'code', label: this.$t('table_header.code') },
        {
          id: 'product',
          label: this.$t('table_header.product'),
          type: 'product'
        },
        { id: 'price', label: this.$t('table_header.price') },
        {
          id: 'product_data',
          label: this.$t('table_header.product_data'),
          type: 'number'
        }
      ]
    };
  },
  computed: {
    sortTypeOptions() {
      return TargetProductDataSortType.options();
    },
    defaultResourceParams() {
      return {
        per: '20',
        page: '1',
        sort_type: TargetProductDataSortType.MOST_CLICKED,
        start_date: this.yesterday,
        end_date: this.yesterday
      };
    },
    rows() {
      return this.products.items.map(item => ({
        ...item,
        product: item
      }));
    },
    yesterday() {
      return moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/product/sorted_products', { params })
        .then(({ data }) => {
          this.products = data.products;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "table_header": {
    "id": "ID",
    "code": "상품번호",
    "product": "상품",
    "price": "가격",
    "product_data": "상품 조회 정보"
  },
  "org_price": "소비자가",
  "final_price": "판매가"
}
</i18n>
