var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.optionType.name,"form-props":_vm.formProps},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var value = ref.value;
var errors = ref.errors;
return [(id === 'options')?[_c('AppTable',{staticClass:"ReviewSettingsEditOptionTypeSetOptionsDialog__table",attrs:{"table-id":"review-settings-edit-option-type-set-options-table","columns":_vm.COLUMNS,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(column.id === 'display_name')?[_c('input',{attrs:{"type":"hidden","name":"options_attributes[][id]"},domProps:{"value":row.id}}),_c('input',{attrs:{"type":"hidden","name":"options_attributes[][value]"},domProps:{"value":row.value}}),_c('AppTextInput',{attrs:{"name":"options_attributes[][display_name]","invalid":!!errors[("options[" + (row.id) + "][display_name]")],"maxlength":"30"},on:{"change":function($event){return _vm.changeDisplayName(row.id)}},model:{value:(row.display_name),callback:function ($$v) {_vm.$set(row, "display_name", $$v)},expression:"row.display_name"}})]:(column.id === 'name')?[_vm._v(" "+_vm._s(_vm.optionsMap[row.value].name)+" ")]:_vm._e()]}}],null,true)})]:[_vm._v(" "+_vm._s(value)+" ")]]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }