<template>
  <div class="AppImageInput">
    <AppFileInput
      v-bind="{
        id,
        name,
        disabled,
        invalid,
        selectOnMount,
        placeholder,
        accept
      }"
      @change="changeFile"
      @blur="$emit('blur')"
    />
    <div
      v-if="caption"
      class="AppImageInput__caption"
      :class="[
        'AppImageInput__caption',
        { 'AppImageInput__caption--disabled': disabled }
      ]"
    >
      {{ caption }}
    </div>
    <div
      v-if="!noPreview && currentImageUrl"
      class="AppImageInput__preview-wrapper"
    >
      <AppImage
        class="AppImageInput__preview"
        :src="currentImageUrl"
        :style="{ height: previewHeight }"
      />
    </div>
    <div
      v-if="!notRemovable && currentImageUrl && name"
      class="AppImageInput__remove-image-wrapper"
    >
      <AppCheckbox
        v-model="removeImage"
        :name="removeName"
        :disabled="disabled"
        :label="removeImageLabel || $t('remove_image')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppImageInput',
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    caption: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      default: null
    },
    noPreview: {
      type: Boolean,
      default: false
    },
    notRemovable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('select_image');
      }
    },
    selectOnMount: { type: Boolean, default: false },
    previewHeight: { type: String, default: null },
    accept: { type: String, default: 'image/*' },
    removeImageLabel: { type: String, default: null }
  },
  data() {
    return {
      currentImageUrl: this.imageUrl,
      removeImage: false,
      fileType: null,
      fileName: null
    };
  },
  computed: {
    removeName() {
      if (this.name.match(/\[[a-z0-9_]+\]$/)) {
        return this.name.replace(/\[([a-z0-9_]+)\]$/, '[remove_$1]');
      } else {
        return `remove_${this.name}`;
      }
    }
  },
  watch: {
    imageUrl(value) {
      this.currentImageUrl = value;
    },
    currentImageUrl() {
      this.emitChange();
    },
    removeImage() {
      this.emitChange();
    }
  },
  methods: {
    changeFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = e => (this.currentImageUrl = e.target.result);
        reader.readAsDataURL(file);
        this.fileType = file.type;
        this.fileName = file.name;
      }
    },
    emitChange() {
      this.$emit('change', {
        imageUrl: this.currentImageUrl,
        removeImage: this.removeImage,
        fileType: this.fileType
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppImageInput__caption {
  @include text-caption;
  margin-top: 8px;

  &--disabled {
    color: $color-grey-40;
  }
}

.AppImageInput__preview-wrapper {
  margin-top: 8px;
  line-height: 0;
}

.AppImageInput__preview {
  max-width: 100%;
  border: solid 1px $color-grey-35;
  box-sizing: content-box;
}

.AppImageInput__remove-image-wrapper {
  margin-top: 3px;
  line-height: 0;
}
</style>

<i18n locale="ko">
{
  "select_image": "이미지를 등록해주세요.",
  "remove_image": "이미지 삭제"
}
</i18n>
<i18n locale="en">
{
  "select_image": "Please attach image.",
  "remove_image": "Remove image"
}
</i18n>
