// TODO: WidgetCombinationType으로 전환 후 삭제
import { constructEnum } from '@/lib/enum';
import InstallationPresetWidgetType from '@/enums/InstallationPresetWidgetType';
import WidgetPageType from '@/enums/WidgetPageType';

const WidgetSetType = constructEnum('WidgetSetType', {
  DEFAULT: 10,
  RECOMMENDATION: 20,
  SCORE_AND_LIST: 100,
  PHOTO_THUMBNAIL_AND_LIST: 110,
  PHOTO_THUMBNAIL_AND_DETACHABLE_BOARD_AND_LIST: 120,
  PHOTO_THUMBNAIL_AND_EXTERNAL_AND_LIST: 130,
  HORIZONTAL_SCROLL_AND_LIST: 140,
  GALLERY_AND_LIST: 150,
  EXTERNAL_AND_LIST: 160,
  PHOTO_THUMBNAIL_AND_GALLERY_AND_LIST: 170,
  PHOTO_THUMBNAIL_AND_HORIZONTAL_SCROLL_AND_LIST: 180,
  PHOTO_THUMBNAIL_AND_EXTERNAL_AND_DETACHABLE_BOARD_AND_LIST: 190,
  GALLERY: 300,
  ROUND_THUMBNAIL_GALLERY: 310,
  SQUARE: 320,
  PRODUCTS_GALLERY: 330,
  HORIZONTAL_SCROLL: 340,
  COUNT_AND_SCORE: 350,
  SQUARE_AND_COUNT_AND_SCORE: 360,
  BEST_GALLERY_AND_GALLERY_AND_LIST: 510,
  BEST_GALLERY_AND_PRODUCTS_GALLERY: 520
});

WidgetSetType.commonTypes = [
  WidgetSetType.DEFAULT,
  WidgetSetType.RECOMMENDATION
];

WidgetSetType.productDetailTypes = [
  WidgetSetType.SCORE_AND_LIST,
  WidgetSetType.PHOTO_THUMBNAIL_AND_LIST,
  WidgetSetType.PHOTO_THUMBNAIL_AND_DETACHABLE_BOARD_AND_LIST,
  WidgetSetType.PHOTO_THUMBNAIL_AND_EXTERNAL_AND_LIST,
  WidgetSetType.HORIZONTAL_SCROLL_AND_LIST
];

WidgetSetType.reviewBoardTypes = [
  WidgetSetType.GALLERY_AND_LIST,
  WidgetSetType.BEST_GALLERY_AND_GALLERY_AND_LIST,
  WidgetSetType.BEST_GALLERY_AND_PRODUCTS_GALLERY
];

WidgetSetType.mainTypes = [
  WidgetSetType.GALLERY,
  WidgetSetType.ROUND_THUMBNAIL_GALLERY,
  WidgetSetType.SQUARE,
  WidgetSetType.PRODUCTS_GALLERY,
  WidgetSetType.HORIZONTAL_SCROLL
];

WidgetSetType.defaultWidgetType = widgetPageType => {
  switch (widgetPageType) {
    case WidgetPageType.PRODUCT_DETAIL:
      return InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET;
    case WidgetPageType.MAIN:
      return InstallationPresetWidgetType.MAIN_GALLERY_WIDGET;
    case WidgetPageType.REVIEW_BOARD:
      return InstallationPresetWidgetType.REVIEW_BOARD_LIST_WIDGET;
  }
};

WidgetSetType.installationTypes = setType => {
  switch (setType) {
    case WidgetSetType.SCORE_AND_LIST:
      return [
        InstallationPresetWidgetType.PRODUCT_DETAIL_SCORE_WIDGET,
        InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET
      ];
    case WidgetSetType.PHOTO_THUMBNAIL_AND_LIST:
      return [
        InstallationPresetWidgetType.PRODUCT_DETAIL_PREVIEW_WIDGET,
        InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET
      ];
    case WidgetSetType.PHOTO_THUMBNAIL_AND_DETACHABLE_BOARD_AND_LIST:
      return [
        InstallationPresetWidgetType.PRODUCT_DETAIL_PREVIEW_WIDGET,
        InstallationPresetWidgetType.PRODUCT_DETAIL_DETACHABLE_BOARD_WIDGET,
        InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET
      ];
    case WidgetSetType.PHOTO_THUMBNAIL_AND_EXTERNAL_AND_LIST:
      return [
        InstallationPresetWidgetType.PRODUCT_DETAIL_PREVIEW_WIDGET,
        InstallationPresetWidgetType.PRODUCT_DETAIL_SOCIAL_WIDGET,
        InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET
      ];
    case WidgetSetType.HORIZONTAL_SCROLL_AND_LIST:
      return [
        InstallationPresetWidgetType.PRODUCT_DETAIL_HORIZONTAL_SCROLL_WIDGET,
        InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET
      ];
    case WidgetSetType.GALLERY_AND_LIST:
      return [
        InstallationPresetWidgetType.REVIEW_BOARD_GALLERY_WIDGET,
        InstallationPresetWidgetType.REVIEW_BOARD_LIST_WIDGET
      ];
    case WidgetSetType.BEST_GALLERY_AND_GALLERY_AND_LIST:
      return [
        InstallationPresetWidgetType.REVIEW_BOARD_BEST_WIDGET,
        InstallationPresetWidgetType.REVIEW_BOARD_GALLERY_WIDGET,
        InstallationPresetWidgetType.REVIEW_BOARD_LIST_WIDGET
      ];
    case WidgetSetType.BEST_GALLERY_AND_PRODUCTS_GALLERY:
      return [
        InstallationPresetWidgetType.REVIEW_BOARD_BEST_WIDGET,
        InstallationPresetWidgetType.REVIEW_BOARD_PRODUCTS_GALLERY_WIDGET
      ];
    case WidgetSetType.GALLERY:
      return [InstallationPresetWidgetType.MAIN_GALLERY_WIDGET];
    case WidgetSetType.ROUND_THUMBNAIL_GALLERY:
      return [InstallationPresetWidgetType.MAIN_ROUND_THUMBNAIL_GALLERY_WIDGET];
    case WidgetSetType.SQUARE:
      return [InstallationPresetWidgetType.MAIN_SQUARE_WIDGET];
    case WidgetSetType.PRODUCTS_GALLERY:
      return [InstallationPresetWidgetType.MAIN_PRODUCTS_GALLERY_WIDGET];
    case WidgetSetType.HORIZONTAL_SCROLL:
      return [InstallationPresetWidgetType.MAIN_HORIZONTAL_SCROLL_WIDGET];
  }
};

export default WidgetSetType;
