<template>
  <div class="ChannelFeedFilterUnitsFormGroup">
    <AppButton
      class="ChannelFeedFilterUnitsFormGroup__add-button"
      :label="$t('add_unit.label')"
      :disabled="cannotAddUnit"
      :tooltip="cannotAddUnit ? $t('tooltip.add_parent_unit') : ''"
      @click="addUnit"
    />
    <AppTable
      table-id="units"
      :no-data="$t('no_data')"
      :columns="columns"
      :rows="value"
    >
      <template #cell="{ column, row, rowIndex }">
        <template v-if="column.id === 'filter'">
          <ChannelUnitFilterCell
            :unit="row"
            :unit-index="rowIndex"
            :product-option-types="productOptionTypes"
            :errors="errors"
            @change="updateUnit($event, rowIndex)"
            @validate-unit-field="validateUnitField($event, rowIndex)"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <input
            v-for="(key, i) in [
              'id',
              'channel_filter_unit_id',
              'group_index',
              'recent_days',
              'filter_type',
              'filter_compare_type',
              'filter_compare_value',
              'date_compare_value',
              'product_option_type_id'
            ]"
            :key="`key-${i}`"
            type="hidden"
            :name="`${name}[][${key}]`"
            :value="row[key]"
          />
          <input
            v-if="row.product_option_value"
            type="hidden"
            :name="`${name}[][product_option_value_id]`"
            :value="row.product_option_value.id"
          />
          <AppButton
            v-if="isParentUnit(row)"
            :label="$t('add_child_unit')"
            :tooltip="$t('tooltip.add_child_unit')"
            :disabled="cannotAddUnit"
            @click="addChildUnit(row, rowIndex)"
          />
          <AppButton
            class="ChannelFeedFilterUnitsFormGroup__delete-button"
            :label="$t('app.delete')"
            :tooltip="
              isParentUnit(row)
                ? $t('tooltip.delete_parent_unit')
                : $t('tooltip.delete_child_unit')
            "
            @click="deleteUnit(row, rowIndex)"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import FormView from '@/mixins/FormView';
import ChannelProductFilterType from '@/enums/ChannelProductFilterType';
import ValueCompareType from '@/enums/ValueCompareType';

export default {
  name: 'ChannelFeedFilterUnitsFormGroup',
  mixins: [FormView],
  model: { event: 'change' },
  props: {
    value: { type: Array, required: true },
    name: { type: String, required: true },
    errors: { type: Object, default: () => {} },
    productOptionTypes: { type: Array, required: true }
  },
  computed: {
    columns() {
      return [
        { id: 'filter', label: this.$t('column.filter'), align: 'left' },
        { id: 'actions', label: this.$t('app.actions'), width: '190px' }
      ];
    },
    cannotAddUnit() {
      return this.value.length >= 7;
    },
    newGroupIndex() {
      const maxGroupIndex = Math.max(
        ...this.value.map(({ group_index }) => group_index)
      );
      return Math.max(maxGroupIndex + 1, 0);
    }
  },
  methods: {
    addUnit() {
      this.value.push(this.defaultUnit(null));
    },
    updateUnit(unit, index) {
      this.value.splice(index, 1, unit);
    },
    deleteUnit(row, rowIndex) {
      const count = this.value.filter(
        unit => unit.channel_filter_unit_id === row.id
      ).length;
      this.value.splice(rowIndex, count + 1);
    },
    addChildUnit(row, rowIndex) {
      const groupUnitsCount = this.value.filter(
        unit => unit.group_index === row.group_index
      ).length;
      const index = rowIndex + groupUnitsCount;
      this.value.splice(index, 0, this.defaultUnit(row));
    },
    defaultUnit(parentUnit) {
      return {
        group_index: parentUnit ? parentUnit.group_index : this.newGroupIndex,
        channel_filter_unit_id: parentUnit ? parentUnit.id : null,
        recent_days: 3,
        filter_type: ChannelProductFilterType.DISPLAYED,
        filter_compare_type: ValueCompareType.GTE,
        filter_compare_value: 0,
        date_compare_value: 10,
        max_products: 10
      };
    },
    isParentUnit(unit) {
      return unit.channel_filter_unit_id === null;
    },
    validateUnitField(column, index) {
      this.$emit('validate-unit-field', `${index}[${column}]`);
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelFeedFilterUnitsFormGroup__add-button {
  margin-top: 9px;
  margin-bottom: 12px;
}

.ChannelFeedFilterUnitsFormGroup__delete-button {
  float: right;
}
</style>

<i18n locale="ko">
{
  "add_unit": {
    "label": "+ 상위 조건 추가"
  },
  "column": {
    "filter": "선정 조건"
  },
  "tooltip": {
    "delete_parent_unit": "하위 조건이 있는 경우 함께 삭제합니다.",
    "delete_child_unit": "해당 하위 조건을 삭제합니다.",
    "add_parent_unit": "최대 7개의 진열 조건만 추가할 수 있습니다.",
    "add_child_unit": "선정 상품 조건을 더 제한 할 수 있는 하위 조건을 추가합니다."
  },
  "add_child_unit": "하위 조건 추가",
  "no_data": "등록된 광고 피드 상품 선정 조건이 없습니다."
}
</i18n>
