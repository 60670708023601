import { constructEnum } from '@/lib/enum';

const ProductCodeConversionStatus = constructEnum(
  'ProductCodeConversionStatus',
  {
    NONE: 0,
    PENDING: 10,
    CONVERTING: 20,
    COMPLETE: 100,
    ERRORED: 200,
    DUPLICATE_CODES: 210
  }
);

ProductCodeConversionStatus.badgeStyle = function(status) {
  switch (status) {
    case ProductCodeConversionStatus.CONVERTING:
      return 'mint-green-outline';
    case ProductCodeConversionStatus.COMPLETE:
      return 'mint-green';
    case ProductCodeConversionStatus.ERRORED:
    case ProductCodeConversionStatus.DUPLICATE_CODES:
      return 'mango-outline';
    default:
      return 'default';
  }
};

export default ProductCodeConversionStatus;
