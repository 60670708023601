<template>
  <AppModalDefault
    class="TheWidgetRequestRenewalDialog"
    :title="$t('title')"
    is-maximized-by-default
    is-max-height
    @close="close"
  >
    <template #body>
      TheWidgetRequestRenewalDialog
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TheWidgetRequestRenewalDialog',
  mixins: [DialogView]
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep {
  .AppModalDefault__body {
    min-height: calc(100vh - 64px);
    background-color: $color-grey-05;
  }
}
</style>

<i18n locale="ko">
{
  "title": "리뉴얼 위젯 설치 요청"
}
</i18n>
