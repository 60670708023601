<template>
  <AppResourceTable :enable-total-count="false" :columns="columns" :rows="rows">
    <template #cell="{ row, column }">
      <template v-if="column === 'usage_status'">
        <AppSwitch
          v-model="widget[row.key]"
          :name="`widget[${row.key}]`"
          :disabled="!widget.show_header_section"
        />
      </template>
      <template v-else-if="column === 'type'">
        <div
          :class="[
            'AppForm__sub-group-title',
            {
              'AppForm__sub-group-title--disabled': !(
                widget.show_header_section && widget.show_title
              )
            }
          ]"
        >
          {{ row.type }}
        </div>
      </template>
      <template v-else-if="column === 'sub_settings'">
        <div
          :class="[
            'ReviewSettingsShowHeaderSection__sub-settings',
            {
              'ReviewSettingsShowHeaderSection__sub-settings--direction-row': isDirectionRow(
                row.key
              )
            }
          ]"
        >
          <ReviewSettingsShowHeaderSectionSubSetting
            v-for="subSettingName in row.sub_settings"
            :key="subSettingName"
            v-bind="{ subSettingName, widget, group }"
            @change="$emit('change', subSettingName)"
            @blur="$emit('blur', subSettingName)"
          />
        </div>
      </template>
    </template>
  </AppResourceTable>
</template>

<script>
import ReviewSettingsShowHeaderSectionSubSetting from './ReviewSettingsShowHeaderSectionSubSetting';

export default {
  name: 'ReviewSettingsShowHeaderSection',
  components: { ReviewSettingsShowHeaderSectionSubSetting },
  props: {
    widget: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    columns() {
      return [
        {
          id: 'usage_status',
          label: this.$t('column.usage_status'),
          width: '70px'
        },
        {
          id: 'type',
          label: this.$t('column.type'),
          width: '155px',
          align: 'left'
        },
        {
          id: 'sub_settings',
          label: this.$t('column.sub_settings'),
          align: 'left'
        }
      ];
    },
    rows() {
      return [
        {
          key: 'show_title',
          type: this.$t('type.show_title'),
          sub_settings: [
            'use_title_and_description',
            'description_text',
            'reviews_button_title',
            'show_notices',
            'review_write_button_label'
          ]
        },
        {
          key: 'show_score_section',
          type: this.$t('type.show_score_section'),
          sub_settings: [
            'show_product_score',
            'show_product_score_histogram',
            'show_evaluation_option_type_graph'
          ]
        },
        {
          key: 'show_media_summary',
          type: this.$t('type.show_media_summary'),
          sub_settings: ['media_summary_style', 'media_summary_sort']
        },
        {
          key: 'show_sort_section',
          type: this.$t('type.show_sort_section')
        },
        {
          key: 'show_filter_section',
          type: this.$t('type.show_filter_section'),
          sub_settings: [
            'show_product_option_filter',
            'show_review_photo_first_button',
            'show_size_filter',
            'show_score_filter',
            'use_review_keyword_filter'
          ]
        }
      ];
    }
  },
  methods: {
    isDirectionRow(key) {
      return ['show_score_section', 'show_filter_section'].includes(key);
    }
  }
};
</script>

<style lang="scss">
.ReviewSettingsShowHeaderSection__sub-settings {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--direction-row {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>

<i18n locale="ko">
{
  "column": {
    "usage_status": "사용 여부",
    "type": "분류",
    "sub_settings": "추가 설정"
  },
  "type": {
    "show_title": "위젯 제목 영역",
    "show_score_section": "평점 영역",
    "show_media_summary": "포토&동영상 모아보기",
    "show_sort_section": "정렬",
    "show_filter_section": "필터"
  }
}
</i18n>
