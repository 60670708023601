<template>
  <AppExternalLink :to="to" v-on="$listeners">
    <div v-if="label.length" class="AppExternalLinkWithIcon__label">
      {{ label }}
    </div>
    <AppExternalLinkIcon />
  </AppExternalLink>
</template>

<script>
export default {
  name: 'AppExternalLinkWithIcon',
  props: {
    to: { type: String, required: true },
    label: { type: String, default: '' }
  }
};
</script>

<style lang="scss" scoped>
.AppExternalLinkWithIcon__label {
  vertical-align: middle;
  display: inline-block;
}
</style>
