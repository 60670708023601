<template>
  <div>
    <AppInfoBox
      v-if="isInfoboxVisible"
      class="ReviewSettingsWidgets__info-box super-admin__item"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="$t('info_box.review_settings_html')" />
      <!-- eslint-enable vue/no-v-html -->
      <a
        class="ReviewSettingsWidgets__info-box-link"
        @click="initialSetupClicked()"
      >
        {{ $t('info_box.review_settings_cta') }}
      </a>
    </AppInfoBox>
    <AppTabContent menu-item-id="review_settings_widgets" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReviewSettingsWidgets',
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', [
      'isSmartInstallationSupported',
      'isCurrentUserInstallationAgency',
      'isWidgetInitialInstallationUsable'
    ]),
    isInfoboxVisible() {
      if (!this.isSmartInstallationSupported) return false;
      if (this.isSuperAdminAccessible) return true;

      return (
        this.isCurrentUserInstallationAgency &&
        this.isWidgetInitialInstallationUsable
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    initialSetupClicked() {
      this.openDialog(['ReviewSettingsInitialSetupDialog']);
    }
  }
};
</script>

<style scoped>
.ReviewSettingsWidgets__info-box {
  margin-bottom: 12px;
}

.ReviewSettingsWidgets__info-box-link {
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "info_box": {
    "review_settings_html": "크리마 리뷰를 처음 도입할 경우 설치 도우미 기능을 활용하여 <b>여러개의 리뷰 위젯을 한번에 생성/설치</b>할 수 있습니다. ",
    "review_settings_cta": "리뷰 위젯 설치 도우미"
  }
}
</i18n>
