<template>
  <div class="AppDataItem">
    <AppDataLabel
      v-if="label"
      v-bind="{ label, labelLink, gap, badge, disabled: labelDisabled }"
    />
    <div>
      <slot>
        <div v-if="type === 'pre'">
          <pre>{{ value }}</pre>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-else-if="type === 'html'" v-html="value" />
        <AppNumber v-else-if="type === 'number'" :value="value" :unit="unit" />
        <AppBadge
          v-else-if="type === 'badge'"
          :label="value['label']"
          :badge-style="value['style']"
          :spinner="value['spinner']"
        />
        <AppMarkdown
          v-else-if="type === 'markdown'"
          :text="value"
          :markdown-option="{ breaks: true }"
          display-style="data-item"
        />
        <AppData v-else :type="type" :value="value" />
      </slot>
    </div>
    <div v-if="info" class="AppDataItem__info">
      {{ info }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDataItem',
  props: {
    label: { type: String, default: '' },
    labelLink: { type: Object, default: null },
    badge: { type: Object, default: null },
    type: { type: String, default: null },
    value: { type: [Number, Date, String, Array, Object], default: null },
    info: { type: String, default: null },
    unit: { type: String, default: null },
    gap: {
      type: String,
      default: 'default',
      validator: v => ['narrow', 'default', 'wide'].includes(v)
    },
    labelDisabled: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppDataItem__info {
  @include text-caption;
  margin-top: 4px;
}
</style>
