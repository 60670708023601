export default [
  {
    id: 'channel_feeds',
    type: 'sub_menu',
    path: '/channel/feeds',
    parentId: 'channel',
    isAccessible: getters => getters.isChannelMultiFeedEnabled
  },
  {
    id: 'channel_feed',
    type: 'sub_menu',
    path: '/channel/feed/products',
    parentId: 'channel',
    isAccessible: getters => !getters.isChannelMultiFeedEnabled
  },
  {
    id: 'channel_feed_products',
    type: 'tab',
    path: '/channel/feed/products',
    parentId: 'channel_feed',
    isAccessible: getters => !getters.isChannelMultiFeedEnabled
  },
  {
    id: 'channel_feed_product_search',
    type: 'tab',
    path: '/channel/feed/product_search',
    parentId: 'channel_feed',
    isAccessible: getters => !getters.isChannelMultiFeedEnabled
  },
  {
    id: 'channel_settings',
    type: 'sub_menu',
    path: '/channel/settings/basic',
    parentId: 'channel'
  },
  {
    id: 'channel_settings_basic',
    type: 'tab',
    path: '/channel/settings/basic',
    parentId: 'channel_settings'
  },
  {
    id: 'channel_settings_admin',
    type: 'tab',
    path: '/channel/settings/admin',
    parentId: 'channel_settings',
    superAdmin: true
  }
].map(item => ({
  ...item,
  isLaunched: getters => getters.isChannelMenuLaunched
}));
