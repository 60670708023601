import { constructEnum } from '@/lib/enum';

const WidgetStyle = constructEnum('WidgetStyle', {
  LIST: 0,
  GALLERY: 10,
  ROUND_THUMBNAIL_GALLERY: 11,
  BEST_GALLERY: 12,
  SQUARE_GALLERY: 13,
  MAIN_GALLERY: 14,
  GRID: 20,
  PHOTO_THUMBNAIL: 30,
  BOARD: 40,
  DETACHABLE_BOARD: 41,
  EXTERNAL: 50,
  OPEN_MARKET: 60,
  RESPONSIVE: 70,
  HORIZONTAL_SCROLL: 80,
  MANAGING_REVIEWS: 100,
  STATISTICS: 110,
  PRODUCT_OPTIONS: 120,
  KEYWORD: 130,
  COUNT_AND_SCORE: 140
});

WidgetStyle.PC_REVIEWS_STYLES = [
  WidgetStyle.LIST,
  WidgetStyle.GALLERY,
  WidgetStyle.BEST_GALLERY,
  WidgetStyle.SQUARE_GALLERY,
  WidgetStyle.ROUND_THUMBNAIL_GALLERY,
  WidgetStyle.MAIN_GALLERY,
  WidgetStyle.HORIZONTAL_SCROLL
];

WidgetStyle.MOBILE_REVIEWS_STYLES = [
  WidgetStyle.LIST,
  WidgetStyle.GALLERY,
  WidgetStyle.BEST_GALLERY,
  WidgetStyle.SQUARE_GALLERY,
  WidgetStyle.ROUND_THUMBNAIL_GALLERY,
  WidgetStyle.MAIN_GALLERY,
  WidgetStyle.BOARD,
  WidgetStyle.HORIZONTAL_SCROLL
];

WidgetStyle.PRODUCT_REVIEWS_COMMON_STYLES = [
  WidgetStyle.LIST,
  WidgetStyle.GRID,
  WidgetStyle.GALLERY,
  WidgetStyle.PHOTO_THUMBNAIL,
  WidgetStyle.BOARD,
  WidgetStyle.DETACHABLE_BOARD,
  WidgetStyle.HORIZONTAL_SCROLL,
  WidgetStyle.EXTERNAL,
  WidgetStyle.PRODUCT_OPTIONS
];

WidgetStyle.PC_PRODUCT_REVIEWS_STYLES_FOR_ADMIN = [
  ...WidgetStyle.PRODUCT_REVIEWS_COMMON_STYLES,
  WidgetStyle.OPEN_MARKET,
  WidgetStyle.RESPONSIVE,
  WidgetStyle.KEYWORD,
  WidgetStyle.STATISTICS
];

WidgetStyle.MOBILE_PRODUCT_REVIEWS_STYLES_FOR_ADMIN = [
  ...WidgetStyle.PRODUCT_REVIEWS_COMMON_STYLES,
  WidgetStyle.OPEN_MARKET,
  WidgetStyle.KEYWORD,
  WidgetStyle.STATISTICS
];

export default WidgetStyle;
