<template>
  <AppDropdownMenuButton
    v-if="menuItems"
    :label="$t('app.manage')"
    :menu-items="menuItems"
  />
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'ChannelProductsActionsCell',
  props: {
    product: { type: Object, required: true },
    showExcludeMenu: { type: Boolean, default: true },
    noTooltip: { type: Boolean, default: true },
    alwaysUseDialog: { type: Boolean, default: true },
    feed: { type: Object, default: () => ({}) }
  },
  computed: {
    menuItems() {
      if (this.showExcludeMenu) {
        return [
          {
            label: this.$t('exclude.label'),
            style: 'danger',
            tooltip: this.noTooltip ? null : this.$t('exclude.tooltip'),
            clickHandler: ({ close }) => {
              if (this.alwaysUseDialog || this.product.is_selected) {
                this.openExcludeProductConfirm();
              } else {
                this.excludeProduct();
              }
              close();
            }
          }
        ];
      } else {
        return [
          {
            label: this.$t('include.label'),
            style: 'danger',
            tooltip: this.noTooltip ? null : this.$t('include.tooltip'),
            clickHandler: ({ close }) => {
              this.cancelExcludeProduct();
              close();
            }
          }
        ];
      }
    },
    feedId() {
      return this.feed.id || 0;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    openExcludeProductConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('dialog.title'),
          markdownText: this.$t('dialog.context'),
          closeButtonLabel: this.$t('dialog.close_button'),
          cancelButtonStyle: 'grey-outline',
          width: DialogSize.MIDDLE
        }
      ]).then(eventBus => {
        eventBus.$on('close', this.excludeProduct);
      });
    },
    excludeProduct() {
      const formData = new FormData();
      formData.append('product_ids[]', this.product.id);
      api
        .post(`/channel/feeds/${this.feedId}/exclude_products`, formData, {
          successMessage: this.$t('toast.exclude', [this.product.name])
        })
        .finally(() => this.$emit('refresh'));
    },
    cancelExcludeProduct() {
      const formData = new FormData();
      formData.append('product_ids[]', this.product.id);
      api
        .post(
          `/channel/feeds/${this.feedId}/cancel_exclude_products`,
          formData,
          {
            successMessage: this.$t('toast.include', [this.product.name])
          }
        )
        .finally(() => this.$emit('refresh'));
    }
  }
};
</script>

<i18n locale="ko">
{
  "exclude": {
    "label": "제외 상품으로 고정",
    "tooltip": "이 상품이 앞으로 영영 피드에 노출되지 않습니다."
  },
  "include": {
    "label": "제외 상품 고정 해제",
    "tooltip": "이 상품이 광고 피드에 노출 될 수 있도록 제외 상품 고정에서 해제합니다."
  },
  "dialog": {
    "title": "제외 상품으로 고정합니다.",
    "context": "진행 시 해당 상품을 피드에서 제외하고 제외 상품으로 고정합니다.<br>제외 상품으로 고정되면 앞으로 피드 선정 조건에 해당 하더라도 더이상 선정되지 않습니다.<br>제외 상품으로 고정된 상품은 상품 검색에서 조회 및 관리하실 수 있습니다.",
    "close_button": "제외 상품으로 고정"
  },
  "toast": {
    "exclude": "{0}을 제외 상품으로 고정했습니다.",
    "include": "{0}을 제외 상품 고정 해제하였습니다."
  }
}
</i18n>
