var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"ReviewDashboardGodoReviewCsvSync",attrs:{"is-loading":_vm.isLoading}},[_c('AppDataList',{attrs:{"data":_vm.items},scopedSlots:_vm._u([{key:"value",fn:function(ref){
var id = ref.id;
return [(id === 'review_csv')?[_c('div',{staticClass:"ReviewDashboardGodoReviewCsvSync__guide"},[_vm._v(" "+_vm._s(_vm.guideDescription)+" "),(!_vm.isCompleted)?[(
                _vm.onboardingProgress.review_csv_sync_status ===
                  _vm.ReviewCsvSyncStatus.SUCCEEDED
              )?_c('div',[_c('AppButton',{attrs:{"type":"external_link","label":_vm.$t('review_csv.success_label'),"to":_vm.pathWithBrandParams('/v2/review/reviews')}})],1):(
                _vm.onboardingProgress.review_csv_sync_status ===
                  _vm.ReviewCsvSyncStatus.FAILED ||
                  _vm.onboardingProgress.review_csv_sync_status ===
                    _vm.ReviewCsvSyncStatus.NONE
              )?_c('div',[_c('AppButton',{attrs:{"label":_vm.$t('review_csv.label')},on:{"click":_vm.clickUploadCsv}})],1):_vm._e(),(
                _vm.onboardingProgress.review_csv_sync_status ===
                  _vm.ReviewCsvSyncStatus.FAILED
              )?_c('div',{staticClass:"ReviewDashboardGodoReviewCsvSync__error"},[_vm._v(" "+_vm._s(_vm.$t('review_csv.error'))+" ")]):_vm._e()]:_vm._e()],2)]:_vm._e(),(id == 'review_count_update')?[(_vm.scoreSettingDiabled)?_c('div',{staticClass:"ReviewDashboardGodoReviewCsvSync__guide--disabled"},[_vm._v(" "+_vm._s(_vm.$t('review_count_update.disabled_guide'))+" ")]):(_vm.godoReviewCountUpdated)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('review_count_update.complete_guide'))+" ")]):_c('div',{staticClass:"ReviewDashboardGodoReviewCsvSync__guide"},[_vm._v(" "+_vm._s(_vm.$t('review_count_update.none_guide'))+" "),_c('div',[_c('AppButton',{attrs:{"label":_vm.$t('review_count_update.button_label')},on:{"click":_vm.clickHowToUpdate}})],1)])]:_vm._e()]}}])}),(!_vm.isCompleted)?_c('AppButtonSubmit',{staticClass:"ReviewDashboardGodoReviewCsvSync__submit-button",attrs:{"submit-label":_vm.$t('app.complete'),"disabled":!_vm.godoReviewCountUpdated || _vm.scoreSettingDiabled},on:{"click":_vm.submit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }