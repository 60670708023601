<template>
  <div
    :class="[
      'AppResourceTableCell',
      cellClass,
      column.align ? `AppResourceTableCell--align-${column.align}` : null,
      cellStyle ? `AppResourceTableCell--style-${cellStyle}` : null,
      column.linkIcon && cellType === 'body'
        ? `AppResourceTableCell--with-link-icon`
        : null,
      column.superAdmin ? 'super-admin__item' : null
    ]"
  >
    <div class="AppResourceTableCell__inner">
      <slot>
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="column.type === 'html'" v-html="value" />
        <span
          v-else-if="column.type === 'pre'"
          class="AppResourceTableCell__pre"
          >{{ value }}</span
        >
        <div
          v-else-if="column.type === 'nowrap'"
          :ref="`ResourceTable${row.id}${column.id}`"
          v-tooltip="{
            message: autoTooltip(
              column.autoTooltip,
              `ResourceTable${row.id}${column.id}`
            )
          }"
          class="AppResourceTableCell__nowrap"
        >
          {{ value }}
        </div>
        <div
          v-else-if="column.type == 'table_line'"
          class="AppResourceTableCell__table-line"
        >
          {{ value || column.default }}
        </div>
        <!-- eslint-enable vue/no-v-html -->
        <AppTableCellProduct
          v-else-if="column.type === 'product'"
          :product="value"
        />
        <template v-else-if="column.type === 'review_media'">
          <AppReviewMediaCell :review="row" />
        </template>
        <template v-else-if="column.type === 'img'">
          <AppImage
            v-if="!!value"
            class="AppResourceTableCell__img"
            :src="value"
          />
        </template>
        <template v-else-if="column.type === 'number'">
          <AppNumber
            :value="value"
            :precision="column.precision"
            :unit="column.unit"
            :placeholder="column.default"
            :force-precision="column.forcePrecision"
          />
        </template>
        <template v-else-if="column.type === 'number2'">
          <template v-if="Array.isArray(value) && value.length === 2">
            <AppNumber
              :value="value[0]"
              :precision="column.precision"
              :unit="column.unit"
              :placeholder="column.default"
            />/<AppNumber
              :value="value[1]"
              :precision="column.precision"
              :unit="column.unit"
              :placeholder="column.default"
            />
          </template>
          <template v-else>{{ column.default }}</template>
        </template>
        <template v-else-if="column.type === 'percentage'"
          >{{ `${(value || 0).toFixed(1)}%` }}
        </template>
        <template v-else-if="column.type === 'rate'">
          <AppNumber
            :value="value ? value * 100 : value"
            :precision="1"
            force-precision
            unit="percentage"
            :placeholder="column.default"
          />
        </template>
        <AppCheckbox
          v-else-if="column.type === 'row_select'"
          :checked="value"
          :disabled="row.disabled"
          input-style="standalone"
          @change="$emit('check', $event)"
        />
        <template v-else-if="column.type === 'badge'">
          <AppBadge
            :label="row[column.id].label"
            :badge-style="row[column.id].badgeStyle"
            :tooltip="row[column.id].badgeTooltip"
          />
        </template>
        <template v-else-if="column.type === 'badges'">
          <ul>
            <li
              v-for="item in value"
              :key="item.id"
              class="AppResourceTableCell__badges"
            >
              <AppBadge
                :label="item.label"
                :tooltip="item.tooltip"
                :badge-style="item.style"
                :badge-size="item.size"
              />
            </li>
          </ul>
        </template>
        <AppData v-else-if="!!value" :type="column.type" :value="value" />
        <AppData v-else :value="column.default" />
      </slot>
    </div>
    <AppResourceTableLinkIcon
      v-if="column.linkIcon && value"
      @click="$emit('click-link-icon')"
    />
  </div>
</template>

<script>
import TooltipHelper from '@/mixins/TooltipHelper';

export default {
  name: 'AppResourceTableCell',
  mixins: [TooltipHelper],
  props: {
    cellClass: { type: String, default: null },
    cellType: {
      type: String,
      required: true,
      validator: v => ['head', 'body'].includes(v)
    },
    cellStyle: {
      type: String,
      default: null,
      validator: v => ['row-select', 'position'].includes(v)
    },
    column: { type: Object, required: true },
    row: { type: Object, default: () => ({}) },
    value: { type: [Number, String, Boolean, Array, Object], default: null }
  }
};
</script>

<style lang="scss" scoped>
.AppResourceTableCell {
  flex: 1 0 0;
  min-width: 0;
  display: flex;
  align-items: center;
  position: relative;
  word-break: break-word;

  &--align-left {
    text-align: left;
  }

  &--align-right {
    text-align: right;
  }

  &--style-row-select {
    flex: 0 0 48px;
  }

  &--style-position {
    flex: 0 0 84px;
  }

  &--with-link-icon {
    padding-right: 20px;
  }
}

.AppResourceTable__head-row {
  .AppResourceTable__cell {
    word-break: normal;
  }
}

.AppResourceTableCell__inner {
  flex: 1 1 auto;
  width: 100%;
}

.AppResourceTableCell__img {
  vertical-align: middle;
  max-width: 100%;
}

.AppResourceTableCell__table-line {
  line-height: 20px;
}

.AppResourceTableCell__pre {
  white-space: pre-wrap;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.AppResourceTableCell__nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AppResourceTableCell__badges {
  width: fit-content;
  margin: auto;
  font-size: 0;
  height: 19px;
}

::v-deep {
  // HACK - 테이블 내부의 AppButton 간격 조정. BEM에 어긋나지만 더 좋은 방법을 찾지 못함
  .AppButton + .AppButton {
    margin-left: 4px;
  }

  .AppResourceTableCell__badges + .AppResourceTableCell__badges {
    margin-top: 4px;
  }
}
</style>
