<template>
  <div class="ReviewImportSmartstore">
    <AppAjaxContent :is-loading="isLoading">
      <template #default>
        <template v-if="isOnboarding">
          <SmartstoreProgressSteps />
          <Component :is="currentOnboardingComponent" />
        </template>
        <AppTabContent v-else menu-item-id="review_import_smartstore" />
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import SmartstoreProgressSteps from './components/SmartstoreProgressSteps';
import SmartstoreAgreement from './components/SmartstoreAgreement';
import SmartstoreProductMatch from './components/SmartstoreProductMatch';
import SmartstorePayment from './components/SmartstorePayment';
import SmartstorePaymentExpired from './components/SmartstorePaymentExpired';
import SmartstoreEstimating from './components/SmartstoreEstimating';
import SmartstoreImporting from './components/SmartstoreImporting';

export default {
  name: 'ReviewImportSmartstore',
  components: {
    SmartstoreProgressSteps,
    SmartstoreAgreement,
    SmartstoreProductMatch,
    SmartstorePayment,
    SmartstorePaymentExpired,
    SmartstoreEstimating,
    SmartstoreImporting
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState('smartstore', ['smartstoreStatus']),
    isOnboarding() {
      return this.smartstoreStatus < SmartstoreStatus.OPERATING;
    },
    currentOnboardingComponent() {
      switch (this.smartstoreStatus) {
        case SmartstoreStatus.NONE:
          return SmartstoreAgreement;
        case SmartstoreStatus.PRODUCT_MATCH_PENDING:
        case SmartstoreStatus.PRODUCT_MATCH_STARTED:
        case SmartstoreStatus.PRODUCT_MATCH_FAILED:
        case SmartstoreStatus.PRODUCT_MATCH_FINISHED:
          return SmartstoreProductMatch;
        case SmartstoreStatus.ESTIMATION_QUEUED:
        case SmartstoreStatus.ESTIMATION_STARTED:
        case SmartstoreStatus.ESTIMATION_FAILED:
          return SmartstoreEstimating;
        case SmartstoreStatus.PAYMENT_PENDING:
          return SmartstorePayment;
        case SmartstoreStatus.PAYMENT_EXPIRED:
          return SmartstorePaymentExpired;
        default:
          // SmartstoreStatus.STARTER_IMPORT_QUEUED
          // SmartstoreStatus.STARTER_IMPORT_STARTED
          // SmartstoreStatus.STARTER_IMPORT_FAILED
          return SmartstoreImporting;
      }
    }
  },
  mounted() {
    this.fetchSmartstoreStatus().finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('smartstore', ['fetchSmartstoreStatus'])
  }
};
</script>
